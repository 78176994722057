import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import md5 from "js-md5";
import URL from "./utils/url";
import common from "./utils/common.js";
import wallet from "./utils/wallet/index.js";
import "vant/lib/index.css";
import { vant } from "./utils/vant.config.js";
import { post, get } from "./utils/http";
import "./public/css/common.css";
import "babel-polyfill";
import "es6-promise/auto";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "./public/js/rem";
import directive from "./utils/directive";
import "animate.css";
import "@vant/touch-emulator";
import i18n from "./public/lang/i18n";
import en from "element-plus/es/locale/lang/en";
import zhTw from "element-plus/es/locale/lang/zh-tw";
let lang = localStorage.getItem("language") || "en_US";
// import VConsole from 'vconsole';
// const vconsole= new VConsole()
let langMap = {
  en_US: en,
  zh_TW: zhTw,
};
import * as echarts from "echarts";
import glComponent from "./componets/index";

const app = createApp(App);
app.config.globalProperties = {
  URL,
  common,
  $md5: md5,
  $wallet:wallet,
  $get: get,
  $post: post,
  $echarts: echarts,
};
vant(app);
directive(app);
app
  .use(router)
  .use(ElementPlus, {
    locale: langMap[lang],
  })
  .use(i18n)
  .use(echarts)
  .use(glComponent)
  .use(store)
  .mount("#app");
