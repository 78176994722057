
<template>
  <Transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div v-if="props.type === 1" class="modal-container">
          <div style="margin-bottom: 16px" class="modal-header">
            <h3>{{ proxy.$t("asset.desc165") }}
              
            </h3>
            <div class="modal-default-button" @click="props.backFun(1)">
              <img class="close" src="../public/images/new/x.svg" />
            </div>
          </div>

          <div class="modal-body">
            <p style="margin-bottom: 36px">
              {{ proxy.$t("asset.desc166") }}
            </p>

            <h4 style="margin-bottom: 12px"> {{ proxy.$t("asset.desc167") }}</h4>
            <input
              style="margin-bottom: 16px"
              v-model="state.model.username"
              :placeholder="$t('asset.desc168')"
            />
            <div style="margin-bottom: 28px" class="alert">
              {{ proxy.$t("asset.desc169") }}
            </div>
            <div class="button" @click="submit(props.type)">
              <div style="margin: auto">{{ proxy.$t("home.desc20")}}</div>
            </div>
          </div>
        </div>
        <div v-if="props.type === 2" class="modal-container">
          <div style="margin-bottom: 32px" class="modal-header">
            <h3>  {{ proxy.$t("asset.desc170") }}</h3>
            <div class="modal-default-button" @click="props.backFun(1)">
              <img class="close" src="../public/images/new/x.svg" />
            </div>
          </div>

          <div class="modal-body">
            <h4 style="margin-bottom: 12px"> {{ proxy.$t("withdraw.desc17")}}</h4>
            <input
              style="margin-bottom: 24px"
              v-model="state.model.email"
              :placeholder="$t('asset.desc168')"
            />
            <h4 style="margin-bottom: 12px">{{ proxy.$t("withdraw.desc18")}}</h4>
            <div style="margin-bottom: 46px" class="special-input">
              <input v-model="state.model.code" :placeholder="$t('withdraw.desc17')" />
              <div class="verify" @click="verify(0)"> {{ state.model.sendText0!="" ?state.model.sendText0: proxy.$t("sign.desc26") }}</div>
            </div>
            <div class="button" @click="submit(props.type)">
              <div style="margin: auto">{{ proxy.$t("home.desc20")}}</div>
            </div>
          </div>
        </div>
        <div v-if="props.type === 3" class="modal-container">
          <div style="margin-bottom: 32px" class="modal-header">
            <h3>  {{ proxy.$t("asset.desc171") }}</h3>
            <div class="modal-default-button" @click="props.backFun(1)">
              <img class="close" src="../public/images/new/x.svg" />
            </div>
          </div>

          <div class="modal-body">
            <h4 style="margin-bottom: 12px">  {{ proxy.$t("asset.desc172") }}</h4>
            <div style="margin-bottom: 24px" class="special-input">
              <input
                :type="in1Privacy"
                v-model="state.model.password"
                :placeholder="$t('sign.desc53')"
              />
              <img
                class="privacy-toggle"
                @click="togglePrivacy(privacyToggles[0])"
                :src="pwdPrivacy === 'password' ? closedimg : openimg"
              />
            </div>

            <h4 style="margin-bottom: 12px">{{proxy.$t("sign.desc56")}}</h4>
            <div style="margin-bottom: 24px" class="special-input">
              <input
                :type="in2Privacy"
                v-model="state.model.requirePassword"
                :placeholder="$t('sign.desc23')"
              />
              <img
                class="privacy-toggle"
                @click="togglePrivacy(privacyToggles[1])"
                :src="pwRPrivacy === 'password' ? closedimg : openimg"
              />
            </div>

            <h4 style="margin-bottom: 12px">{{proxy.$t("withdraw.desc18")}}</h4>
            <div style="margin-bottom: 60px" class="special-input">
              <input v-model="state.model.code" :placeholder="$t('asset.desc173')"/>
              <div class="verify" @click="verify(1)">
                {{ state.model.sendText1!="" ?state.model.sendText1: proxy.$t("sign.desc26") }}
              </div>
            </div>
            <div class="button" @click="submit(props.type)">
              <div style="margin: auto">{{ proxy.$t("home.desc20")}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script setup>
import { ref, reactive, getCurrentInstance, computed } from "vue";
import openimg from "../public/images/new/eye_open.svg";
import closedimg from "../public/images/new/eye_closed.svg";
// defineProps 支持的类型有：String、Number、Boolean、Object、Array、Function，除此之外还支持很多高级类型，如：枚举类型、对象类型、联合类型
// props 的验证  type: 定义数据的类型  reqiured: 是否必须 default: 默认值 validator: 自定义验证
const props = defineProps({
  show: {
    type: Boolean,
    reqiured: true,
    default: false,
  },
  type: {
    type: Number,
    reqiured: true,
    default: 0,
  },
  backFun: {
    type: Function,
    reqiured: false,
  },
});
const { proxy } = getCurrentInstance();
const state = reactive({
  model: {
    username: "",
    email: "",
    code: "",
    password: "",
    requirePassword: "",
    timeNull0: null,
    sendText0: "",
    issend0: true,
    timeNull1: null,
    sendText1: "",
    issend1: true,
  },
});

const pwdPrivacy = ref("password");
const pwRPrivacy = ref("password");
const privacyToggles = [pwdPrivacy, pwRPrivacy];
const togglePrivacy = (field) => {
  field.value === "password"
    ? (field.value = "text")
    : (field.value = "password");
};
const submit = (type) => {
  console.log(type);
  let postUrl = "";
  let postData = {};
  let successMessage = "";
  if (type == 1) {
    //修改用户名
    if (state.model.username == "") {
      proxy.$message.error(proxy.$t("sign.desc71"));
      return;
    }
    postUrl = proxy.URL.user.useraccountset;
    postData = {
      account: state.model.username,
    };
    successMessage = proxy.$t("asset.desc165");
  } else if (type == 2) {
    //设置邮箱
    if (state.model.email == "") {
      proxy.$message.error(proxy.$t("sign.desc21"));
      return;
    }
    let reg = /^[A-Za-z0-9]+([-_.][A-Za-z0-9]+)*@([A-Za-z0-9]+[-.])+[A-Za-z0-9]{2,5}$/;
            if (!reg.test(state.model.email)) {
                proxy.$message.error(proxy.$t('sign.desc35'));
                return;
            }
    if (state.model.code == "") {
      proxy.$message.error(proxy.$t("sign.desc25"));
      return;
    }
    postUrl = proxy.URL.user.useremailset;
    postData = {
      email: state.model.email,
      code: state.model.code,
    };
    successMessage = proxy.$t("asset.desc174");
  } else if (type == 3) {
    //设置交易密码
    if (state.model.password == "") {
      proxy.$message.error(proxy.$t("asset.desc175"));
      return;
    }
    if (state.model.requirePassword == "") {
      proxy.$message.error(proxy.$t("asset.desc176"));
      return;
    }
    if (state.model.requirePassword != state.model.password) {
      proxy.$message.error(proxy.$t("home.desc21"));
      return;
    }
    if (state.model.code == "") {
      proxy.$message.error(proxy.$t("sign.desc25"));
      return;
    }
    postUrl = proxy.URL.login.passwordset;
    postData = {
      verityType: 1,
      password: proxy.common.mdpassword(state.model.requirePassword),
      code: state.model.code,
    };
    successMessage = proxy.$t("asset.desc177");
  } else {
    return;
  }
  proxy.$post(postUrl, postData).then((res) => {
    if (res.code == 0) {
      proxy.$message.success(successMessage);
      proxy.$post(proxy.URL.member.info, {}).then((ress) => {
        console.log(ress);
        if (ress.code == 0) {
          proxy.$store.commit("SET_USERINFO", ress.data);
        }
        props.backFun(1);
      });
    } else {
      proxy.$message.error(res.message);
    }
  });
};

const verify = (type) => {
  let _this = state.model;
  if (type == 0) {
    let reg = /^[A-Za-z0-9]+([-_.][A-Za-z0-9]+)*@([A-Za-z0-9]+[-.])+[A-Za-z0-9]{2,5}$/;
            if (!reg.test(state.model.email)) {
                proxy.$message.error(proxy.$t('sign.desc35'));
                return;
            }
    if (state.model.issend0) {
      state.model.issend0 = false;

      proxy
        .$post(proxy.URL.login.sendverify, {
          accountType: 1,
          account: state.model.email,
          type: 11,
        })
        .then((res) => {
          if (res.code == 0) {
            proxy.$message.success(proxy.$t("sign.desc37"));
            let num = 60;
            state.model.timeNull0 = setInterval(() => {
              
              num--;
              if (num < 1) {
                clearInterval(_this.timeNull0);
                _this.issend0 = true;
                _this.sendText0 ='';
              } else {
                console.log(_this.sendText0);
                _this.sendText0 = `${num} S`;
              }
            }, 1000);
          } else {
            state.model.issend0 = true;
            proxy.$message.error(proxy.$t("sign.desc37"));
          }
        });
    }
  }
  else if (type == 1) {
    if (state.model.issend1) {
      state.model.issend1 = false;

      proxy
        .$post(proxy.URL.login.sendverify, {
          accountType: 1,
          account: proxy.$store.state.userinfo.email,
          type: 12,
        })
        .then((res) => {
          if (res.code == 0) {
            proxy.$message.success(proxy.$t("sign.desc37"));
            let num = 60;
            state.model.timeNull1 = setInterval(() => {
            
              num--;
              if (num < 1) {
                clearInterval(_this.timeNull1);
                _this.issend1 = true;
                _this.sendText1 ='';
              } else {
                console.log(_this.sendText1);
                _this.sendText1 = `${num} S`;
              }
            }, 1000);
          } else {
            proxy.$message.error(proxy.$t("sign.desc37"));
            state.model.issend1 = true;
          }
        });
    }
  }
};
</script>

<style>
.special-input {
  position: relative;
}

.verify {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
  color: #c9fa5b;
  text-align: right;
  font-family: Bai Jamjuree;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
  cursor: pointer;
}

.privacy-toggle {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
  cursor: pointer;
}

.close {
  cursor: pointer;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  margin: 0px auto;
  transition: all 0.3s ease;
  padding: 32px;
  padding-bottom: 40px;
  width: 412px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  border: 1px solid #000;
  background: #181818;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h3 {
  color: #fff;
  font-family: Bai Jamjuree;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.modal-body p {
  color: rgba(255, 255, 255, 0.7);
  font-family: Bai Jamjuree;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.modal-body h4 {
  color: #fff;
  font-family: Bai Jamjuree;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
}

.modal-body input {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  border: 1px solid #393939;
  padding: 15px;
  background: transparent;
  transition: background-color 0.5s;
  color: #fff;
  font-family: Bai Jamjuree;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
}

.button {
  height: 50px;
  color: #000;
  text-align: center;
  font-family: Bai Jamjuree;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  width: 100%;
  margin: auto;
  fill: #c9fa5b;
  background-image: url("../public/images/new/button-bg.png");
  background-size: 100% 100%;
  display: flex;
  cursor: pointer;
}

.alert {
  color: #eb4343;
  font-family: Bai Jamjuree;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media (max-width: 960px) {
  .modal-container {
    width: 95%;
  }

  .button {
    max-width: 350px;
  }
}
</style>

