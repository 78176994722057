import { defineAsyncComponent } from "vue";
import tooltip from "./tooltip.vue";
import hoverborder from "./hoverborder.vue";
const mobilepage = defineAsyncComponent(() => import("./mobilepage"));
const comimage = defineAsyncComponent(() => import("./comimage"));
const PsdPopup = defineAsyncComponent(() => import("./PsdPopup"));
const SuccFailToast = defineAsyncComponent(() => import("./SuccFailToast"));
const ActivityTop = defineAsyncComponent(() => import("./activitytop"));
const ComPagination = defineAsyncComponent(() => import("./ComPagination"));
const daotBuyPopup = defineAsyncComponent(() => import("./daotBuyPopup"));
const IntegralUseToast = defineAsyncComponent(() =>
  import("./IntegralUseToast")
);
const ComLoading = defineAsyncComponent(() => import("./ComLoading"));
const PageLoading = defineAsyncComponent(() => import("./PageLoading"));
const components = {
  tooltip,
  hoverborder,
  mobilepage,
  comimage,
  PsdPopup,
  SuccFailToast,
  ComPagination,
  IntegralUseToast,
  ComLoading,
  PageLoading,
  ActivityTop,
  daotBuyPopup
};

export default {
  install(app) {
    Object.keys(components).forEach((key) => {
      app.component(key, components[key]);
    });
  },
};
