export default function directive(app) {
  app.directive("LimitInputNumber", {
    beforeMount(el, binding) {
      el.addEventListener("input", (event) => {
        const value = event.target.value;

        // 移除非法字符，只保留数字和一个小数点
        const cleanedValue = value.replace(/[^\d.]+/g, "");

        // 检查小数点的位置
        const decimalIndex = cleanedValue.indexOf(".");

        // 如果小数点在开头，则修正为0.
        if (decimalIndex === 0) {
          event.target.value = "0.";
        }
        // 如果小数点不在开头且存在多个小数点，则修正为第一个小数点
        else if (
          decimalIndex !== -1 &&
          cleanedValue.lastIndexOf(".") !== decimalIndex
        ) {
          event.target.value =
            cleanedValue.slice(0, decimalIndex + 1) +
            cleanedValue.slice(decimalIndex + 1).replace(/\./g, "");
        }
        // 否则，保留清理后的值
        else {
          event.target.value = cleanedValue;
        }
      });

      el.addEventListener("blur", (event) => {
        event.target.value = Number(event.target.value.replace(/[^\d.]+/g, ""));
      });
    },
  });
}
