<template>
    <div class="trade">
        <div class="trade-title">{{ $t('home.desc11') }}</div>
        <div class="trade-info">
            <div class="info-name">{{ $t('home.desc12') }}</div>
            <div class="info-input flex">
                <input :type="psdtype" v-bind:autocomplete="noAutoComplete" v-model="password" maxlength="6"
                    @input="passwordChange" onKeyUp="value=value.replace(/[^0-9]/g,'')" :placeholder="$t('home.desc13')" />
                <img :src="psdtype == 'password' ? require('../public/images/ceyes.png') : require('../public/images/eyesopen.png')"
                    alt="" @click="handlerEye(0)" />

            </div>
        </div>
        <div class="trade-error">
            <p>{{ errtips }}</p>
        </div>
        <div class="trade-info">
            <div class="info-name">{{ $t('home.desc14') }}</div>
            <div class="info-input flex">
                <input :type="psdtype1" v-model="conpassword" maxlength="6" @input="conpasswordChange"
                    onKeyUp="value=value.replace(/[\W]/g,'')" :placeholder="$t('home.desc15')" />
                <img :src="psdtype1 == 'password' ? require('../public/images/ceyes.png') : require('../public/images/eyesopen.png')"
                    alt="" @click="handlerEye(1)" />
            </div>
        </div>
        <div class="trade-error">
            <p>{{ errtips1 }}</p>
        </div>
        <div class="trade-info" v-if="showEmail">
            <div class="info-name">{{ $t('sign.desc24') }}</div>
            <div class="info-input flex">
                <input type="tel" v-model="code" onkeyup="this.value=this.value.replace(/\D/g,'')"
                    onafterpaste="this.value=this.value.replace(/\D/g,'')" maxlength="6" :placeholder="$t('sign.desc25')" />
                <p @click="handlerSend">{{ sendText }}</p>
            </div>
        </div>
        <div class="trade-info" v-if="showGoogle">
            <div class="info-name">{{ $t('home.desc8') }}</div>
            <div class="info-input flex">
                <input type="tel" v-model="googlecode" onkeyup="this.value=this.value.replace(/\D/g,'')"
                    onafterpaste="this.value=this.value.replace(/\D/g,'')" maxlength="6" :placeholder="$t('home.desc17')" />
            </div>
        </div>
        <div class="trade-error">
            <p>{{ errtips2 }}</p>
        </div>
        <div class="trade-tips"><span v-if="showEmail" @click="showType(1)">{{ $t('home.desc66') }}</span><span v-else
                @click="showType(2)">{{ $t('home.desc67') }}</span></div>
        <div class="trade-btn pc" @click="handlerSubmit" v-loading="loading">{{ $t('home.desc20') }}</div>
        <div class="trade-btn h5" @click="handlerSubmit1" v-loading="loading">{{ $t('home.desc20') }}</div>
          <!-- 谷歌密码 -->
          <el-dialog v-model="showGoogleTips" :append-to-body="true">
            <div class="modal-con1">
                <img src="../public/images/new/closeicon.png" alt="" class="close" @click="showGoogleTips = false" />
                <div class="modal-title">{{ $t("home.desc64") }}</div>
                <div class="modal-desc">{{ $t("home.desc65") }}</div>
                <div class="modal-btn flex">
                    <div class="flexcenter" @click="showGoogleTips = false">
                        {{ $t("node.desc47") }}
                    </div>
                    <div class="flexcenter" @click="handlerGoogle">
                        {{ $t("node.desc48") }}
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>


export default {
    data() {
        return {
            showGoogleTips:false,
            password: '',
            psdtype: 'password',
            psdtype1: 'password',
            conpassword: '',
            code: '',
            errtips: '',
            errtips1: '',
            errtips2: '',
            loading: false,
            ismobile: false,
            sendText: this.$t('sign.desc26'),
            issend: true,
            sourceType: 0,
            noAutoComplete: false,
            noAutoComplete1: false,
            showGoogle: false,
            showEmail: true,
            googlecode: '',
            bindGoogle:false
        }
    },
    created() {
        this.noAutoComplete = true
        this.noAutoComplete1 = true
        // this.sourceType = this.$store.state.userData.sourceType;
        // this.bindGoogle = this.$store.state.userData.hasBindGoogleAuth
        this.$post(this.URL.member.info, {}).then((res) => {
        if (res.code == 0) {
          this.sourceType = res.data.sourceType;
          this.bindGoogle = res.data.hasBindGoogleAuth;
        }
      });
    },
    methods: {
        handlerGoogle() {
            this.showGoogleTips=false;
            if (localStorage.getItem('ismobile') == 1) {
                this.$router.push('/user/info?id=4')
            }else{
                this.$emit('handlerSwitch', 'Google');
            }
           
        },
        showType(i) {
            if(i==1 && !this.bindGoogle){
                this.showGoogleTips=true;
                return;
            }
            this.googlecode = '';
            this.code = '';
            this.issend = true;
            this.errtips2 = '';
            if (this.sendText != this.$t('sign.desc26')) {
                this.sendText = this.$t('sign.desc26');
                clearInterval(this.timeNull);
            }
            if (i == 1) {
                this.showGoogle = true;
                this.showEmail = false;
            } else {
                this.showGoogle = false;
                this.showEmail = true;
            }
        },
        passwordChange() {
            const regex = /^\d*$/; // 验证输入值是否为数字
            let str = this.password + ''
            if (str.length > 0) {
                let arr = str.split('')
                for (let i = 0; i < arr.length; i++) {
                    if (!regex.test(arr[i])) {
                        arr.splice(i, 1)
                        break;
                    }
                }
                this.password = arr.join('')
            }
        },
        conpasswordChange() {
            const regex = /^\d*$/; // 验证输入值是否为数字
            let str = this.conpassword + ''
            if (str.length > 0) {
                let arr = str.split('')
                for (let i = 0; i < arr.length; i++) {
                    if (!regex.test(arr[i])) {
                        arr.splice(i, 1)
                        break;
                    }
                }
                this.conpassword = arr.join('')
            }
        },
        handlerSend() {
            let _this = this;
            if (this.issend) {
                this.issend = false;

                this.$post(this.URL.login.sendverify, {
                    accountType: 1,
                    account: this.$store.state.userData.email,
                    type: 3
                }).then(res => {
                    if (res.code == 0) {
                        this.$message.success(this.$t('sign.desc37'));
                        let num = 60
                        this.timeNull = setInterval(() => {
                            num--;
                            if (num < 1) {
                                clearInterval(_this.timeNull);
                                _this.issend = true;
                                _this.sendText = _this.$t('sign.desc26')
                            } else {
                                this.sendText = `${num} S`
                            }
                        }, 1000)
                    } else {
                        this.issend = true;
                    }
                })

            }
        },
        handlerEye(i) {
            if (i == 0) {
                this.psdtype == 'password' ? this.psdtype = 'text' : this.psdtype = 'password'
            } else {
                this.psdtype1 == 'password' ? this.psdtype1 = 'text' : this.psdtype1 = 'password'
            }
        },
        handlerSubmit() {
            this.errtips = '';
            this.errtips1 = '';
            this.errtips2 = '';
            if (this.password == '') {
                this.errtips = this.$t('home.desc13');
                return;
            }
            if (this.conpassword == '') {
                this.errtips1 = this.$t('home.desc15');
                return;
            }
            if (this.conpassword != this.password) {
                this.errtips1 = this.$t('home.desc21');
                return;
            }
            if (this.code == '' && this.showEmail) {
                this.errtips2 = this.$t('sign.desc25');
                return;
            }
            if (this.googlecode == '' && this.showGoogle) {
                this.errtips2 = this.$t('home.desc17');
                return;
            }
            if (!this.loading) {
                this.loading = true;
                let params = {
                    newPassword: this.sourceType == 2 || this.sourceType == 3 ? this.password : this.common.mdpassword(this.password)
                }
                if (this.showEmail) {
                    params.email = this.$store.state.userData.email;
                    params.emailCode = this.code
                } else {
                    params.googleCode = this.googlecode
                }
                this.$post(this.URL.user.trade, params).then(res => {
                    if (res.code == 0) {
                        this.loading = false;
                        this.$message.success(this.$t('home.desc43'))
                        this.$emit('handlerSwitch', 'headerNav');
                        this.$post(this.URL.user.info, {}).then(result => {
                            this.$store.commit('SET_USERDATA', result.data)
                        })
                    } else {
                        this.loading = false;
                        this.$message.error(res.message)
                    }
                })

            }
        },
        handlerSubmit1() {
            this.errtips = '';
            this.errtips1 = '';
            this.errtips2 = '';
            if (this.password == '') {
                this.errtips = this.$t('home.desc13');
                return;
            }
            if (this.conpassword == '') {
                this.errtips1 = this.$t('home.desc15');
                return;
            }
            if (this.conpassword != this.password) {
                this.errtips1 = this.$t('home.desc21');
                return;
            }
            if (this.code == '' && this.showEmail) {
                this.errtips2 = this.$t('sign.desc25');
                return;
            }
            if (this.googlecode == '' && this.showGoogle) {
                this.errtips2 = this.$t('home.desc17');
                return;
            }
            if (!this.loading) {
                this.loading = true;
                let params = {
                    newPassword: this.sourceType == 2 || this.sourceType == 3 ? this.password : this.common.mdpassword(this.password)
                }
                if (this.showEmail) {
                    params.email = this.$store.state.userData.email;
                    params.emailCode = this.code
                } else {
                    params.googleCode = this.googlecode
                }
                this.$post(this.URL.user.trade, params).then(res => {
                    if (res.code == 0) {
                        this.loading = false;
                        this.$message.success(this.$t('home.desc43'))

                        this.$post(this.URL.user.info, {}).then(result => {
                            this.$store.commit('SET_USERDATA', result.data)
                        })
                        setTimeout(() => {
                            this.$router.go(-1)
                        }, 2000);
                    } else {
                        this.loading = false;
                        this.$message.error(res.message)
                    }
                })

            }
        }
    }
}
</script>

<style lang="less" scoped>
.trade {
    .trade-title {
        margin-bottom: 16px;
        font-size: 24px;
        color: #FFFFFF;
        font-weight: 600;
    }

    .trade-info {
        .info-name {
            margin-bottom: 10px;
            font-size: 13px;
            color: #FFFFFF;
            font-weight: 600;
        }

        .info-input {
            position: relative;
            padding: 12px 14px;
            border: 1px solid #393939;
            box-sizing: border-box;

            input {

                flex: 1;
                height: 17px;
                font-size: 16px;
                color: #ffffff;

                &::placeholder {
                    color: #5F5F5F;
                }
            }

            p {
                font-size: 14px;
                color: #C9FA5B;
                line-height: 17px;
                cursor: pointer;
            }

            img {
                position: absolute;
                top: 50%;
                right: 15px;
                width: 16px;
                height: 16px;
                transform: translateY(-50%);
                cursor: pointer;
            }
        }
    }

    .trade-error {
        height: 30px;

        p {
            padding: 5px 0 0;
            font-size: 13px;
            color: #F95454;
        }
    }

    .trade-tips {
        text-align: right;
        font-size: 13px;
        color: #C9FA5B;
        cursor: pointer;
    }

    .trade-btn {
        margin-top: 37px;
        width: 100%;
        height: 57px;
        background: url('../public/images/new/rightbtn.png') center no-repeat;
        background-size: 100% 100%;
        font-size: 20px;
        color: #111111;
        font-family: 'Nebula';
        text-align: center;
        line-height: 60px;
        cursor: pointer;
    }

    .h5 {
        display: none;
    }
}
.modal-con1 {
    position: relative;
    padding: 32px;
    width: 480px;

    .close {
        position: absolute;
        top: 32px;
        right: 32px;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    .modal-title {
        margin-bottom: 32px;
        font-size: 20px;
        color: #ffffff;
    }

    .modal-desc {
        font-size: 16px;
        color: rgba(255, 255, 255, 0.8);
    }

    .divestment-list {
        padding: 20px;
        border-radius: 8px;
        background: #242424;
        flex-wrap: wrap;

        .divestment-info {
            margin-bottom: 16px;
            font-size: 14px;
            color: rgba(255, 255, 255, 0.6);
            line-height: 14px;

            &:last-child {
                margin-bottom: 0;
            }

            div {
                flex: 1;
            }

            p {
                flex: 0 0 40%;
                color: #fff;
                text-align: right;
            }
        }
    }

    .modal-tipsname {
        margin: 21px 0 11px;
        font-size: 14px;
        color: #fff;
        line-height: 16px;

        img {
            width: 16px;
            height: 16px;
            margin-right: 6px;
        }
    }

    .modal-tips1 {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.8);
        line-height: 14px;

        div {
            margin-bottom: 4px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .modal-input {
        margin: 20px 0 16px;
    }

    .modal-tips {
        font-size: 14px;
        color: #eb4343;
    }

    .modal-btncon {
        margin-top: 24px;
        height: 50px;
        background: url("../public/images/new/btnbg16.png") center no-repeat;
        background-size: 100% 100%;
        font-size: 16px;
        color: #000000;
        cursor: pointer;
    }

    .modal-btn {
        margin-top: 32px;
        justify-content: space-between;

        div {
            flex: 0 0 197px;
            height: 50px;
            background: url("../public/images/new/btnbg14.png") center no-repeat;
            background-size: 100% 100%;
            font-size: 18px;
            color: #ffffff;
            cursor: pointer;

            &:last-child {
                background: url("../public/images/new/btnbg15.png") center no-repeat;
                background-size: 100% 100%;
                color: #000000;
            }
        }
    }
}

@media (max-width:1200px) {
    .trade {
        padding-bottom: 14px;

        .trade-title {
            width: 284px;
            padding: 0 0 24px;
            margin: 0 auto 24px;
            font-family: 'Nebula';
            font-size: 16px;
            line-height: 18px;
        }

        .trade-info {
            padding: 0 32px;

            .info-input {

                input {
                    font-size: 14px;
                }
            }
        }

        .trade-error {
            padding: 0 32px;
            white-space: nowrap;
            height: 20px;

            p {
                padding-top: 5px;
            }
        }

        // .pcerror {
        //     display: none;
        // }

        .trade-tips {

            color: #C9FA5B;
            padding: 0 0 0 32px;
            font-size: 12px;
        }

        .trade-btn {
            width: 284px;
            font-family: 'Nebula';
            margin: 14px auto 0;
        }
    }
}

@media (max-width:767px) {
    .trade {
        padding: 20px 16px;
        background: #181818;
        border-radius: 8px;

        .trade-title {
            display: none;
        }

        .trade-info {
            padding: 0;
        }

        .trade-tips {
            padding-left: 0;
            color: #C9FA5B;
        }

        .trade-error {
            padding: 0;
        }

        .trade-btn {
            width: 100%;
            height: 49px;
            font-size: 16px;
            line-height: 49px;
        }

        .pc {
            display: none;
        }

        .h5 {
            display: block;
        }
    }
    .modal-con1 {
        width: 100%;
        padding: 16px;

        .close {
            position: absolute;
            top:20px;
            right: 16px;
            width: 24px;
            height: 24px;
            cursor: pointer;
        }

        .modal-title {
            margin-bottom: 24px;
            font-size: 20px;
            color: #ffffff;
        }

        .modal-desc {
            font-size: 16px;
            color: rgba(255, 255, 255, 0.8);
        }


        .modal-btn {
            margin-top: 32px;
            justify-content: space-between;

            div {
                flex: 0 0 48%;
                height: 44px;
                background: url("../public/images/new/btnbg14.png") center no-repeat;
                background-size: 100% 100%;
                font-size: 18px;
                color: #ffffff;
                cursor: pointer;

                &:last-child {
                    background: url("../public/images/new/btnbg15.png") center no-repeat;
                    background-size: 100% 100%;
                    color: #000000;
                }
            }
        }
    }
}
</style>