<template>
  <div class="hover-border">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <slot />
  </div>
</template>
<script></script>
<style scoped lang="less">
.hover-border {
  transition: 0.5s;
  border: 2px solid transparent;
  position: relative;
  span {
    position: absolute;
    background-image: url("../public/images/adorn_triangle.png");
    background-size: 100% 100%;
    width: 12px;
    height: 12px;
    transition: 0.5s;
    opacity: 0;
    &:nth-child(1) {
      top: 12px;
      left: 12px;
      transform: translate(-3px, -3px);
    }
    &:nth-child(2) {
      top: 12px;
      right: 12px;
      transform: rotate(90deg) translate(-3px, -3px);
    }
    &:nth-child(3) {
      bottom: 12px;
      right: 12px;
      transform: rotate(180deg) translate(-3px, -3px);
    }
    &:nth-child(4) {
      bottom: 12px;
      left: 12px;
      transform: rotate(-90deg) translate(-3px, -3px);
    }
  }
  &:hover {
    border-color: #d2fb71;
    span {
      opacity: 1;
      &:nth-child(1) {
        transform: none;
      }
      &:nth-child(2) {
        transform: rotate(90deg);
      }
      &:nth-child(3) {
        transform: rotate(180deg);
      }
      &:nth-child(4) {
        transform: rotate(-90deg);
      }
    }
  }
}
@media (max-width: 767px) {
  .hover-border {
    transition: 0.5s;
    border: 2px solid transparent;
    position: relative;
    span {
      opacity: 0;
    }
    &:hover {
      border-color: transparent;
      span {
        opacity: 0;
      }
    }
  }
}
</style>
