import { createI18n } from 'vue-i18n'
import messages from './index'
// if(localStorage.getItem('language') == null){
  localStorage.setItem('language', 'en_US')
// }
let lang = localStorage.getItem('language');
const i18n = createI18n({
  locale: lang,
  messages,
  silentTranslationWarn: true
})
export default i18n