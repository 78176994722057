
import zhcnLocale  from './zhcn'
import en_US from './en'
export default {
  zh_TW:{
    ...zhcnLocale
  },
  en_US:{
    ...en_US
  }
}
