<template>
    <div class="drawer-con">
        <div class="userinfo">
            <div class="userinfo-top flex">
                <div class="top-account flex">
                    <img src="../public/images/loginicon.png" alt="" class="img1" />
                    <div>{{ $store.state.userinfo.account!=''?dealAddress($store.state.userinfo.account) :dealAddress($store.state.userinfo.userId)  }}</div>
                    <img src="../public/images/downicon.png" alt="" class="img2" />
                </div>
            
                <img src="../public/images/closeicon.png" alt="" class="close-modal" v-if="name=='headerNav'" @click="handlerClose" />
                <img src="../public/images/new/returnicon.png" alt="" class="close-modal" v-else @click="handlerClose" />
            </div>
            <div class="userinfo-con">
                <component :is="name" @handlerSwitch="handlerSwitch"></component>
            </div>
        </div>
  
        <div class="drawcon-footer drawcon-footer-pc">
            <div class="footer-link flex">
                <img src="../public/images/Telegram.png" alt="" @click="handlerPath('https://t.me/daoversal')" />
                <img src="../public/images/Twitter.png" alt="" @click="handlerPath('https://twitter.com/daoversal')" />
                <img src="../public/images/Facebook.png" alt="" @click="handlerPath('https://www.facebook.com/daoversal/')" />
         
                <img src="../public/images/Youtube.png" alt="" @click="handlerPath('https://www.youtube.com/@Daoversal')" />
            </div>
            <div class="footer-ip">© MYST2023.Daoversal Copyright</div>
        </div>
        <div class="drawcon-footer drawcon-footer-h5">
            <div class="footer-link flex">
                <img src="../public/images/Telegram-y.png" alt="" @click="handlerPath('https://t.me/daoversal')" />
                <img src="../public/images/Twitter-y.png" alt="" @click="handlerPath('https://twitter.com/daoversal')" />
                <img src="../public/images/Facebook-y.png" alt="" @click="handlerPath('https://www.facebook.com/daoversal/')" />
               
                <img src="../public/images/Youtube-y.png" alt="" @click="handlerPath('https://www.youtube.com/@Daoversal')" />
            </div>
            <div class="footer-ip">© MYST2023.Daoversal Copyright</div>
        </div>
    </div>
</template>

<script>
import headerNav from './headernav.vue';
import Trade from './trade.vue'
import Google from './google.vue'
import unbindGoogle from './unbindgoogle.vue'
import { mapState } from 'vuex'
import initConcat from '@/utils/initConcat'
import AccountInfo from './accountinfo.vue';
import TransferAccount from './transferAccount.vue';
export default {
    props: {
        drawer: {
            typeof: Boolean,
            default: false
        },
        type:{
            typeof:String,
            default:''
        }
    },
    components: {
        headerNav,
        Trade,
        Google,
        unbindGoogle,
        AccountInfo,
        TransferAccount
    },
    computed: {
        ...mapState(['address','isLogin']),
    },
    data() {
        return {
            isstep:1,
            name: 'headerNav',
            lang: 'EN',
            userinfo: {},

        }
    },
    mounted() {
        if (localStorage.getItem('token') != null) {
            this.$post(this.URL.user.info, {}).then(res => {
                if (res.code == 0) {
                    this.userinfo=res.data;
                    this.$store.commit('SET_USERDATA', res.data)
                } else {
                    this.$message.error(res.message)
                }
            })
        }
        if(this.type==1){
            this.name='Trade'
        }else if(this.type==2){
            this.name='Google'
        }else if(this.type==3){
            this.name = 'TransferAccount'
        }
    },
    methods: {
        handlerPath(url){
            window.open(url)
        },
        dealAddress(str) {
            if (str && str.length > 10) {
                return str.substring(0, 4) + '....' + str.substring(str.length - 4, str.length)
            } else {
                return str
            }
        },
        handleCommand(val) {
            localStorage.setItem('language',val);
            location.reload();
        },
        concactWallet(id) {
            sessionStorage.setItem("concactId", id)
            this.$emit('handlerClose')
            // 连接钱包
            initConcat.init(id, (address, chainId) => {
                // this.address = address;
                // this.chainId = chainId;
                this.handlerClose()
                //this.$router.push('/home')
                // setTimeout(() => {
                //    this.$router.push('/home')
                // }, 1500);
            });
        },
        handlerClose() {
            if (this.name == 'headerNav') {
                this.$emit('handlerClose')
            } else {
                this.name = 'headerNav'
            }
        },
        handlerSwitch(val) {
            if(val == 1){
                this.$emit('handlerClose')
            }else{
                this.name = val;     
            }
        },
        handlerdrawer() {
            this.drawer = true;
            this.name = 'headerNav'
        },
        handlerSetp(){
          
            this.isstep=2;
        }
    }
}
</script>

<style lang="less" scoped>
.drawer-con {
    position: relative;
    min-height: 100vh;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .drawcon-footer {
        margin-top: 50px;
        width: 100%;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        &-pc {display: block;}
        &-h5 {display: none;}

        .footer-link {
            padding: 24px 0;

            img {
                width: 24px;
                height: auto;
                margin-right: 18px;
                cursor: pointer;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .footer-ip {
            font-size: 12px;
            color: #999999;
        }
    }
    .footerbottom{
            margin: 50px 0 0;
            bottom: inherit;
            position:initial;
        }
}

.userinfo {
    .userinfo-top {
        padding: 28px 0 30px;
        justify-content: space-between;

        .top-account {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 0 0 220px;
            width: 220px;
            height: 38px;
            background: url('../public/images/new/loginbg2.png') center no-repeat;
            background-size: 100%;
            padding: 0 15px;

            .img1 {
                flex: 0 0 8px;
                width: 8px;
                height: 8px;
            }

            .img2,
            .img2-h5 {
                flex: 0 0 8px;
                width: 8px;
                height: 5px;
            }
            .img2 {display: block;}
            .img2-h5 {display: none;}

            div {
                flex: 1;
                font-family: 'Nebula';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: #FFFFFF;
                overflow: hidden;
                line-height: 38px;
                text-align: center;
                cursor: pointer;
            }
        }

        .login-lang {
            margin-top: 10px;
            height: 17px;
            cursor: pointer;

            .el-dropdown-link {
                display: flex;
                font-size: 15px;
                color: #111111;

                img {
                    width: 8px;
                    height: 5.5px;
                    margin: 5px 0 0 4px;
                }
            }
        }

        .close-modal,
        .close-modal-h5 {
            flex: 0 0 20px;
            width: 21px;
            height: 21px;
            margin: 9px 0 0 0;
            cursor: pointer;
        }
        .close-modal-h5 {display: none;}
    }

    .userinfo-con {
        width: 100%;
    }
}

.login {
    position: relative;
    padding: 81px 0 0;

    .close-modal,
    .close-modal-h5 {
        position: absolute;
        top: 24px;
        right: 32px;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
    .close-modal-h5 {display: none;}

    .login-name {
        font-size: 24px;
        color: #000;

    }

    .login-desc {
        padding: 29px 0 44px;
        font-size: 16px;
        color: #000;
        line-height: 28px;
    }

    .login-btn {
        width: 100%;
        height: 50px;
        margin-bottom: 16px;
        background: url('../public/images/loginbg1.png') center no-repeat;
        background-size: 100% 100%;
        cursor: pointer;

        img {
            flex: 0 0 24px;
            width: 24px;
            height: 23px;
            margin: 13px 20px 0 14px;
        }

        div {
            flex: 1;
            margin-right: 20px;
            font-size: 16px;
            font-family: 'Nebula';
            color: #000;
            white-space: nowrap;
        }
    }
}
@media (max-width:1200px) {
    .drawer-con{
        max-width:400px;
        padding-top: 5px;
        .drawcon-footer{
            position: inherit;
            bottom: inherit;
            border: 0;
            &-pc {display: none;}
            &-h5 {
                display: block;
                padding-left: 30px;
                .footer-link {
                    padding: 24px 0;
                    img {
                        width: 13px;
                        margin-right: 25px;
                    }
                }
            }
            .footer-ip {
                color: #FFE27F;
            }
        }
    }
    .userinfo {
      
      
        .userinfo-top {
            padding: 13px 32px 40px;

            .top-account {
                flex: 0 0 177px;
                height: 38px;
                padding: 0 8px;

                div {
                    line-height: 28px;
                }
            }

            .login-lang {
                display: none;
            }
           
           
        }
        .userinfo-con {
            // border-top: 1px solid #FFE27F;
            border: none;
        }
    }
    .login {
        background: linear-gradient(180deg, rgba(255, 226, 127, 0.6) -10.71%, rgba(110, 85, 38, 0.6) 84.58%);
        // opacity: 0.5;
        // backdrop-filter: blur(4.5px);
        border-radius: 20px 0px 0px 20px;
        padding: 71px 32px;
        .close-modal-h5 {
            display: block;
        }
        .close-modal {
            display: none;
        }
        .login-name {
            color: #FFE279;
            font-family: 'Nebula';
        }
        .login-desc {
            font-size: 12px;
            line-height: 17px;
            color: #FFE279;
        }
        .login-btn {
            background: url('../public/images/loginbg3.png') center no-repeat;
            background-size: 100% 100%;
            div {font-size: 14px;}
        }
    }
}
</style>