import store from '../store'
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from 'web3modal'
import Web3 from "web3";
import { post, get} from "./http";
import URL from "./url";
import i18n from '../public/lang/i18n'
import Router from "../router";

// import ethUSDTABI from '../public/abi/ethUSDTABI.json';
// import bscUSDTABI from '../public/abi/bscUSDTABI.json';
// import ERC20ABI from '../public/abi/ERC20ABI.json';

import BigNumber from 'bignumber.js'
import {
  ethers
} from "ethers";
import { Toast } from 'vant';
import {
  ElNotification
} from 'element-plus'


// const contractAddress = store.state.contractAddress // 目前是USDT的地址
// const Web3Modal = window.Web3Modal.default;
// const WalletConnectProvider = window.WalletConnectProvider.default;
// const EvmChains = window.evmChains;
// const Fortmatic = window.Fortmatic;
const buyAddress = store.state.buyAddress
let web3Provider
let web3ModalNew
let provider
let fn = null
let web3 = null

let lang = localStorage.getItem('language')

let accountAddress = store.state.address
let networkName = ''
// const Fortmatic = window.Fortmatic;
export default {
  async exchangeMainet(str,fn) { // 用户在页面切换链路
    let that = this
    let exChainId = '0x' + str.toString(16)
    const { ethereum } = window;
    if (ethereum) {
      try {
        await ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: exChainId }],
        }).then((res) => {
          console.log('切换成功');
          if (fn) fn()
        });
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          console.log('需要添加');
          that.changeMainet(fn)
        }
        // handle other "switch" errors
      }
    }
  },
  async changeMainet(fn) { // 为用户生成链路
    let that = this
    const { ethereum } = window;
    if (ethereum) {
      await ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: '0x38', // 必须将链的整数 ID 指定为十六进制字符串
            // 56 转16进制：a = 56,a = '0x' + a.toString(16)
            chainName: 'BSC',
            nativeCurrency: {
              name: 'BNB',
              symbol: 'BNB',
              decimals: 18, // 必须是非负整数
            },
            // iconUrls: ['../assets/images/bsc.png'],
            rpcUrls: ['https://bsc-dataseed.binance.org/'],
            // rpcUrls: ['https://bsc-dataseed1.binance.org/'],
            blockExplorerUrls: ['https://bscscan.com/'],
            // 注释参考：https://eips.ethereum.org/EIPS/eip-3085
          },
        ],
      }).then((res) => {
        // 无法监听用户取消/确定动作
        let num = 0
        let ethInter = setInterval(() => {
          num++
          if (num >= 20) clearInterval(ethInter)
          if (ethereum.networkVersion == '56' || ethereum.chainId == '56') {
            clearInterval(ethInter)
            this.init(fn)
          }
        }, 200);
      }).catch((e) => {
      })
    }
  },
  initCheckNetWork(callback) {
    if (callback) fn = callback
    if (typeof window.ethereum !== 'undefined') {
      // const { ethereum } = window;
      // if (ethereum.networkVersion!='56'&&ethereum.chainId!='56') {
      //   this.changeMainet()
      // } else {
      //   this.init()
      // }
      this.init()
    } else {
      // this.init()
      let toaStr = '请通过DAPP浏览器访问地址'
      if (lang == 'en_US') toaStr = 'Please visit the address via DAPP browser.'
      this.noti(toaStr)
    }
  },
  init(id, callback) { // 创建Web3Modal实例
    if (callback) fn = callback
    const providerOptions = {
      /* See Provider Options Section */
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          // infuraId: "6367cad1841a4e5cbc1b02014812c2f1", // 个人 alan
          // infuraId: "9321e08afdc04e2c81cabc499dc5d569", // 网上
          infuraId: "52d82fe657a2478fa40b336d286554ac", // kson
        },
        // options: {
        //   rpc: {
        //       56: "https://bsc-dataseed.binance.org/",
        //   },
        //   network: "binance",
        //   chainId: 56,
        //   infuraId: "687d7aae5ff147fe97df2652dde02461", // required
        //   appLogoUrl:"",
        //   name:"11111"
        // }
        options: {
          rpc: {
            56: "https://bsc-dataseed.binance.org/",
          },
          network: "binance",
          chainId: 56,
          infuraId: "52d82fe657a2478fa40b336d286554ac", // required
          appLogoUrl:"",
          name:"11111"
        }
      },
      // fortmatic: {
      //   package: Fortmatic,
      //   options: {
      //     // Mikko's TESTNET api key
      //     key: "pk_test_391E26A3B43A3350"
      //   }
      // }
    };
    web3ModalNew = new Web3Modal({
      // network: "mainnet", // optional
      // network: [56],
      cacheProvider: true, // optional
      providerOptions: id == 1 ? {} : providerOptions, // required
      disableInjectedProvider: id == 1 ? false : true, // optional. For MetaMask / Brave / Opera.
    });
    // return
    this.onConnect()
  },
  async onConnect() { // 开始链接
    let that = this
    try {
      if (web3Provider == null) {
        web3Provider = await web3ModalNew.connect();
        web3 = new Web3(web3Provider);
      }
      // that.isConnectSuc = true
    } catch (e) {
      console.log("Could not get a wallet connection", e);
      return;
    }
    // Subscribe to accounts change
    web3Provider.on("accountsChanged", (accounts) => {
      console.log("accountsChanged=====", "changed");
      console.log(accounts);
      this.onDisconnect('', true); // web3modal.cachedProvider
      this.reset()
      // if (accounts && accounts.length > 0) {
      //   setTimeout(() => {
      //     let chainList = JSON.parse(localStorage.getItem('chainList'))
      //     let chainBol = false
      //     let netChainId
      //     const { ethereum } = window;
      //     if (ethereum && ethereum.networkVersion) netChainId = ethereum.networkVersion
      //     if (web3Provider && web3Provider.chainId) netChainId = web3Provider.chainId
      //     for (let i = 0; i < chainList.length; i++) { // 暂时不弄128 heco的火币链
      //       if (chainList[i].chainId && netChainId == chainList[i].chainId) {
      //         chainBol = true
      //         this.fetchAccountData();
      //         Router.push('/')
      //         break;
      //       }
      //     }
      //     if (!chainBol) this.noti(`${i18n.global.t("load.no_support")}`)
      //   }, 300);
      // } else {
      //   this.onDisconnect(); // web3modal.cachedProvider
      //   this.reset()
      // }
      // this.fetchAccountData();
    });
    // Subscribe to chainId change
    web3Provider.on("chainChanged", (chainId) => {
      console.log("===========", "chainChanged");
      // 0x38 ===> 56 0x1 ===> 1 0x80 ===> 128
      // let nowId = ethereum.networkVersion
      setTimeout(() => {
        let chainList = JSON.parse(localStorage.getItem('chainList'))
        let chainBol = false
        let netChainId
        const { ethereum } = window;
        if (ethereum && ethereum.networkVersion) netChainId = ethereum.networkVersion
        if (web3Provider && web3Provider.chainId) netChainId = web3Provider.chainId
        for (let i = 0; i < chainList.length; i++) { // 暂时不弄128 heco的火币链
          if (chainList[i].chainId && Number(netChainId) == chainList[i].chainId) {
            chainBol = true
            this.fetchAccountData();
            Router.push('/')
            break;
          }
        }
        if (!chainBol) this.noti(`${i18n.global.t("load.no_support")}`)
      }, 300);
    });
    web3Provider.on("disconnect", (error) => {
      console.log('退出了------------------------');
      this.onDisconnect('', true); // web3modal.cachedProvider
      this.reset()
    });
    await this.refreshAccountData();
  },
  async refreshAccountData() {
    await this.fetchAccountData(web3Provider);
  },
  async fetchAccountData() {
    let that = this
    store.commit('SET_ADDRESS', '')
    // Get a Web3 instance for the wallet
    provider = new ethers.providers.Web3Provider(web3Provider);
    // Get connected chain id from Ethereum node
    that.signer = provider.getSigner();
    const chainId = await that.signer.getChainId();
    // Load chain information over an HTTP API
    // const chainData = await EvmChains.getChain(chainId);
    // 给networkName赋值
    // networkName = chainData.name;
    // store.commit('SET_NETWORKNAME',networkName)
    localStorage.setItem('chainId',chainId)
    let chainList = JSON.parse(localStorage.getItem('chainList'))
    for (let i = 0; i < chainList.length; i++) { // 暂时不弄128 heco的火币链
      if (chainList[i].chainId && chainId == chainList[i].chainId) {
        localStorage.setItem('chainName',chainList[i].chainName)
      }
    }
    provider.listAccounts().then(function (accounts) {
      accountAddress = accounts[0];
      store.commit('SET_ADDRESS', accounts[0])
      localStorage.setItem('address',accounts[0])
      // if (fn) fn(accounts[0])
      that.getlogin(fn)
    })
  },
  async onDisconnect(onDisconnectCallBack,bol) { // 断开链接
    // bol true 钱包断开，代码监听 false 代码断开
    console.log("Killing the provider connection", "provider off");
    // If the cached provider is not cleared,
    // WalletConnect will default to the existing session
    // and does not allow to re-scan the QR code with a new wallet.
    // Depending on your use case you may want or want not his behavir.
    // 本地已断开，通过以下代码远程断开钱包应用上的链接
    if (!bol && web3 && web3.currentProvider && web3.currentProvider.close) {
      await web3.currentProvider.close();
    }
    if (web3Provider && web3Provider.removeAllListeners) {
      web3Provider.removeAllListeners('accountsChanged')
      web3Provider.removeAllListeners('chainChanged')
    }
    // if (web3Provider && web3Provider.close) {
    //   await web3Provider.close()
    //   await web3ModalNew.clearCachedProvider();
    // }
    if (web3ModalNew && web3ModalNew.clearCachedProvider) {
      await web3ModalNew.clearCachedProvider();
    }
    // web3ModalNew.reset()
    web3Provider = null
    // web3ModalNew = null
    // provider = null
    accountAddress = null;
    if (window.location.pathname == '/') {
      // window.location.reload()
    } else {
      localStorage.setItem('reload', true)
      Router.push('/')
    }
    // Set the UI back to the initial state
  },
  _loginSignKey(fn) {
    post(`${URL.home.getKey}`, {
      address: accountAddress,
      chainId: localStorage.getItem('chainId')
    }).then(res => {
      if (res.code == 0) {
        if (fn) fn(res.data)
      }
    })
  },
  async _getSignature(signKey,fn) {
    web3.eth.personal.sign(signKey,accountAddress,'1111',(err,signature)=> {
      if (signature != undefined) {
        if (fn) fn(signature)
      }
    })
  },

  //授权登录
  getlogin(loginFn){
    let that = this
    if(store.state.address == ''){
      return
    }

    let id = ''
    let chainId = localStorage.getItem('chainId')
    let chainList = JSON.parse(localStorage.getItem('chainList'))
    for (let i = 0; i < chainList.length; i++) {
      if (chainList[i].chainId == chainId) {
        id = chainList[i].chainName
        break;
      }
    }
    post(URL.home.check,{
      chainType:id,
      address:accountAddress
    }).then((res) => {
      if (res.code == 0) {
        if(!res.data.hasRegister){
          if(store.state.inviteCode == ''){
            Router.push('/reg?id='+id)
          }else{
            that._loginSignKey(function(key) {
              if(res.code==0){
              that._getSignature(key,function(sign) {
                post(URL.home.userAuth,{
                  address: accountAddress,
                  chainType:id,
                  walletSign: sign,
                  inviteCode:store.state.inviteCode
                }).then((res) => {
                  if (res.code == 0) {
                    store.commit('SET_TOKEN', res.data.token)
                    localStorage.setItem('token', res.data.token)
                    post(URL.member.info,{
                    }).then((ress) => {
                      if (res.code == 0) {
                        store.commit('SET_USERDATA', ress.data)
                        store.commit('SET_LOGIN', true)
                        store.commit("SET_USERINFO", ress.data);
                        console.log(store.state.isLogin);
                        if (loginFn) loginFn()
                      }
                    });
                  }else{
                    Toast(res.message)
                    Router.push('/reg?id='+id)
                  }
                });
              })
            }
            })
          }

        }else{
          that._loginSignKey(function(key) {
            that._getSignature(key,function(sign) {
              post(URL.home.userAuth,{
                address: accountAddress,
                chainType:id,
                walletSign: sign,
                inviteCode:store.state.inviteCode
              }).then((res) => {
                if (res.code == 0) {
                  store.commit('SET_TOKEN', res.data.token)
                  localStorage.setItem('token', res.data.token)
                  post(URL.member.info,{
                  }).then((ress) => {
                    if (res.code == 0) {
                     
                      console.log(store.state.isLogin);
                      store.commit('SET_USERDATA', ress.data)
                      store.commit('SET_LOGIN', true)
                      store.commit("SET_USERINFO", ress.data);
                      console.log(store.state.isLogin);
                      if (loginFn) loginFn()
                    }
                  });
                }else{
                  Toast(res.message)
                  Router.push('/reg?id='+id)
                }
              });
            })
          })
        }
      }
    });
  },
  // getPayStatus(fn) { // 检查当前是否能进行支付
  //   let status = null
  //   post(URL.home.payStatus,{
  //   }).then((res) => {
  //     if (res.code == 0) {
  //       status = res.data.hasAllow
  //       if (fn) fn(status)
  //     }
  //   })
  // },
  reset() {
    console.log('重置');
    store.commit('SET_TOKEN', '')
    store.commit('SET_USERDATA', {})
    store.commit('SET_LOGIN', false)
    store.commit('SET_NETWORKNAME','')
    store.commit('SET_ADDRESS', '')
    store.commit('SET_BUYADDRESS', '')
    localStorage.removeItem('chainId')
    localStorage.removeItem('token')
  },



  /*
    合约接入开始
  */
  //获取某种代币的精度  传入参数：1，token的合约地址
  async _getTokenDecimals(tokenAddress) {
    const erc20Contract = new web3.eth.Contract(ERC20ABI, tokenAddress)
    try {
      const decimals = await erc20Contract.methods.decimals().call({ from: accountAddress });
      return decimals
    } catch (e) {
      console.log(e)
    }
  },
  //获取某种代币的数量  传入参数：1，token的合约地址
  async _getBalance(tokenAddress) {
    let erc20Contract = new web3.eth.Contract(ERC20ABI, tokenAddress)
    var balance = await erc20Contract.methods.balanceOf(accountAddress).call({ from: accountAddress });

    let _Decimals = await this._getTokenDecimals(tokenAddress);
    balance = this._getValueDivided(balance, 10 ** _Decimals);

    return balance;
  },

  ///////////////////////////////////////////单币抵押  StakingPoolABI/////////////////////////////////////////////////////
  //获取授权额度
  async _getAllowance(tokenAddress, spenderAddress, fn) {
    let erc20Contract = new web3.eth.Contract(ERC20ABI, tokenAddress)
    var allowance = await erc20Contract.methods.allowance(accountAddress, spenderAddress).call({ from: accountAddress });
    let _Decimals = await this._getTokenDecimals(tokenAddress);
    allowance = this._getValueDivided(allowance, 10 ** _Decimals);
    if (fn) fn(allowance)
  },
  //授权tokenAddress到spenderAddress , 如tokenAddress=usdt  spenderAddress=需要授权的地址
  async _Approve(tokenAddress, spenderAddress, amount, callback, errCallback) {
    let that = this
    let contract = new web3.eth.Contract(ERC20ABI, tokenAddress)

    contract.methods.approve(spenderAddress, amount).send({ from: accountAddress })
      .on('transactionHash', function (hash) {
        console.log("hash--->>>>>", hash)
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        // console.log("receipt--->>>>>",receipt)
      })
      .on('receipt', function (receipt) {
        if (callback) callback()
        console.log("receipt--->>>>>", receipt)
      })
      .on('error', function (error) {
        if (errCallback) errCallback(error)
        that.toastError(error)
      })
  },

  /*
    mint开始
  */
    async _mintNFT(mintContract, eventId, account, saltSeed, v, r, s, callback, errCallback) {
      let that = this
      let contract = new web3.eth.Contract(MINTABI, mintContract)
      contract.methods.mintNFT(eventId, account, saltSeed, v, r, s).send({ from: accountAddress })
        .on('transactionHash', function (hash) {
          console.log("hash--->>>>>", hash)
        })
        .on('confirmation', function (confirmationNumber, confirmation) {
          // console.log("confirmation--->>>>>",confirmation)
        })
        .on('receipt', function (receipt) {
          if (callback) callback(receipt)
          console.log("receipt--->>>>>", receipt)
        })
        .on('error', function (error) {
          if (errCallback) errCallback(error)
          that.toastError(error)
        })
    },
    async _mintUserMintCounte(mintContract, eventId, fn) {
      let erc20Contract = new web3.eth.Contract(MINTABI, mintContract)
      var count = await erc20Contract.methods.userMintCount(eventId, accountAddress).call({ from: accountAddress });
      console.log(count);
      if (fn) fn(count)
    },


  hashAction(hash, action) {
    ethersProvider.getTransactionReceipt(hash).then((receipt) => {
      let to = 0;
      if (receipt && receipt.to) {
        to = 1;
      }
      action(to, receipt)
    });
  },
  _getValueDivided(value, value2) {
    //除法
    let bg1 = new BigNumber(value)
    let bg2 = new BigNumber(value2)
    let amount = bg1.dividedBy(bg2).toFixed();
    return amount;
  },
  _getValueDivided2(value, value2) {
    //除法
    let bg1 = new BigNumber(value)
    let bg2 = new BigNumber(value2)
    let amount = bg1.dividedBy(bg2).toFixed(2, 1);
    return amount;
  },
  _getValueMultip(value, value2) {
    //乘法
    let params1 = new BigNumber(value)
    let valueDecimals = params1.multipliedBy(value2).toFixed()
    return valueDecimals
  },
  _getValueMinus(value, value2) {
    // 减法
    let bg1 = new BigNumber(value)
    let bg2 = new BigNumber(value2)
    let amount = bg1.minus(bg2).toFixed();
    return amount;
  },
  _getValueAdd(value, value2) {
    //加法
    var bg1 = new BigNumber(value);
    var bg2 = new BigNumber(value2);
    let amount = bg1.plus(bg2).toFixed();
    return amount;
  },
  toastError(res) {
    if (res.code == '4001') {
      this.noti(`${i18n.global.t("public.desc5")}`)
      return
    }
    if (res.error != undefined && res.error.reason != undefined) {
      this.noti(res.error.reason)
    } else if (res.error != undefined && res.error.message != undefined) {
      this.noti(res.error.message)
    } else if (res.data != undefined && res.data.message != undefined) {
      this.noti(res.data.message)
    } else if (res.message != undefined) {
      this.noti(res.message)
    } else {
      this.noti(res)
    }
  },
  noti(str) {
    Toast({
      message: str,
      duration: 1500,
    });
  }
}