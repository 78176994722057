const zh_TW = {
  common: {
    desc1: "即將推出",
    desc2: "我知道了",
    desc3: "确定",
    desc4: "提示",
    desc5: "没有更多了",
    desc6: "轉入",
    desc7:'加载中...',
    desc8:"我们很抱歉，但出了问题。我们已经接到关于这个问题的通知。"
  },
  login: {
    desc1: "登錄",
    desc2: "選擇一個可用的錢包提供商或創建一個新的錢包。什麼是錢包？ ",
    desc3: "使用MetamasK登錄",
    desc4: "WalletConnect",
    desc5: "首頁",
    desc6: "市場",
    desc7: "礦池",
    desc8: "資產",
    desc9: "我的",
    desc10: "連接錢包",
    desc11: "聯繫客服",
    desc12: "網絡異常，連接超時...",
    desc13: "語言選擇",
    desc14: "礦池詳情",
  },
  home: {
    desc: "首頁",
    desc1: "我的土地",
    desc2: "資產",
    desc3: "兌換",
    desc4: "土地市場",
    desc5: "礦池列表",
    desc6: "邀請好友",
    desc7: "交易密碼",
    desc8: "谷歌驗證碼",
    desc9: "幫助中心",
    desc10: "退出登錄",
    desc11: "設置交易密碼",
    desc12: "新的交易密碼",
    desc13: "請輸入新的交易密碼",
    desc14: "確認交易密碼",
    desc15: "請再次輸入交易密碼",
    desc16: "谷歌驗證碼",
    desc17: "請輸入谷歌驗證碼",
    desc18: "注意：如果忘記密碼，只能通過聯繫客服進行重置修改。 ",
    desc19: "密碼錯誤",
    desc20: "確定",
    desc21: "兩次密碼不一致",
    desc22: "設置谷歌驗證碼",
    desc23: "下載APP",
    desc24: "掃描二維碼",
    desc25: "綁定谷歌驗證",
    desc26: '請在手機應用商店下載"Google身份驗證器”',
    desc27: "Download from",
    desc28: "App Store",
    desc29: "Google play",
    desc30: "通過谷歌身份驗證器掃描二維碼綁定",
    desc31: "複製秘鑰",
    desc32: "通過驗證您的帳戶啟用身份驗證器",
    desc33: "解綁谷歌驗證碼",
    desc34: "通過谷歌身份驗證器解除綁定",
    desc35: "輸入來自谷歌驗證器的 6 位數驗證碼",
    desc36:
      "注意：一旦解除綁定谷歌驗證碼，您的帳號安全將會下降很多。建議您解除以後儘快重新綁定谷歌驗證碼，以確保賬戶安全。如因為個人安全原因導致賬號出現資產安全問題，將與Daoversal無關。一旦解除綁定將視為默認自己承擔風險。 ",
    desc37: "解除綁定",
    desc38: "設置成功",
    desc39: "登錄已過期，請重新登錄",
    desc40: "節點購買",
    desc41: "您已經解除綁定。建議您盡快重新綁定谷歌驗證碼，以確保賬戶安全。 ",
    desc42: "恭喜！您已成功綁定谷歌驗證碼",
    desc43: "設置交易密碼成功",
    desc44: "確定退出登錄?",
    desc45: "取消",
    desc46: "確定",
    desc47: "退出登錄成功",
    desc48: "請先設置交易密碼",
    desc49: "請先綁定谷歌驗證碼",
    desc50: "等級:",
    desc51: "ID:",
    desc52: "坐標:",
    desc53: "可用土地:",
    desc54: "星球坐標",
    desc55: "Track",
    desc56: "No.",
    desc57: "Star-ID",
    desc58: "Apply",
    desc59: "Clear",
    desc60: "星球名:",
    desc61: "未命名",
    desc62: "不可命名",
    desc63: "土地市場",
    desc64: '請先設定谷歌驗證碼',
    desc65: '請先設定谷歌驗證碼後再進行資金密碼設定',
    desc66: '切換谷歌驗證',
    desc67: '切換郵箱驗證'
  },
  planet: {
    desc: "類型",
    desc1: "請選擇",
    desc2: "狀態",
    desc3: "時間",
    desc4: "切換為系列列表",
    desc5: "剩餘收益",
    desc6: "星球編號",
    desc7: "星球等級",
    desc8: "重耕土地",
    desc9: "轉讓",
    desc10: "切換為土地列表",
    desc11: "已收集",
    desc12: "已質押",
    desc13: "星球詳情",
    desc14: "轉讓土地",
    desc15: "地址",
    desc16: "請輸入地址",
    desc17: "請輸入交易密碼",
    desc18: "請輸入谷歌驗證器密碼",
    desc19: "考慮一下",
    desc20: "確認支付",
    desc21: "土地重耕",
    desc22: "您正在將「<span>{n1}</span>」重耕成為「<span>{n2}</span>」",
    desc23: "需要支付",
    desc24: "土地等級",
    desc25: "產出類型",
    desc26: "出局倍數",
    desc27: "土地總量",
    desc28: "購買",
    desc29: "是否要購買土地？ ",
    desc30: "您確定消費{n1} 購買以後物品",
    desc31: "我點錯了",
    desc32: "確認購買",
    desc33: "系列展示",
    desc34: "土地展示",
    desc35: "{n1}倍",
    desc36: "購買成功",
    desc37: "您還沒有購入土地",
    desc38: "立即擁有您的Daoversal土地吧！ ",
    desc39: "查看星系",
    desc40: "全部",
    desc41: "已質押",
    desc42: "未質押",
    desc43: "編號",
    desc44: "價格降序",
    desc45: "價格升序",
    desc46: "接收者用戶id",
    desc47: "請輸入接收者用戶id",
    desc48: "轉讓成功",
    desc49: "重耕成功",
    desc50: "支付成功",
    desc51: "恭喜!您已經成功購買「{n1}」土地{n2}塊",
    desc52: "查看我的土地",
    desc53: "轉賬記錄",
    desc54: "土地圖片",
    desc55: "土地編號",
    desc56: "接收地址",
    desc57: "轉賬地址",
    desc58: "轉賬ID",
    desc59: "接收ID",
    desc60: "轉賬時間",
    desc61: "複製成功",
    desc62: "土地狀態",
    desc63: "質押中",
    desc64: "更多",
    desc65: "收起",
    desc66: "土地市場",
    desc67: "暫未開放",
    desc68: "已命名",
    desc69: "可命名",
  },
  pool: {
    desc: "待領取",
    desc1: "質押資產(RP)",
    desc2: "質押數量",
    desc3: "土地收益",
    desc4: "推薦收益",
    desc5: "社區收益",
    desc6: "領取收益",
    desc7: "質押池",
    desc8: "選擇你需要的土地質押池，來換取平台不同的挖礦獎勵",
    desc9: "質押土地，賺取資源獎勵",
    desc10: "質押數量",
    desc11: "APR",
    desc12: "挖礦週期",
    desc13: "{n1}天",
    desc14: "資源類型",
    desc15: "星球ID",
    desc16: "土地購買",
    desc17: "星球坐標",
    desc18: "我已擁有",
    desc19: "購買土地",
    desc20: "{n1} {n2}買{n3}塊",
    desc21: "此星球剩餘全部土地",
    desc22: "自定義數量",
    desc23: "地塊數量",
    desc24: "合計價格",
    desc25: "立即購買",
    desc28: "購買",
    desc29: "質押池詳情",
    desc30: "土地",
    desc31: "您可以在保持土地所有權的同時賺取額外的錢",
    desc32: "檢測到您當前有可質押的星球/土地",
    desc33: "去質押",
    desc34: "土地質押數量",
    desc35: "土地折合資產",
    desc36: "動態礦池",
    desc37: "{n1}天礦池",
    desc38: "編號",
    desc39: "土地等級",
    desc40: "開始時間",
    desc41: "結束時間",
    desc42: "贖回",
    desc43: "鎖倉中",
    desc44: "未贖回",
    desc45: "已贖回",
    desc46: "近7天",
    desc47: "近15天",
    desc48: "近30天",
    desc49: "近90天",
    desc50: "近180天",
    desc51: "近365天",
    desc52: "可質押的土地",
    desc53: "當前正在質押「{n1}」",
    desc54: "選中的進行質押",
    desc55: "將全部質押",
    desc56: "剩餘結算數量",
    desc57: "{n1}收益",
    desc58: "請選擇要質押的土地",
    desc59: "土地質押成功",
    desc60: "是否要贖回土地？ ",
    desc61:
      '您確定贖回單價為<span style="color:#fff;">{n1}{n2}</span>「#{n3}_{n4}」土地嗎？ ',
    desc62: "立即贖回",
    desc63: "贖回成功",
    desc64: "土地收益概覽",
    desc65: "領取收益成功",
    desc66: "你可以選擇對應的幣種進行購買此土地",
    desc67: "{n1}餘額：",
    desc68: "可用餘額不足",
    desc69: "你可以選擇對應的幣種進行重耕此土地",
    desc70: "複利收益",
    desc71: "全球收益",
    desc72: "你已經購買了土地",
    desc73: "前往動態礦池進行質押！ ",
    desc74: "前往質押",
    desc75: "管理",
    desc76: "礦池",
    desc77: "索賠資源",
    desc78: "土地狀態",
    desc79: "活動",
    desc80: "命名資源",
    desc81: "資源：",
    desc82: "每日收益率：",
    desc83: "週產量：",
    desc84: "領取",
    desc85: "停止採礦",
    desc86: "停止採礦？ ",
    desc87: "每一塊停止採礦的土地的剩餘資源將被處以5% 的罰款",
    desc88: "剩餘資源",
    desc89: "令牌ID",
    desc90: "已選擇停止",
    desc91: "全部停止",
    desc92: "買{n1}塊",
    desc93: "全部",
    desc94: "活動",
    desc95: "藝術挖掘",
    desc96: "我選擇了",
    desc97: "我全部",
    desc98: "A浮動APR Is",
    desc99: "剩餘資源",
    desc100: "不活躍",
    desc101: "活躍",
    desc102: "命名資源",
    desc103: "選擇土地",
    desc104: "繼續購買",
    desc105: "土地價格",
    desc106: "待質押",
  },
  asset: {
    desc: "我的資產",
    desc1: "提現",
    desc2: "充值",
    desc3: "兌換",
    desc4: "明細記錄",
    desc5: "邀請好友",
    desc6: "總資產估值",
    desc7: "明細記錄",
    desc8: "充值記錄",
    desc9: "提現記錄",
    desc10: "兌換記錄",
    desc11: "幣種",
    desc12: "餘額",
    desc13: "比例",
    desc14: "充值",
    desc15: "資產分佈總覽圖",
    desc16: "選擇幣種",
    desc17: "幣種",
    desc18: "轉賬到",
    desc19: "轉賬網絡",
    desc20: "使用交易平台APP或錢包APP掃碼",
    desc21: "保存二維碼",
    desc22: "充值須知：",
    desc23: "1.最小充值：0.01 USDT，小於最小金額將無法到賬；",
    desc24: "2.請勿向上述地址充值非USDT資產，否則資產無法找回；",
    desc25: "3.請將客戶端或在線錢包將您需要充值的相應幣種數目發送到該地址。 ",
    desc26: "充值記錄",
    desc27: "選擇網絡",
    desc28: "選擇幣種",
    desc29: "注意事項",
    desc30:
      "對於數字貨幣的充值和提幣，由於區塊鏈交易不可逆、匿名的特點，一旦您輸入錯誤信息，您的資產或將無法找回。請謹慎操作。 ",
    desc31: "提現",
    desc32: "提現記錄",
    desc33: "請輸入地址",
    desc34: "提現金額",
    desc35: "數量",
    desc36: "請輸入需要提取的資產數量",
    desc37: "安全",
    desc38: "交易密碼",
    desc39: "請輸入交易密碼",
    desc40: "谷歌ATM",
    desc41: "請輸入谷歌驗證器密碼",
    desc42: "當前幣種餘額",
    desc43: "最高提取",
    desc44: "最低提取",
    desc45: "最低",
    desc46: "手續費",
    desc47: "實際到賬",
    desc48: "提交成功",
    desc49: "下載成功",
    desc50: "充幣地址",
    desc51: "複製地址",
    desc52: "投資賬戶",
    desc53: "鏈上充值",
    desc54: "選擇您需要充值的幣種",
    desc55: "選擇充值網絡",
    desc56: "充值幣種",
    desc57: "充值{n2}自動識別為充值{n1}",
    desc58: "充值地址",
    desc59: "搜索",
    desc60: "1.请勿将您选择的代币以外的任何数字资产存入上述地址。 否则，您可能会永久失去您的资产。",
    desc61:
      "2. 请确保所选网络与您提现或充值的平台网络一致。 选择错误的网络可能会导致您的资金损失。",
    desc62: "3.您的充值地址不会经常改变。 如有变更，我们将通过公告或电子邮件通知您。",
    desc63: "4、请确保您的计算机和浏览器安全，并确保您的信息不被篡改或泄露。",
    desc64: "（如果您打算将资金从钱包转移到我们的 BEP-20 地址，请确保提款也是通过 BEP-20 网络执行。）",
    desc65: "6、請將客戶端或在線錢包將您需要充值的相應幣種數目發送到該地址。 ",
    desc66: "查看更多",
    desc67: "鏈上提現",
    desc68: "選擇您需要提現的幣種",
    desc69: "選擇網絡/地址",
    desc70: "輸入或粘貼提現地址",
    desc71: "提現數量",
    desc72: "最少提現{n1}{n2}",
    desc73: "提現DU的時候，我們會自動幫您兌換成對應主網的USDT",
    desc74: "可用餘額",
    desc75: "確認提現",
    desc76: "最多提現{n1}{n2}",
    desc77: "提示",
    desc78: "提現地址",
    desc79: "命名星球",
    desc80: "查看明細",
    desc81: "最近10條充值記錄",
    desc82: "溫馨提示",
    desc83: "充值詳情",
    desc84: "資產詳情",
    desc85: "{n2}-{n1}資產",
    desc86: "流水詳情",
    desc87: "重置",
    desc88: "搜索",
    desc89: "出賬",
    desc90: "入賬",
    desc91: "交易成功",
    desc92: "鏈名稱",
    desc93: "來源地址",
    desc94: "充值金額",
    desc95: "到賬金額",
    desc96: "接收地址",
    desc97: "接收時間",
    desc98: "交易哈希",
    desc99: "外部轉賬-SAI",
    desc100: "投資賬戶",
    desc101: "獎金賬戶",
    desc102: "我的收益",
    desc103: "充值",
    desc104: "總資產",
    desc105: "資產列表",
    desc106: "總額",
    desc107: "可用",
    desc108: "凍結",
    desc109: "總資產折合",
    desc110: "投資賬戶總資產",
    desc111: "獎金賬戶總資產",
    desc112: "資產分佈",
    desc113: "賬戶中各類資產折合成USDT的金額佔比",
    desc114: "第三方轉入",
    desc115: "轉入幣種",
    desc116: "轉入平台",
    desc117: "僅支援透過第三方平台 hyper community向目前平台轉入{n1}資產",
    desc118: "帳號",
    desc119: "複製UID",
    desc120: "請透過第三方平台 hyper community向目前帳號轉入{n1}資產",
    desc121: "1、僅支援透過第三方平台 hypercomunity向目前平台轉入{n1}資產；",
    desc122: "2、轉入的{n1}只可用於購買資金包。 ",
    desc123:
      "4、發送完成後，系統會自動在此交易獲得相應數量，確認後將該筆資產充值到您在本站的帳戶，請耐心等待。 ",
    desc124: "轉入記錄",
    desc125: "單號",
    desc126: "時間",
    desc127: "類型",
    desc128: "來源用戶UID",
    desc129: "來源平台",
    desc130: "來源使用者信箱",
    desc131: "幣種",
    desc132: "數量",
    desc133: "狀態",
    desc134: "第三方轉入-HYPERCOMUNITY",
    desc135: "轉入詳情",
    desc136: "闪兑",
    desc137: "3.一個Daoversal 帳號 只能從HV/HN 的帳號各轉一次待釋放。",
    desc138: "DP積分只能用於充值所訂購的TFA套餐",
    desc139: "即將開啟",
    desc140: '切換資金密碼驗證',
    desc141: '切換郵箱驗證',
    desc142: '資金密碼',
    desc143: '請輸入資金密碼',
    desc144: '為了你的資產安全，提幣時請先設定Google驗證碼後再進行提幣操作',
    desc145:'充值返利{n1}%',
    desc146:'充值{n1}返{n2}%',
    desc147:'凡是活動期間充值{n1}的用戶將會獲得立返{n2}% {n4}獎勵到用戶的{n3}。',
    desc148:'限時活動',
    desc149:'活動時間：',
    desc150:'充值返利活動規則說明',
    desc151:'1、充值返利活動時間：',
    desc152:'2、規則：僅限於充值{n1}',
    desc153:'3、 獎勵回饋比例：充值金額的{n1}%',
    desc154:'4、 獎勵幣種：{n1}',
    desc155:'5、 獎勵帳戶：',
    desc156:'6、 獎勵發放時間：充值成功後馬上發放，可在充值記錄和流水中查看發放明細。 ',
    desc157:'規則詳情',
    desc159:'充值返利：',
    desc160:'交易哈希',
    desc161: '設定DAOVERSA用戶名',
    desc162: '未設置',
    desc163: '已設置',
    desc164: '為了您的帳號安全，設定登入密碼前請先設定信箱',
    desc165: '設定用戶名成功',
    desc166: '此用戶名是您在DAOVERSAL平台上的獨特標識，可用於帳號登入、查詢等多項功能',
    desc167: '用戶名',
    desc168: '最长16个字符，支持字母、数字和特殊符号',
    desc169: '注意：用戶名將終身伴隨，一旦設置不可更改，請謹慎選擇並記住您的用戶名',
    desc170: '設定郵箱',
    desc171: '設定登錄密碼',
    desc172: '密碼',
    desc173: '請輸入驗證碼',
    desc174: '郵箱設定成功',
    desc175: '請輸入登錄密碼',
    desc176: '請再次輸入登錄密碼',
    desc177: '設定登錄密碼成功',
  },
  assetbag: {
    desc1: "資金包",
    desc2: "資料總覽",
    desc3: "資金包類型",
    desc4: "我的資金包",
    desc5: "總投資({n})",
    desc6: "資金包(個)",
    desc7: "額度({n})",
    desc8: "收益({n})",
    desc9: "已分享(個)",
    desc10: "已回填({n})",
    desc11: "已損失({n})",
    desc12: "收益記錄",
    desc13: "回填記錄",
    desc14: "查看",
    desc15: "額度",
    desc16: "價格",
    desc17: "動態倍數",
    desc18: "{n1}和{n2}",
    desc19: "購買",
    desc20: "名額分享",
    desc21: "限購(份/人)",
    desc22: "庫存(份)",
    desc23: "不限",
    desc24: "已回填",
    desc25: "回填率",
    desc26: "釋放速率",
    desc27: "回填",
    desc28: "提取",
    desc29: "詳情",
    desc30: "已領取",
    desc31: "待領取",
    desc32: "可分享(個)",
    desc33: "初級",
    desc34: "中級",
    desc35: "高級",
    desc36: "總數(份)",
    desc37: "收起",
    desc38: "展開",
    desc39: "購買資金包",
    desc40: "支付帳戶",
    desc41: "帳戶餘額",
    desc42: "取消",
    desc43: "立即支付",
    desc44: "註：每個帳戶只可購買一個{n1}。",
    desc45: "回填資金包",
    desc46: "剩餘回填",
    desc47: "目前每日可領取",
    desc48: "註: 回填率 100%之前是 0.2，之後是 0.6%",
    desc49: "回填金額",
    desc50: "帳戶餘額不足",
    desc51: "註：",
    desc52: "1、回填率達到 100%以後才可購買下一個資金包。",
    desc53: "2、無回填中的資金包不會發放DP點數獎勵。",
    desc54: "回填 {n1} {n2}",
    desc55: "回填率可達： {n} ",
    desc56: "預計每日可領取",
    desc57: "資金包",
    desc58: "狀態",
    desc59: "回填完成",
    desc60: "回填中",
    desc61: "每日可領取",
    desc62: "已發放收益",
    desc63: "剩餘待釋放",
    desc64: "購買時間",
    desc65: "提取確認",
    desc66: "收益類型",
    desc67: "數量",
    desc68: "幣種",
    desc69: "確定",
    desc70: "恭喜您！已成功領取收益！",
    desc71: "我知道了",
    desc72: "查看資產",
    desc73: "收益領取詳情",
    desc74: "總收益",
    desc75: "已領取",
    desc76: "待領取",
    desc77: "剩餘釋放",
    desc78: "日期",
    desc79: "收益記錄",
    desc80: "購買成功！",
    desc81: "你已經買了1個{n}資金包",
    desc82: "出售中",
    desc83: "已售罄",
    desc84: "回填中",
    desc85: "已完成",
    desc86: "支付金額",
    desc87: "回填率未達{n}無法提取",
    desc88: "帳戶餘額({n})",
    desc89: "已損失({n})",
    desc90: "回填成功！",
    desc91: "你已成功回填了{n}",
    desc92: "回填記錄詳情",
    desc93: "回填數量",
    desc94: "資金包等級",
    desc95: "資金包額度",
    desc96: "類型",
    desc97: " 1、只有回填率達到100%以後才可以回填下一個資金包。",
    desc98: "2、點數獎勵只可用於資金包的加速釋放。",
    desc99: "3、有回填中的資金包才會發放DP點數獎勵。",
    desc100:
      "4、超過 120 小時未領取或超過 24 小時未使用（回填資金包）的積分獎勵都會進入到已損失收益中。",
    desc101: "回填資金包",
    desc102: "已成功",
    desc103: "已購買的資金包總額",
    desc104: "第一個值指的是已釋放的{n}，第二個值指的是剩餘可釋放的{n}總額。",
    desc105: "已領取的資金包釋放的{n}總額",
    desc106: "已回填的{n}積分總額",
    desc107: "可釋放的{n}總額",
    desc108: "購買資金包只能使用{n}支付，{n}只可透過hyper community進行轉入",
    desc109: "動態倍數跟代數獎積分總量有關，代數獎積分=動態倍數*代數獎數量",
    desc110: "回填率越高，釋放速率越高",
    desc111: "每個帳戶可購買資金包的最大數量",
    desc112: "平台每日可出售資金包的最大數量",
    desc113: "目前資金包已回填的{n}積分總額",
    desc114: "已回填{n}積分總額與目前資金包購買金額之比",
    desc115: "目前資金包每日釋放速率,回填率越高，釋放速率越高",
    desc116: "目前資金包已提取的{n}收益總額",
    desc117: "目前資金包可提取的{n}收益總額",
    desc118: "超過24小時未回填，{n}將會進入已損失收益中",
    desc119: "立即回填",
    desc120: "領取時間",
    desc121: "資金包收益",
    desc122:
      "超過 120 小時未領取或超過 24 小時未使用（回填資金包）的積分獎勵都會進入到已損失收益中。",
    desc123: "收益提取",
    desc124: "暫無可提取收益",
    desc125: "暫無可回填資金包，請先購買",
    desc126: "購買資金包數量已達最大限額",
    desc127: "資金包詳情",
    desc128: "回填及提現進度",
    desc129: "回填進度",
    desc130: "釋放進度",
    desc131: "已釋放",
    desc132: "可提取",
    desc133: "剩餘釋放",
    desc134: "去使用",
    desc135: "{n}餘額不足",
    desc136: "積分獎勵",
    desc137: "轉入",
    desc138: "完成回填{n} 即可提現",
    desc139: "激活提现",
    desc140: "1、當回填率達到{n} 才可發起提現",
    desc141: "2、回填率達到{n1}前，可提現金額將依=總累計已回填金額*{n2}釋放",
    desc142: "3、回填率達到{n1}後，可提現金額將依=總回填金額{n3}*{n2}釋放",
    desc143: "提現進度",
    desc145: "已提現",
    desc146: "待提現",
    desc147: "剩餘可提現",
    desc148:
      "積分獎勵：土地收益、土地稅、星球推薦獎、代數獎和資金包本身會產生積分獎勵，積分獎勵只用於回填資金包。有回填中的資金包才會發放dp積分獎勵，積分獎勵只 可用於資金包的加速釋放",
    desc149: "前往使用",
    desc150: `你的帳戶有<span>{n}</span>的積分獎勵還未使用，趕快去回填資金包，超過<span>24 H </span>未使用, 將會進入到已損失收益 中。 `,
    desc151: "静态速率",
    desc152: "{n1}%和{n2}%為資金包釋放速率，當資金包達到100%回填速率改為{n2}%",
    desc153: "回填率達到 100%以後才可購買下一個資金包",
    desc154:
      "有關更多信息，請閱讀我們的<a href='https://daoversal.zendesk.com/hc/en-us/articles/7994766038159-TFA-Package-Subscription-Tutorial' target=_blank>TFA 教程文章</a>。",
    desc155: "請前往獎金帳戶查看收益",
    desc156: "已回填",
    desc157: "【{n1}】 =资金包释放速率，当资金包达到100%回填后 速率改为{n2}",
    desc158: "释放率",
    desc159: "等级",
    desc160: "释放倍率",
  },
  withdraw: {
    desc: "可用",
    desc1: "最小提幣數量{n1}{n2}",
    desc2: "到賬幣種",
    desc3: "選擇提現幣種後，系統將把{n1}轉換成為對應幣種到提現地址",
    desc4: "選擇網絡",
    desc5: "提現地址",
    desc6: "輸入或粘貼提現地址",
    desc7: "提現手續費",
    desc8: "預期到賬",
    desc9: "餘額不足",
    desc10: "溫馨提示:",
    desc11: "1、提現地址請輸入選擇的對應的網絡和幣種地址；",
    desc12:
      '2、申請提現後,系統會自動在此交易獲得相應數量,確認後將該筆虛擬幣充值到你所填的地址內,提現需經過多重節點的確認，相應數量的確認需要大約<span style="color:#c9fa5b;">0.5到1個</span>小時間,請耐心等待，確認數為2方可入賬；',
    desc13: "3、同一個地址可多次提現,不影響到賬；",
    desc14:
      '4、最小提現數額需等值 <span style="color:#c9fa5b;">{n1} {n2}</span>, 小於將無法到賬;',
    desc15:
      '5、請勿向非<span style="color:#c9fa5b;">{n1}-{n2}</span>地址提現資產,否則資產無法找回。',
    desc15other: "",
    desc16: "安全驗證",
    desc17: "郵箱",
    desc18: "郵箱驗證碼",
    desc19: "輸入6位郵箱驗證碼",
    desc20: "發送",
    desc21: "谷歌驗證碼",
    desc22: "輸入6位谷歌驗證碼",
    desc23: "確定",
    desc24: "待審核",
    desc25: "審核不通過",
    desc26: "提幣失敗",
    desc27: "區塊處理中",
    desc28: "回調失敗",
    desc29: "提幣成功",
    desc30: "已退款",
    desc31: "提現記錄",
    desc32: "提現詳情",
    desc33: "交易ID",
    desc34: "提現數量",
    desc35: "主網",
    desc36: "手續費",
    desc37: "狀態",
    desc38: "交易hash",
    desc39: "待發放",
    desc40: "已發放",
    desc41: "發放失敗",
  },
  myincome: {
    desc: "待領取收益",
    desc1: "上次發放",
    desc2: "土地收益",
    desc3: "土地稅收",
    desc4: "代數獎勵",
    desc5: "一鍵領取",
    desc6: "數據總覽",
    desc7: "投資總額",
    desc8: "持有土地(塊)",
    desc9: "擁有星球(個)",
    desc10: "已損失收益",
    desc11: "收益額度",
    desc12: "已領取收益",
    desc13: "待領取收益",
    desc14: "收益類型",
    desc15: "星球推薦獎",
    desc16: "幣種",
    desc17: "收益時間",
    desc18: "領取時間",
    desc19: "數量",
    desc20: "狀態",
    desc21: "發放賬戶",
    desc22: "操作",
    desc23: "已領取的土地收益之和",
    desc24: "已領取的土地收益之和",
    desc25: "已領取的代數獎之和",
    desc26: "已領取的土地稅收之和",
    desc27:
      "超過120h未領取將不會發放新的收益，新產生的收益都會進入已損失收益中，直到領取後才重新發放",
    desc28:
      "非石器時代土地發放的土地收益和代數獎最多為投資額的3倍，收益額度中第一個值為已發放收益（包含已損失）,第二個值為土地投資額的3倍",
    desc29: "土地收益(非石器時代) ",
    desc30: "土地稅收",
    desc31: "星球推薦獎",
    desc32: "代數獎勵",
    desc33: "已損失收益",
    desc34: "土地收益(石器時代)",
    desc35: "待領取",
    desc36: "已領取",
    desc37: "已損失",
    desc38: "投資賬戶",
    desc39: "獎金賬戶",
    desc40: "超過",
    desc41:
      "超過<span style='color: #C9FA5B;'>120h</span>未領取將不會發放新的收益，新產生的收益都會進入已損失收益中，直到領取後才重新發放。",
    desc42: "我知道了",
    desc43: "查看資產",
    desc44: "發放時間",
    desc45: "損失原因",
    desc46: "超時未領取",
    desc47: "土地星球名稱",
    desc48: "土地星球坐標",
    desc49: "土地等級",
    desc50: "購買土地金額",
    desc51: "土地稅率",
    desc52: "狀態",
    desc53: "代數",
    desc54: "用戶總數",
    desc55: "土地收益總額",
    desc56: "第{n1}代",
    desc57: "收益詳情",
    desc58: "發放時間：",
    desc59: "收益總額：",
    desc60: "我的等級：",
    desc61: "等級權益：",
    desc62: "投資總額：",
    desc63: "恭喜你！已成功领取收益！",
    desc64: "土地收益積分",
    desc65: "土地稅積分",
    desc66: "推薦星球獎勵點數",
    desc67: "代數獎勵積分",
    desc68: "資金包靜態獎勵點數",
    desc69: "加速釋放活動獎勵",
    desc70: "業務類型",
    desc71: "V1",
    desc72: "V2",
  },
  record: {
    desc: "明細記錄",
    desc1: "開始時間",
    desc2: "至",
    desc3: "結束時間",
    desc4: "類型",
    desc5: "時間",
    desc6: "幣種",
    desc7: "創建日期",
    desc8: "類型",
    desc9: "地址",
    desc10: "數量",
    desc11: "訂單號",
    desc12: "狀態",
    desc13: "暫無相關數據",
    desc14: "全部",
    desc15: "詳情",
    desc16: "單號",
    desc17: "充值地址",
    desc18: "幣種",
    desc19: "金額",
    desc20: "主網",
    desc21: "成功",
    desc22: "數額",
    desc23: "交易時間",
  },
  swap: {
    desc: "兌換",
    desc1: "兌換記錄",
    desc2: "交易密碼",
    desc3: "請輸入交易密碼",
    desc4: "手續費",
    desc5: "確認",
    desc6: "選擇幣種",
    desc7: "兌換成功",
    desc8: "請輸入兌換數量",
    desc9: "實際到賬",
    desc10: "配額",
  },
  invite: {
    desc1: "邀請好友加入 Daoversal，不僅可以與您的朋友分享數字星球的樂趣",
    desc2: "還能通過高額費用的獎勵獲得更多回報。邀請越多，獎勵越豐厚！ ",
    desc3: "立即邀請",
    desc4: "您的等級：{n1}(最高持有LV{n2}星球)",
    desc5: "下一個等級的要求",
    desc6: "當前進度條",
    desc7: "1代有效用戶",
    desc8: "2+3代有效用戶",
    desc9: "最高土地等級",
    desc10: "您的合作夥伴",
    desc11: "查看列表",
    desc12: "傘下人數",
    desc13: "團隊星球",
    desc14: "團隊土地",
    desc15: "團隊業績",
    desc16: "星球情況",
    desc17: "持有星球",
    desc18: "累計投資",
    desc19: "待開發",
    desc20: "廢棄土地",
    desc21: "開發完成",
    desc22: "正在開發",
    desc23: "Daoversal Web3社交宇宙",
    desc24: "邀請你探索Daoversal Web3社交宇宙，共享財富盛宴：",
    desc25: "進入Daoversal官網",
    dec26: "綁定邀請碼",
    desc27: "保存海報",
    desc28: "複製邀請鏈接",
    desc29: "合作夥伴",
    desc30: "是",
    desc31: "否",
    desc32: "上次登錄時間",
    desc33: "是否推薦好友",
    desc34: "是否有星球",
    desc35: "是否有土地",
    desc36: "總業績",
    desc37: "由低到高",
    desc38: "由高到低",
    desc39: "直推人數",
    desc40: "星球數量",
    desc41: "土地數量",
    desc42: "總業績",
    desc43: "查看下級",
    desc44: '彩池活動',
    desc45: '常規邀請',
    desc46: '活動規則',
    desc47: '邀請排行榜',
    desc48: '我的邀請',
    desc49: '我的獎勵',
    desc50: '邀請3個好友質押土地 200 DU',
    desc51: '瓜分10W美元的獎勵',
    desc52: '活動時間：2023-11-01 00:00:00 2023-11-10 00:00:00',
    desc53: '參與瓜分獎池人數',
    desc54: '獎金池總額',
    desc55: '活動獎勵',
    desc56: '1、活動期間最高拉新人數的前五名的獎勵，可瓜分佔總獎金池10w 的 10%，比例如下：',
    desc57: '第一名',
    desc58: '第二名',
    desc59: '第三名',
    desc60: '第四名',
    desc61: '第五名',
    desc62: '2、剩餘的達標用戶，均分剩餘的90%。',
    desc63: '3、額外獎勵：在活動進行的第七天後（即倒數第三天，需公佈在活動結束前，最後5位完成任務達標的用戶，可額外獲得500 DU 的獎勵）額外獎勵不屬於在彩池內的 DU。 ',
    desc64: '{n1}人',
    // 此外，活動最後<span style="color:#C9FA5B">5位</span>達標的用戶可獲得<span style="color:#C9FA5B">500 DU</span>的額外獎勵。
    desc65: '1、活動說明：在活動期間，用戶必須新增<span style="color:#C9FA5B">3位</span>直推用戶或以上且直推用戶已完成質押<span style=" color:#C9FA5B">200 DU</span>或以上金額的土地，即可參與獎金池瓜分彩池獎勵。  ',
    desc651: '2、額外獎勵：在活動進行的第七天後（即倒數第三天，需公佈在活動結束前，最後5位完成任務達標的用戶，可額外獲得500 DU 的獎勵）額外獎勵不屬於在彩池內的 DU。 ',
    desc66: '3、參與用戶：全局用戶',
    desc67: '4、新增直推邀請用戶必須並完成質押<span style="color:#C9FA5B">200 DU </span>或以上金額的土地即為一個有效用戶。 ',
    desc68: '5、彩池獎勵將在活動結束後的<span style="color:#C9FA5B">120小時</span>發放到投資帳戶。 ',
    desc69: '6、凡是進入彩池的用戶將能均分價值 <span style="color:#C9FA5B">10萬DU</span> 的獎勵。 ',
    desc70: '7、新用戶必須是活動期間新註冊的用戶。',
    desc71: '8、前5位得獎用戶如果有相同人數的情況， 拉新用戶的總質押金額多的排名靠前，如果質押金額相同就按照達標時間早的排名靠前。',
    desc72: '邀請排行',
    desc73: '排名',
    desc74: '用戶ID',
    desc75: '邀請用戶數',
    desc76: '*活動結束後排名前 5 名可瓜分獎金池總額的 10%。 ',
    desc77: '*更新時間: 2023-10-27 15:30:23 (UTC+4)。 ',
    desc78: '註冊時間',
    desc79: '*活動結束前 3 天最後完成任務的 5 名用戶每人可獲得 500DU的額外獎勵（不在獎金池內）。 ',
    desc80: '*僅展示活動期間邀請註冊並完成至少 200DU質押的用戶',
    desc81: '時間',
    desc82: '獎勵類型',
    desc83: '幣種',
    desc84: '數量',
    desc85: '狀態',
    desc86: '額外獎勵',
    desc87: '序號',
    desc89: '解鎖獎勵',
    desc90: '邀請好友',
    desc91: '参与奖池瓜分人数每日 03:00-06:00（GMT+4）更新一次。',
    desc92: '團隊績效=個人績效+傘下業績',
    desc93:'直推个人星球',
    desc94:'直推个人土地',
    desc95:'团队人数',
    desc96:'',
    desc97:'个人业绩',
    desc98:'个人净增业绩',
    desc99:'团队业绩',
    desc100:'团队净增业绩',
    desc101:'级别',
    desc102:'团队奖励',
    desc103:'立即绑定',
    desc104:'绑定邀请关系',
    desc105:'请输入邀请码',
    desc106:'绑定',
    desc107:'取消',
  },
  page: {
    desc: "邀請碼",
    desc1: "輸入邀請碼",
    desc2: "請輸入您的邀請碼",
    desc3: "確定",
  },
  point: {
    desc1: "全網招募{n1}個星球節點",
    desc2: "說明",
    desc3: "您已購買",
  },
  footer: {
    desc1: "产品",
    desc2: "DID",
    desc3: "我的化身",
    desc4: "我的空间",
    desc5: "我的明星",
    desc6: "市场",
    desc7: "支持",
    desc8: "常見問題",
    desc9: "Docs",
    desc10: "联系",
    desc11: "开发人员",
    desc12: "Github",
    desc13: "Bug",
    desc14: "提交",
    desc15: "审计",
    desc16: "其他",
    desc17: "Daoversal",
    desc18: "令牌",
    desc19: "隐私政策",
    desc20: "使用条款",
    desc21: "SOCIALS",
    desc22: "星球",
    desc23: "资产",
    desc24: "邀请好友",
    desc25: "我的",
  },
  new: {
    desc1: "星球市場",
    desc2: "土地推薦",
    desc3: "星球管理",
    desc4: "我的土地",
    desc5: "行情兌換",
    desc6: "資產總覽",
    desc7: "投資賬戶",
    desc8: "獎金賬戶",
    desc9: "交易賬戶",
    desc10: "投資資產總覽：",
    desc11: "折合",
    desc12: "劃轉",
    desc13: "收益類型",
    desc14: "數量",
    desc15: "發放時間",
    desc16: "狀態",
    desc17: "註：",
    desc18: "1、只有土地稅、星球推薦獎、代數獎和資金包本身會產生積分獎勵。 ",
    desc19: "2、積分獎勵只可用於資金包的加速釋放。 ",
    desc20: "3.有回填中的資金包才會發放dp積分獎勵。 ",
  },
  node: {
    desc1:
      "立即投身節點市場，成為星球持有人，享受優先購買權和豐厚的土地稅收。 ",
    desc2: "利用您的星球土地，穩固財富增長，開啟財富王國的大門！ ",
    desc3: "節點星球類型",
    desc4: "價格",
    desc5: "稅收",
    desc6: "總數",
    desc7: "{n1}個星球",
    desc8: "坐標",
    desc9: "等級",
    desc10: "狀態",
    desc11: "立即命名",
    desc12: "篩選欄",
    desc13: "全部",
    desc14: "出售中",
    desc15: "已出售",
    desc16: "名稱",
    desc17: "請輸入星球坐標",
    desc18: "您正在購買[{n1}]的星球",
    desc19: "星球坐標",
    desc20: "星球類型",
    desc21: "星球價格",
    desc22: "土地總數",
    desc23: "隨機選擇",
    desc24: "星球購買",
    desc25: "請為這顆星球命名",
    desc26: "支付方式",
    desc27: "投資賬戶",
    desc28: "獎金賬戶",
    desc29: "支付幣種",
    desc30: "{n1}支付",
    desc31:
      "購買這顆星球，你將擁有這個星球的銷售權和命名權所有人可以知道這一顆星球是你命名的，並且每當你自己的星球被購買一塊土地，你都會獲得土地稅。 ",
    desc32: "確認支付",
    desc33: "暫未開放命名",
    desc34: "已命名",
    desc35: "可命名",
    desc36: "從低到高",
    desc37: "從高到低",
    desc38: "土地投資區間",
    desc39: "土地稅",
    desc40: "星球命名費",
    desc41: "支付賬戶",
    desc42: "立即充值",
    desc43:
      '購買即表示您已閱讀並同意產品<span style="color:#ffffff;">《銷售協議》</span>',
    desc44: "支付{n1}",
    desc45: "請先設置交易密碼",
    desc46:
      "為了您的資金安全，在平台內交易必須驗證交易密碼，您尚未設置交易密碼，請先設置再購買。 ",
    desc47: "下次再說",
    desc48: "立即設置",
    desc49: "賬戶未登錄",
    desc50: "您尚未登錄賬號，請先登錄，登錄成功即可購買。 ",
    desc51: "取消",
    desc52: "立即登錄",
    desc53: "為了您的資金安全，請輸入6位數交易密碼",
    desc54: "恭喜您成功獲得了星球命名權",
    desc55: "現在，你可以前往星球管理頁面，探索和管理您的獨特星球。 ",
    desc56: "繼續探索",
    desc57: "立即編輯星球",
    desc58: "支付命名費",
    desc59: "歡迎來到星球編輯器",
    desc60: "命名費",
    desc61: "管理我的星球",
    desc62: "星球持有人",
    desc63: "可用土地數量",
    desc64: "購買土地(暫未開放)",
    desc65: "{n1}的星球",
    desc66: "您即將擁有【{n1}】星球的命名權",
    desc67: "可命名星球：",
    desc68: "已命名星球：",
    desc69: "不可命名星球：",
    desc70: "星系",
    desc71: "星系名稱",
    desc72: "我的星球",
    desc73: "認購土地數量*每日靜態收益*{n1}%",
    desc74: "土地認購金額*{n1}%",
    desc75: "提示：HV和SAI平台用戶用原平台交易密碼即可完成驗證",
    desc76: "邀請碼（選填）",
    desc77:
      "必須命名過一顆星球(含石器時代星球)<br />才能推薦別人且自己不能作為自己的推薦人。<br />輸入邀請碼後，每當有其他用戶購買該星球的土地，<br />會給星球命名邀請人0.4%的土地稅獎勵。",
    desc78: "輸入邀請碼",
    desc79:
      "當前可用餘額僅展示鏈上充值<br />可用於購買星球的部分，SAI<br />節點套餐遷移中兌換的DU不可<br />用於購買二期以後的星球且在<br />此處不展示",
    desc80: "可售數量",
  },
  land: {
    desc1: "探索這些獨特命名的星球，開始您的星際征程吧！ ",
    desc2: "選擇一個與您心靈共鳴的星球名稱，讓您的星球在宇宙中獨樹一幟！ ",
    desc3: "搜索星球坐標/持有人/星球名稱",
    desc4: "持有人：",
    desc5: "土地情況",
    desc6: "星球級別",
    desc7: "星球殖民",
    desc8: "前往購買",
  },
  manage: {
    desc1:
      "用您的創意和想像力賦予星球新的名稱，選擇合適的圖片風格，使其成為宇宙中最引人注目的明星之一！ ",
    desc2: "持有星球",
    desc3: "土地佔用情況",
    desc4: "累計稅收收入",
    desc5: "今日增資",
    desc6: "累計投資",
    desc7: "開發完成",
    desc8: "土地個數：{n1}個",
    desc9: "價值：{n1} DAOT",
    desc10: "廢棄土地",
    desc11: "正在開發",
    desc12: "您的星球列表",
    desc13: "您的星球命名",
    desc14:
      "用您的創意和想像力賦予星球新的名稱，選擇合適的圖片風格，使其成為宇宙中最引人注目的明星之一！ ",
    desc15: "編輯星球",
    desc16: "{n1}個",
    desc17: "投資數量",
    desc18: "土地稅收",
    desc19: "歡迎來到星球編輯器",
    desc20:
      "請記住，在完成編輯後的{n1}天內，您將無法再次修改星球信息。因此，請仔細考慮每個設置，並確保您對其感到滿意。 ",
    desc21: "星球名稱",
    desc22: "給您的星球起一個獨特而有意義的名字吧！這將成為您星球的獨特標識.",
    desc23: "星球宣言",
    desc24: "描述您星球的歷史、居民、資源和獨特之處，或召集您的居民到某個據點",
    desc25: "要求",
    desc26: "上傳附件需要尺寸 380*380",
    desc27: "文件不得高於 4MB",
    desc28: "請嚴格按照要求來進行上傳，以下提供示例附件：",
    desc29: "點擊下載",
    desc30: "圖片上傳",
    desc31: "取消保存",
    desc32:
      "完成編輯後，我們將為您的星球進行專業級渲染。每一個細節都將被精心呈現，以確保您的星球在宇宙中特別顯眼。 ",
    desc33: "編輯完全",
    desc34: "示例圖片",
    desc35: "放棄編輯",
    desc36: "預覽編輯",
    desc37: "請輸入星球名稱",
    desc38: "請輸入星球宣言",
    desc39: "星球渲染中...",
    desc40: "上一步",
    desc41: "已渲染完成",
    desc42: "星球命名",
    desc43: "確認提交",
    desc44: "星球編輯成功",
    desc45: "待開發土地",
    desc46: "土地佔用情況",
    desc47: "待開發土地",
    desc48: "今日稅收",
    desc49: "你的星球，你的世界，讓你的星球語種不同",
    desc50: "分享人數",
    desc51: "分享命名星球",
    desc52: "{n1} 推薦命名",
    desc53: "累計推薦命名星球的人數",
    desc54: "累計推薦命名星球的數量",
    desc55: "需要上傳星球圖片",
    desc56:'星球轉移',
    desc57:'贈',
    desc58:'星球轉讓',
    desc59:'星球土地',
    desc60:'轉入用戶UID',
    desc61:'輸入轉入用戶UID',
    desc62:'邀請碼(選填)',
    desc63:'輸入邀請碼，非必填',
    desc64:'轉讓手續費',
    desc65:'投資帳戶DAOT餘額',
    desc66:'轉讓即表示您已閱讀並同意產品<span style="color:#C9FA5B">《轉讓規則》</span>',
    desc67:'必須命名一顆星球(含石器時代星球) 才能推別人且自己不能作自己的推薦人。輸入邀請碼後，每當有其他使用者購買該星球的土地就會為星球命名邀請人0.4%的土地購買金額作為獎勵。 ',
    desc68:'轉讓規則說明',
    desc69:'1、行星轉移沒有限制。 ',
    desc70:'2、行星類型沒有限制； 所有行星都可以轉移。',
    desc71:'3、轉帳費用從寄送者的存款帳戶 (DAOT) 中扣除。',
    desc72:'4、初始星球轉讓費為{n1} DAOT，後續每次轉讓需在累計轉讓費的基礎上加收{n2}%。 ',
    desc73:'5、轉帳費用是根據星球的轉帳數量計算的，而不是帳戶的。',
    desc74:'6、如果輸入的邀請碼無效或為空，則星球推薦獎勵的分配將作廢，直到下次輸入有效邀請為止。 隨後，新的推薦獎勵將分配給新的星球推薦用戶。',
    desc75:'7、邀請碼是指新的星球推薦使用者。 星球上產生的任何新的推薦獎勵都將分配給新的推薦人。',
    desc76:'8、星球推薦人需要擁有一顆星球，包括石頭星球； 行星接收者和行星所有者不能同時相同。 如果您只擁有一顆行星，您將無法將自己指定為行星引薦人，因為行星引薦人必須擁有一顆行星。',
    desc761:'9、星球轉移立即生效。',
    desc77:'星球轉移成功',
    desc78:'轉讓手續費取決於星球轉讓次數，星球每轉讓一次星球轉讓手續費將累計增加{n1}%',
    desc79:'您的好友 <span style="color:#C9FA5B">{n1}</span> 於 {n2} 向你贈送了一顆座標為{n3}的{n4}星球，請注意查收。 ',
    desc80:'星球轉移',
    desc81:'他人贈送',
    desc82:'{n1}顆',
    desc83:'星球轉讓人',
    desc84:'星球擁有',
    desc85:'自己購買',
    desc86:'他人贈送',
    desc87:'轉移記錄',
    desc88:'星球轉出記錄',
    desc89:'星球轉入記錄',
    desc90:'時間',
    desc91:'類型',
    desc92:'星球名稱',
    desc93:'星球類型',
    desc94:'星球座標',
    desc95:'星球土地',
    desc96:'星球價格',
    desc97:'手續費',
    desc98:'轉入用戶',
    desc99:'星球邀請人',
    desc100:'狀態',
    desc101:'已完成',
    desc102:'星球轉出',
    desc103:'星球轉入',
    desc104:'轉出用戶',
    desc105:'類型',
    desc106:'手動轉出',
    desc107:'系統轉出',
   
  },
  quotes: {
    desc1: "行情列表",
    desc2: "幣種",
    desc3: "價格",
    desc4: "24h漲跌幅",
    desc5: "72h漲跌幅",
    desc6: "操作",
    desc7: "查看",
    desc8: "{n1} 兑换 {n2} 代币",
    desc9: "請輸入數量",
    desc10: "余额",
    desc11: "交易賬戶持有：",
    desc12: "交易記錄",
    desc13: "单号",
    desc14: "时间",
    desc15: "交易对",
    desc16: "交易类型",
    desc17: "数量",
    desc18: "状态",
    desc19: "手续费",
    desc20: "加載中...",
    desc21: "数量最小：{n1}",
    desc22: "数量最大：{n1}",
    desc23: "交易确认",
    desc24: "卖出",
    desc25: "价格",
    desc26: "数量",
    desc27: "得到",
    desc28: "请输入数量",
    desc29: "兑换成功",
    desc30: "兑换记录",
    desc31: "交易详情",
    desc32: "币种行情",
    desc33: "展开",
    desc34:
      "注：价格更新时间为10秒，最后成交价格以点击确定时获取到最新价格为准",
    desc35: "买入",
    desc36: "买入{n1}代币",
    desc37: "兑换",
    desc38: "得到",
  },
  sign: {
    desc1: "登錄",
    desc2: "註冊",
    desc3: "加入 Daoversal，開啟您的數字星球之旅。 ",
    desc4:
      "在這個充滿創造力和想像力的平台上，您將體驗到無與倫比的數字世界的樂趣和價值。 ",
    desc5: "登錄星球",
    desc6: "賬戶",
    desc7: "請輸入用戶名或郵箱",
    desc8: "忘記用戶名？ ",
    desc9: "登錄密碼",
    desc10: "請輸入密碼",
    desc11: "記住密碼",
    desc12: "忘記密碼？ ",
    desc13: "確認登錄",
    desc14: "還沒有賬號？ ",
    desc15: "立即註冊",
    desc16: "Hyper community一鍵登錄",
    desc17: "用戶名",
    desc18: "最長10個字符，支持字母、數字和特殊字符",
    desc19:
      "注意：用戶名將終身伴隨，一旦設置不可更改，請謹慎選擇並記住您的用戶名",
    desc20: "郵箱",
    desc21: "請輸入郵箱號碼",
    desc22: "確認登錄密碼",
    desc23: "請再次輸入密碼",
    desc24: "郵箱驗證碼",
    desc25: "請輸入郵箱驗證碼",
    desc26: "發送驗證碼",
    desc27: "邀請碼",
    desc28: "請輸入邀請碼",
    desc29:
      '一旦註冊成功，默認閱讀並同意<span style="cursor: pointer;">《用戶註冊協議》</span>',
    desc30: "確認註冊",
    desc31: "已有賬號？ ",
    desc32: "立即登錄",
    desc33: "請輸入用戶名",
    desc34: "兩次密碼不一致",
    desc35: "請輸入正確的郵箱格式",
    desc36: "切換語言",
    desc37: "驗證碼發送成功",
    desc38: "請選擇同意協議",
    desc39: "註冊成功",
    desc40: "登錄成功",
    desc41: "賬戶設置",
    desc42: "第一步",
    desc43: "請在手機應用商店下載“Google 身份驗證器”",
    desc44: "第二步",
    desc45: "通過谷歌身份驗證器，掃描二維碼綁定",
    desc46: "第三步",
    desc47: "綁定郵箱",
    desc48: "我的上級",
    desc49: "賬戶來源",
    desc50: "註冊時間",
    desc51: "公告中心",
    desc52: "註冊星球賬號",
    desc53: "密碼8-20位，需包含大小寫字母和數字",
    desc54: "找回登錄密碼",
    desc55: "設置密碼",
    desc56: "確認密碼",
    desc57: "修改密碼成功",
    desc58: "找回用戶名",
    desc59: "郵箱賬號",
    desc60: "用戶名已發送",
    desc61: "您為宜用戶名，系統正通過郵箱發送給你，請注意查收",
    desc62: "好的",
    desc63: "第三方登錄",
    desc64: "檢測到您是{n1}平台用戶，初次登錄請設置用戶名",
    desc65: "關聯郵箱：",
    desc66: "設置成功",
    desc67: "本平台註冊",
    desc68: "檢測到您是{n1}平台用戶，初次登錄請設置唯一用戶名和郵箱",
    desc69: "電子郵箱",
    desc70: "您的等級：",
    desc71:'請輸入一個新的信箱',
    desc72:'忘記密碼',
    desc73: "通過錢包登入",
    desc74: "通過錢包登入",
    desc75: "請輸入邀請碼",
    desc76: "請選擇錢包登入",
    desc77: "邀請碼",
    desc78: "断开WalletConnect连接",
    desc79: "請查看已連接錢包，確認簽名訊息",
  },
  notice: {
    desc1: "DAOVERSAL公告",
    desc2: "查看詳情",
  },
  market: {
    desc1: "星球狀態",
    desc2: "星球類型",
    desc3: "土地狀態",
    desc4: "土地價格",
    desc5: "土地等級",
    desc6: "輸入星球持有人或星球名字",
    desc7: "可購買",
    desc8: "已售罄",
    desc9: "未開放購買",
    desc10: "從高到低",
    desc11: "從低到高",
    desc12: "星球坐標",
    desc13: "土地情況",
    desc14: "土地級別",
    desc15: "土地價格",
    desc16: "立即購買",
    desc17: "未開放",
    desc18: "您正在購買「{n1}」星球的土地",
    desc19: "星球級別",
    desc20: "土地級別",
    desc21: "星球居民",
    desc22: "土地情況",
    desc23: "土地價格",
    desc24: "支付賬戶",
    desc25: "賬戶餘額",
    desc26: "土地投資金額",
    desc27: "投資區間{n1}~{n2}",
    desc28: "餘額不足",
    desc29: "支付後默認已閱讀並同意",
    desc30: "購置土地",
    desc31: "已售罄",
    desc32: "未開放購買",
    desc33: "請輸入土地投資金額",
    desc34: "土地投資金額最少：{n1}",
    desc35: "土地投資金額最大：{n1}",
    desc36: "再買一塊",
    desc37: "土地管理",
    desc38: "恭喜您成功購買了土地",
    desc39: "您可以前往“我的土地”管理土地和查看收益情況",
    desc40:
      "注: 混幣支付中會優先扣除hPT , 再扣除DU , hPT最多可抵扣土地投資總額的",
    desc41: "支付金額",
    desc42: "《土地協議》",
    desc43: "獎金賬戶餘額",
    desc44: "獎金賬戶餘額不足",
    desc45: "待領取收益",
    desc46: "上次發放: ",
    desc47: "土地收益({n1})",
    desc48: "土地稅收({n1})",
    desc49: "代數獎勵(RC)",
    desc50: "查看詳情",
    desc51: "一鍵領取",
    desc52: "投資總額({n1})",
    desc53: "持有土地(塊)",
    desc54: "土地收益({n1})",
    desc55: "土地收益(RC)",
    desc56: "代數獎勵(RC)",
    desc57: "土地稅收({n1})",
    desc58: "收益額度(RC)",
    desc59: "已損失收益({n1})",
    desc60: "已損失收益(RC)",
    desc61: "已領取的土地收益之和",
    desc62: "已領取的土地收益之和",
    desc63: "已領取的代數獎之和",
    desc64: "已領取的土地稅收之和",
    desc65:
      "非石器時代土地發放的土地收益和代數獎最多<br />為投資額的3倍，收益額度中第一個值為已發放<br />收益（包含已損失）,第二個值為土地投資額的3倍",
    desc66:
      "超過120h未領取將不會發放新的收益，<br />新產生的收益都會進入已損失收益中，<br />直到領取後才重新發放",
    desc67:
      "超過120h未領取將不會發放新的收益，<br /新產生的收益都會進入已損失收益中，<br />直到領取後才重新發放",
    desc68: "以下里都是您開拓出的土地，您可以輕鬆查看和管理您的所有土地",
    desc69: "投資金額",
    desc70: "購買時間",
    desc71: "由遠到近",
    desc72: "由近到遠",
    desc73: "「{n1}」星球的土地",
    desc74: "{n1}生效",
    desc75: "待生效",
    desc76: "生效中",
    desc77: "已完成",
    desc78: "已廢棄",
    desc79: "收益天數",
    desc80: "投資總額",
    desc81: "撤資",
    desc82: "追加",
    desc83: "詳情",
    desc84: "土地撤資",
    desc85: "您當前正在進行歸屬於「{n1}」的星球土地撤資",
    desc86: "撤資土地的星球坐標",
    desc87: "撤資總額",
    desc88: "撤資手續費(費率{n1}%)",
    desc89: "到賬金額",
    desc90: "1、每個人只能購買一次石器時代的土地，撤資後將無法進行購買；",
    desc91: "2、撤資將收取{n1}%的平台手續費；",
    desc92:
      "3、撤資當天土地將停止產生收益 , 撤資金額將全部返回至你的獎金賬戶。 ",
    desc93: "我再想想",
    desc94: "確定撤資",
    desc95: "注意",
    desc96: "撤資成功",
    desc97: "您正追加投資歸屬「{n1}」的星球土地",
    desc98: "追加土地的星球坐標",
    desc99: "當前土地投資總額",
    desc100: "追加金額",
    desc101: "全部",
    desc102:
      "注：每次追加投資數的時候，最低追加數量是當前土地投資數的10%以上，最高追加數量是當前星球土地的最高區間數。 ",
    desc103: "追加投資",
    desc104: "可追加金額區間 {n1} ~ {n2}",
    desc105: "請輸入追加金額",
    desc106: "追加投資成功",
    desc107: "歸屬於「{n1}」星球的土地",
    desc108: "生效時間",
    desc109: "產出收益",
    desc110: "追加投資區間",
    desc111: "撤資詳情",
    desc112: "撤資狀態",
    desc113: "撤資時間",
    desc114: "開發完成時間",
    desc115: "當前星球土地總數為{n1}塊 ,<br /> 還剩餘{n2}塊可出售 ",
    desc116:
      "錢包模式(石器時代)土地贖回投資額，<br />土地不再產生收益 ，變成已廢棄<br /廢棄土地",
    desc117:
      "投資模式（非石器時代）土地發放的<br />靜態和動態收益累加起來最多為投資額的3倍 ， <br />此時土地不再產生收益，變成開發完成的土地",
    desc118: "上級的星球土地",
    desc119: "團隊的星球土地",
    desc120: "土地",
    desc121: "請輸入用戶名",
    desc122: "篩選",
    desc123: "重置",
    desc124: "數據總覽",
    desc125: "聯繫我們",
    desc126: "我的賬戶",
    desc127: "語言",
    desc128: "恭喜您！已成功領取收益！",
    desc129:
      '超過<span style="color:#C9FA5B;">120H</span>未領取將不會發放新產出的收益，直到領取後才重新發放！',
    desc130: "我知道了",
    desc131: "查看資產",
    desc132: "展開",
    desc133: "購買土地",
    desc134:
      "你當前購買的不是你的上級或你上級所屬團隊的的星球土地，是否確認購買？",
    desc135: "一鍵領取",
    desc136: "星球推荐命名奖励(DU)",
    desc137: "代數獎勵",
    desc138: "星球推荐奖励",
    desc139: "土地稅收",
    desc140: "土地收益",
    desc141: "土地撤资",
    desc142: "星球订阅",
    desc143: "購買土地(已售罄)",
    desc144: "取消質押後該賬戶無法重新質押石器時代的土地，是否確認？",
    desc145: "執行",
    desc146: "结算收益",
    desc147: "土地追加投资",
    desc148: "代数奖励",
    desc150: "购买资金包",
    desc151: "资金包回填",
    desc152: "资金包收益",
    desc153: "扣除-超时未使用",
    desc154: "资金包资产转移",
    desc155: "土地收益积分",
    desc156: "土地税积分",
    desc157: "推荐星球奖励积分",
    desc158: "代数奖励积分",
    desc159: "资金包静态奖励积分",
    desc160: "结算收益",
    desc161: "加速釋放活動獎勵",
    desc162: '萬聖節 - 前 5 名獎勵',
    desc163: '萬聖節-額外獎勵',
    desc164: '活動獎勵',
    desc165: '萬聖節 - 瓜分獎勵',
    desc166:'充值返利',
    desc167:'盲盒奖品',
    desc168:'復投活動週獎勵',
    desc169:'復投活動月獎勵',
    desc170:'星球售罄獎勵',
    desc171:'星球轉讓手續費',
    desc172:'1. 追加投資金額必須大於您目前累積土地質押金的10%。 ',
    desc173:'2. 目前的累計總質押金金額（當前質押金金額+追加投資金額）必須在該土地類型的質押區間內。 ',
    desc174:'注意：',
    desc175:'土地加速中',
    desc176:'出售比例 {n1}%',
    desc177:'出售比例說明',
    desc178:'1、出售比例：已出售土地數量/星球土地總數量。 ',
    desc179:'2、星球土地加速釋放說明：當星球達到對應的土地售出比例， 對應得到星球主和土地主可獲得獎勵。 當星球已達到30%的土地售出比例時，對應星球購買土地的用戶可獲得加速0.2% 為期10天。 若星球升級到50%的售出比例 土地主將可獲得延期10天加速0.2%。 其他以此類推，具體如下：',
    desc180:'星球土地售出比例',
    desc181:'土地主加速釋放比例（靜態收益）',
    desc182:'加速釋放週期',
    desc183:'星球主獎勵比例',
    desc184:'星球土地加速釋放活動',
    desc185:'獎勵屬性',
    desc186:'土地速率加速',
    desc187:'加速釋放',
    desc188:'活動規則',
    desc189:'1、活動名稱：星球土地加速每日獎勵釋放。 ',
    desc190:'2、活動時間：',
    desc191:'3、活動描述：當星球達到對應的土地售出比例， 對應得到星球主和土地主可獲得獎勵。 當星球已達到30%的土地售出比例時，對應星球購買土地的用戶可獲得加速0.2% 為期10天。 若星球升級到50%的售出比例 土地主將可獲得延期10天加速0.2%。 其他以此類推，具體如下：',
    desc192:'4、活動規則：',
    desc193:'4.1 星球土地類別不限，凡是達到對應的售出比例即可獲得加速和獎勵。 ',
    desc194:'4.2 若星球土地售出比例從30% 升級到50%時， 將獲得延長加速時間（10天）， 加速速率百分比不會疊加。 ',
    desc195:'4.3 若星球土地售出比例從30% 升級到100%時，將會獲得總計 40 天的土地加速釋放和獎勵。 ',
    desc196:'5、土地加速釋放公式：使用者當日的靜態釋放速率+0.2% 。 ',
    desc197:'6、星球主獎勵比例：土地主每日靜態收益*獎勵比例。 ',
    desc198:'7、加速釋放生效時間：T+1。 ',
    desc199:'8、星球主獎勵生效時間：T+1。 ',
    desc200:'9、說明：',
    desc201:'舉例1：星球A已售出 300片土地（30%） ，凡是在星球A購買土地的用戶若當日的釋放速率是0.6% 。 此用戶隔日的靜態收益可獲得加速0.2%，即為 0.6*+0.2%=0.8% 釋放速率。 假設使用者A 的當日每日速率為 0.6% ，實際每日可得=6DU 。 加速0.2% ，用戶A 當日可獲得8DU ，為期10天。 此時，星球主可得8DU *3% = 0.24 獎勵。 ',
    desc202:'舉例2：星球B 已售出了900片土地，購買該星球的土地主將獲得為其30天的0.2%加速釋放。 因為一個獎勵區間可獲得10天的加速週期，星球B已達到 80% 的土地售出比例所以可獲得 30天的加速釋放。 ',
    desc203:'土地加速釋放',
    desc204:'星球土地加速每日獎勵釋放',
    desc205:'星球達到對應的土地售出比例， 星球主和土地主可獲得加速和獎勵',
    desc206:'加速',
    desc207:'星球主獎勵',
    desc208:'當星球達到 30%以上出售比例時，星球<br />主可以獲得 10 天以上用戶靜態收益的<br />3%-6%作為獎勵 ',
    desc209:'注意：',
    desc210:'1、石器時代土地產生的收益會作為本金自動加入到投資額中，用戶要提取石器時代的全部收益只可選擇撤資取回。 ',
    desc211:'2、當星球達到 30%以上出售比例時，購買該星球土地的用戶可以獲得 10 天以上0.2%土地收益加速。 ',
    desc212:'土地加速',
    desc213:'註：當星球達到 30%以上出售比例時，星球主可以獲得 10 天以上用戶靜態收益的3%-6%作為獎勵 。 ',
    desc214:'星球土地收益總額',
    desc215:'獎勵比率',
    desc216:'星球土地加速釋放',
    desc217:'星球達到對應的土地銷售比例',
    desc218:'土地復投活動',
    desc219:'星球主獎勵積分',
    desc220:'累積最多的土地重新評估金額並獲得豐厚的獎勵。',
    desc221:'{n1} GST 開始（持續）',
    desc222:'復原',
    desc223:'提幣退款',
    desc224:'購買數量',
    desc225:'可購數量：',
    desc226:'庫存：',
    desc227:'目前土地等級的購買數量超出限制',
    desc228:'質押總天數',
  },
  transfer: {
    desc1: "資金劃轉",
    desc2: "幣種",
    desc3: "劃轉",
    desc4: "從",
    desc5: "至",
    desc6: "數量",
    desc7: "全部",
    desc8: "可用 ",
    desc9: "確認",
    desc10: "劃轉記錄",
    desc11: "單號",
    desc12: "時間",
    desc13: "劃轉數量",
    desc14: "狀態",
    desc15: "請輸入劃轉數量",
    desc16: "到",
    desc17: "劃轉數量",
    desc18: "劃轉成功",
    desc19: "資金劃轉",
    desc20: "交易劃轉",
    desc21: "劃轉詳情",
  },
  activity: {
    desc1: '邀請3個好友質押200 DU土地可瓜分10W美元獎勵',
    desc2: '前往查看',
    desc3: '前往<br />查看'
  },
  brand: {
    desc1: '部件',
    desc2: '資源',
    desc3: '社區資源庫',
    desc4: '材質圖',
    desc5: '下載資源',
    desc6: '設計基於',
    desc7: 'Daoversal',
    desc8: '版式',
    desc9: '顏色',
    desc10: '星球',
    desc11: '土地',
    desc12: 'Logo',
    desc13: '佈局',
    desc14: '背景',
    desc15: '錯誤示範',
    desc16: '安全空間',
    desc17: '顏色',
    desc18: '版式',
    desc19: '根據專案實際情況選擇網格設計形式，以適應多種複雜場景。',
    desc20: '本頁介紹了使用品牌標誌時背景亮度的應用規則。 當背景顏色亮度在10%至60%之間時，禁止使用墨色標識。 其他情況都可以正常使用。',
    desc21: '請勿重新繪製或勾勒徽標',
    desc22: "不要在徽標上添加陰影",
    desc23: "請勿更改徽標的比例",
    desc24: '不要使用扭曲的徽標',
    desc25: '為了確保品牌標誌在所有應用場景下都能被清晰識別，需要在標誌周圍預留一定的安全、非侵入區域。 當標誌尺寸縮放時，安全區域的大小也會按比例縮放。 禁止進入區域內不得有文字、圖形、影像、線條等。',
    desc26: '·標誌安全空間（灰底）',
    desc27: '·將徽標高度設定為',
    desc28: '·距離標誌週邊0.5X等距的距離是標誌的安全空間。',
    desc29: '品牌顏色',
    desc30: '黑色的',
    desc31: '白色的',
    desc32: '黃色的',
    desc33: '灰色的',
    desc34: '橘色',
    desc35: '蒙特塞拉特',
    desc36: '行星',
    desc37: '光',
    desc38: '常規的',
    desc39: '半粗體',
    desc40: 'Logo',
    desc41: '星球',
    desc42: '土地',
    desc43: '輔助的',
    desc44: '旗幟',
    desc45: '掛繩',
    desc46: 'T-Shirt',
    desc47: '手提袋',
    desc48: '帽子',
    desc49: '筆',
    desc50: '記事本',
    desc51: '水瓶',
    desc52: '宣傳冊',
    desc53: '捲起彩旗',
    desc54: '海報',
    desc55: '通訊',
    desc56: '標誌為PNG格式.',
    desc57: '行星是PNG格式的.',
    desc58: '石器時代星球',
    desc59: '銅器時代星球',
    desc60: '鐵器時代星球',
    desc61: '蒸氣時代星球',
    desc62: '工業時代星球',
    desc63: '信息時代星球',
    desc64: '科技時代星球',
    desc65: '土地采用PNG格式。',
    desc66: '輔助是PNG格式的。',
    desc67: '該材料為 PSD 格式。',
    desc68: '滯後表面',
    desc69: '寬度：',
    desc70: '高度：',
    desc71: '掛繩長度',
    desc72: '請注意，所有測量結果都將位於PSD檔案內',
    desc73: '該材料為PSD格式。',
    desc74: '請注意，材質將位於PNG檔案中的Daoversal標誌中',
    desc75: '三折小冊子：',
    desc76: '全尺寸：寬360mm*高210mm',
    desc77: '折疊尺寸：寬90mm*高210mm',
    desc78: '文件中有6個設計',
    desc79: '捲起彩旗尺寸：',
    desc80: '海報尺寸：寬800mm*高1800mm',
    desc81: '海報為 JPEG 格式。',
    desc82: '時事通訊採用 JPEG 格式。',
    desc83: '社區資源',
    desc84:'該材料為PNG格式.'
  },
  blind:{
    desc1:'獎勵中心',
    desc2:'在獎勵中心參與活動，豐厚獎勵等你來拿',
    desc3:'獎勵有限，快來贏取！ ',
    desc4:'盲盒抽獎',
    desc5:'恭喜你獲得一次盲盒抽獎機會',
    desc6:'凡是購買銅器以上等級土地且土地狀態是生效中，每日登入可抽取<span style="color:#C9FA5B">{n1}次</span>盲盒',
    desc7:'前往抽獎',
    desc8:'活動規則',
    desc9:'1、活動名稱：巧克力盲盒抽獎活動',
    desc10:'2、活動時間：',
    desc11:'3、活動說明：凡是購買銅器以上等級土地且土地狀態為生效中，每日登入可抽取<span style="color:#C9FA5B">{n1}次</span>盲盒',
    desc12:'4、活動對象：全部使用者',
    desc13:'5、條件要求：僅限於購買過銅器以上等級的土地且當日購買土地，次日才可抽取盲盒',
    desc14:'6、土地購買要求：活動上線前購買的銅器以上的土地可參與抽取盲盒',
    desc15:'7、帳戶要求：一個帳戶1 天只能抽取{n1}次，跟購買土地數量無關，當天未抽取，抽取次數可累計到下次抽取',
    desc16:'8、 獎品：',
    desc17:'9、 獎勵帳戶：',
    desc18:'10、 獎勵發放：抽中後立即發放',
    desc19:'{n1}種類現金盲盒',
    desc20:'巧克力幸運盲盒抽獎',
    desc21:'距離結束',
    desc22:'天',
    desc23:'時',
    desc24:'分',
    desc25:'秒',
    desc26:'您可能抽中的盲盒',
    desc27:'現金',
    desc28:'立即抽獎',
    desc29:'失效倒數計時',
    desc30:'每日登入可抽取<span style="color:#C9FA5B">{n1}次</span>，當天剩餘<span style="color:#C9FA5B">{n2}次</span>抽獎 機會',
    desc31:'每天凌晨0點清空當天抽獎次數 ',
    desc32:'我的最近抽取',
    desc33:'全部抽獎紀錄',
    desc34:'最近平台抽中記錄',
    desc35:'最近已有 <span style="color:#C9FA5B">{n1}人</span>參與抽獎',
    desc36:'ID',
    desc37:'獎品',
    desc38:'時間',
    desc39:'今日已抽獎',
    desc40:'暫不可抽獎',
    desc41:'開啟盲盒',
    desc42:'恭喜你抽中了一個',
    desc43:'關閉',
    desc44:'盲盒獎品已發放至你的{n1}中',
    desc45:'可點擊',
    desc46:'前往查看',
    desc47:'盲盒抽獎紀錄',
    desc48:'累計抽獎次數',
    desc49:'累計抽取盲盒個數',
    desc50:'累計盲盒獎勵',
    desc51:'抽獎時間',
    desc52:'盲盒名稱',
    desc53:'盲盒詳情',
    desc54:'獎品發放狀態',
    desc55:'發放時間',
    desc56:'發放帳戶',
    desc57:'操作',
    desc58:'查看',
    desc59:'{n1}次',
    desc60:'{n1}個',
    desc61:'已發放',
    desc62:'查看',
    desc63:'目前帳號暫不符合抽獎條件',
    desc64:'請先 <span style="color:#C9FA5B;cursor: pointer;">購買土地</span> 或等土地生效後再來抽獎',
    desc65:'您可能抽中的盲盒',
    desc66:'我的獎品',
    desc67:'抽獎規則',
    desc68:'平台抽獎',
    desc69:'最近平台抽獎紀錄',
    desc70:'即將開始',
    desc71:'開始時間',
    desc72:'活動結束時間',
    desc73:'活動已結束',
    desc74:'活動未開始'
  },
  reward:{
    desc1:'參與獎勵中心活動',
    desc2:'豐厚獎勵等你來拿',
    desc3:'每季獎勵有限，快來贏取！ ',
    desc4:'獎勵活動',
    desc5:'完成以下任務即可獲得活動獎勵！ ',
    desc6:'進行中',
    desc7:'未開始',
    desc8:'已結束',
    desc9:'購買銅器以上等級土地抽盲盒',
    desc10:'進度（銅器以上土地）',
    desc11:'獎品',
    desc12:'結束時間',
    desc13:'抽獎次數',
    desc14:'中獎金額',
    desc15:'盲盒抽獎',
    desc16:'購買土地',
    desc17:'活動規則',
    desc18:'聖誕節活動',
    desc19:'每日{n1}次抽取盲盒機會',
    desc20:'充值DU返',
    desc21:'儲值總金額',
    desc22:'返現總額',
    desc23:'儲值DU將立返{n1}%',
    desc24:'儲值DU',
    desc25:'星球土地活動',
    desc26:'本週土地出售數量',
    desc27:'星球土地售罄數量',
    desc28:'獎勵總額',
    desc29:'土地售罄榜單',
    desc30:'土地質押周榜',
    desc31:'星球管理',
    desc32:'土地復投周榜',
    desc33:'土地復投月榜',
    desc34:'前往購買土地',
    desc35:'土地復投活動',
    desc36:'復投總金額',
    desc37:'返現總額',
    desc38:'彩池活動',
    desc39:'我的周土地質押',
    desc40:'統計時間：',
    desc41:'土地質押周榜僅統計銅器以上等級的星球（不計算石器時代星球）',
    desc42:'僅展示統計時間內星球土地有質押的用戶，土地質押周榜僅展示本週土地質押情況，不涉及獎金發放。 ',
    desc43:'星球土地質押周榜僅顯示當週新增的土地數量',
    desc44:'星球土地質押周榜',
    desc45:'排名',
    desc46:'週土地質押數量(塊)',
    desc47:'星球等級',
    desc48:'星球名稱',
    desc49:'狀態',
    desc50:'持有者帳號',
    desc51:'僅展示當週新增土地銷售數量',
    desc52:'進行中',
    desc53:'開始時間',
    desc54:'1、活動名稱：星球售罄活動',
    desc55:'2、活動時間：',
    desc56:'3、 參與用戶：全部用戶',
    desc57:'4、活動描述：在活動期間若星球已售罄的將進入榜單並且根據星球類別發放獎勵，具體如下：',
    desc58:'5、獎勵條件：星球土地售罄榜單僅統計活動時間內銅器以上級別的星球土地已售罄的星球（不計算石器時代星球）',
    desc59:'6、獎勵金額：',
    desc60:'7、獎勵幣種：',
    desc61:'8、獎勵帳戶：',
    desc62:'9、獎勵發放時間：星球土地一旦售罄就立即發放',
    desc63:'已售罄',
    desc64:'星球土地累計質押數量（塊）',
    desc65:'出售中',
    desc66:'未開放',
    desc67:'活動時間：',
    desc68:'星球土地售罄榜單僅統計活動時間內銅器以上級別的星球土地已售罄的星球（不計算石器時代星球）',
    desc69:'星球土地已售罄的將根據星球等級發放DU獎勵，具體如下：',
    desc70:'註：獎勵發放帳戶為投資帳戶，土地售罄後立即發放',
    desc71:'我的複投',
    desc72:'我的獎勵',
    desc73:'在活動期間復投（追加投資）',
    desc74:'瓜分活動期間平台復投總金額的{n1}%',
    desc75:'活動時間：',
    desc76:'獎池一：週獎池',
    desc77:'瓜分倒數計時：',
    desc78:'獎池分人數',
    desc79:'獎池總額',
    desc80:'預計每人可得',
    desc81:'瓜分時間：',
    desc82:'獎池二：月獎池',
    desc83:'週瓜分紀錄',
    desc84:'月瓜分紀錄',
    desc85:'活動獎勵',
    desc86:'在活動期間，每週復投（追加投資）的平台總金額的{n1}% 將打入複投獎池。 複投獎池的{n2}%返給前100名最高追加投資- 均分）。 {n3}%的獎勵將每週發放， {n2}%是每月發放一次。 ',
    desc87:'類型',
    desc88:'條件',
    desc89:'獎勵',
    desc90:'週獎池',
    desc91:'週復投前 100',
    desc92:'瓜分週復投總金額',
    desc93:'月獎池',
    desc94:'月復投全部用戶',
    desc95:'瓜分月復投總金額',
    desc96:'舉例：',
    desc97:'本週累計共有100,000 DU 追加投資金額，10%會打入複投獎池 10,000 DU',
    desc98:'30%= 3,000 DU 將均分給當週前 100 位使用者',
    desc99:'70%= 7,000DU 將累計到月獎池 ',
    desc100:'直到月底將均分給這個月參與復投的用戶，假設月末時獎池累計有12,000 DU，本月參與復投的用戶有1,000人，則均分12,000 DU 的獎勵 ，即每人分： 12000/1000=12 DU',
    desc101:'{n1} 人',
    desc102:'土地復投周榜',
    desc103:'1、統計時間：',
    desc104:'2、僅展示統計時間內復投（追加投資）排名前<span style="color:#C9FA5B;">100</span>的用戶。 ',
    desc105:'3、復投不區分復投土地等級。 ',
    desc106:'4.週復投榜單用戶瓜分每週復投總金額的 <span style="color:#C9FA5B;">{n1}%*{n2}%</span>。 ',
    desc107:'土地復投月榜',
    desc108:'2、僅展示統計時間內復投（追加投資）全部用戶。 ',
    desc109:'4.月復投名單用戶瓜分每週復投總金額的 <span style="color:#C9FA5B;">{n1}%*{n2}%</span>。 ',
    desc110:'週土地複投金額',
    desc111:'帳號',
    desc112:'週獎池瓜分紀錄',
    desc113:'月獎池瓜分紀錄',
    desc114:'週復投名單用戶瓜分每週複投總金額的{n1}%*{n2}%。 ',
    desc115:'月復投名單用戶分割每月複投總金額的{n1}%*{n2}%。 ',
    desc116:'月土地復投金額',
    desc117:'名稱',
    desc118:'瓜分人數',
    desc119:'獎金池總額',
    desc120:'人均',
    desc121:'獎池週期',
    desc122:'瓜分時間',
    desc123:'狀態',
    desc124:'已發放',
    desc125:'未發放',
    desc126:'週獎池',
    desc127:'月獎池',
    desc128:'收益類型',
    desc129:'數量',
    desc130:'發放時間',
    desc131:'操作',
    desc132:'查看',
    desc133:'2.週獎勵每週發放 1 次。 ',
    desc134:'2.月獎勵每月發放 1 次。 ',
    desc135:'註：',
    desc136:'獎勵記錄',
    desc137:'週獎勵',
    desc138:'月獎勵',
    desc139:'復投記錄',
    desc140:'復投金額',
    desc141:'統計時間',
    desc142:'平台週復投排名',
    desc143:'週土地復投',
    desc144:'月土地復投',
    desc145:'週復投',
    desc146:'月復投',
    desc147:'1、每週統計一次當週復投的金額，當週復投金額的<span style="color:#C9FA5B;">{n1}%</span>納入彩池；其中：（週 發放）<span style="color:#C9FA5B;">{n2}% </span>是平均發放給前<span style="color:#C9FA5B;">100</span>名用戶。 （月發放）<span style="color:#C9FA5B;">{n3}% </span>預留到一個月後，集合當月每一週的<span style="color:#C9FA5B;">{n3 }% </span>總和彩池，平均發給當月所有有復投的使用者。 ',
    desc148:'2、活動時間：',
    desc149:'3、參與用戶：全域用戶',
    desc150:'4、條件：僅限於活動上線後有追加投資用戶',
    desc151:'5、週獎池',
    desc152:'週',
    desc153:'第{n1}週',
    desc154:'6、月獎池',
    desc155:'7、 獎勵幣種：',
    desc156:'8、 獎勵帳戶：',
    desc157:'9、 舉例：本週累計共有100,000 DU 追加投資金額，10%會打入複投獎池10,000 DU 30%= 3,000 DU 將均分給當週前100 名用戶70%= 7,000DU 將累計到均分 月獎池',
    desc158:'直到月底將均分給這個月參與復投的用戶，假設月末時獎池累計有12,000 DU，本月參與復投的用戶有1,000人，則均分12,000 DU 的獎勵，即每人分： 12000/1000=12 DU',
    desc159:'獎勵發放時間',
    desc160:'至',
    desc161:'星球土地售罄榜單',
    desc162:'復投活動：',
    desc163:'可瓜分複投總金額的{n1}%',
    desc164:'週土地復投排行榜',
    desc165:'月土地復投排行榜',
    desc166:'活動說明',
    desc167:'星球土地售罄活動',
    desc168:'土地已售罄可獲得{n1} DU獎勵',
    desc169:'星球售罄榜單',
    desc170:'土地質押周榜',
    desc171:'星際之旅，星球送不停',
    desc172:'進度',
    desc173:'獎品',
    desc174:'免費贈送一塊土地',
    desc175:'{n1}塊',
    desc176:'取得土地數量',
    desc177:'購買土地',
    desc178:'活動頁面',
    desc179:' 1.活動描述在活動期間內，新用戶每購買3 塊土地，將免費獲得1塊購買的最低等級的最低價格的土地。 ',
    desc180:'在活動期間內，新用戶每購買3 塊土地，將免費獲得1塊購買的最低等級的最低價格的土地。 ',
    desc181:'2、規則',
    desc182:'2.1 新用戶指的是活動之前從未購買過土地的用戶。 購買星球不算。 ',
    desc183:'購買的 3 塊土地不用限制土地等級。 ',
    desc184:'每買 3 塊送1塊， 買 6 塊送2塊，其他以此類推...。 ',
    desc185:'2.4 購買的土地必須是投資帳戶支付（包含混合支付【DU+HPT支付】），獎金帳戶支付購買的土地不算。 ',
    desc186:'3、活動時間',
    desc187:'4、活動獎勵 ',
    desc188:'免費贈送最低等級最低價格的土地。 ',
    desc189:'5、發放時間',
    desc190:'購買成功後立即發放。 可在我的土地頁面查看系統贈送的土地。 ',
    desc191:'6、說明：',
    desc192:'舉例1：用戶A是新用戶，購買了<span style="color:#C9FA5B">3 石器時代土地</span>，此時<span style="color:#C9FA5B">用戶 A</span>可以獲得一塊投資價格是<span style="color:#C9FA5B">10 DU</span> 的石器時代土地。 ',
    desc193:'舉例2：用戶A 是新用戶，購買了<span style="color:#C9FA5B">1 石器時代土地、1 塊銅器時代土地</span>和<span style="color: #C9FA5B">1 塊鐵器時代土地</span>，此時<span style="color:#C9FA5B">用戶A</span>可以獲得一塊投資價格是<span style="color:#C9FA5B"> 10 DU </span>的石器時代土地。 ',
    desc194:'舉例3：用戶A 是一個新用戶，在活動期間買了第一次購買的是<span style="color:#C9FA5B">銅器時代土地</span>，第二次購買的是 <span style="color:#C9FA5B">鐵器時代土地</span>，第三次購買的是<span style="color:#C9FA5B">銅器時代土地</span>，第四次到第 六次都是購買的是<span style="color:#C9FA5B">鐵器時代土地</span>。 此時用戶A在第3塊土地購買完成後可免費獲得1塊投資金額是 <span style="color:#C9FA5B">101 DU</span>的銅器時代土地。 在第6塊土地購買完成後可免費獲得1塊投資金額是 <span style="color:#C9FA5B">301 DU</span>的鐵器時代土地。 ',
    desc195:'土地購買贈送活動',
    desc196:'免費贈送一顆星球',
    desc197:'購買星球',
    desc198:'在活動期間內，新用戶每購買2 顆任意時代的星球，將免費獲得1顆購買的最低等級的星球。 ',
    desc199:'2.1 新用戶指的是活動之前從未購買過星球的用戶。 自己未購買，他人贈送過星球的也屬於新用戶。 ',
    desc200:'2.2 購買的 2 顆星球可以是任意時代的星球，不限制星球等級。 ',
    desc201:'2.3 贈送的星球可以轉讓他人，可以照常享有土地稅等收益。 ',
    desc202:'免費贈送購買的最低等級的星球。 ',
    desc203:'購買成功後立即發放。 可在星球管理頁面查看系統贈送的星球。 ',
    desc204:'舉例1：用戶A是一個新用戶，購買了2 顆鐵器時代星球，第一次購買的鐵器時代星球推薦人是用戶B，第二購買的鐵器時代星球推薦人也是用戶B，此時 用戶A可以免費獲得一顆鐵器時代的星球，星球推薦人是用戶B。 ',
    desc205:'舉例2：用戶A是一個新用戶，購買了2 顆鐵器時代星球，第一次購買的鐵器時代星球推薦人是用戶B，第二購買的鐵器時代星球推薦人是C，此時用戶 A可以免費獲得鐵器時代的星球，星球推薦人是用戶C。 ',
    desc206:'舉例3：用戶A是一個新用戶，購買了2 顆鐵器時代星球，第一次購買的鐵器時代星球沒有推薦人，第二購買的鐵器時代星球推薦人是用戶B，此時用戶A 可以免費獲得鐵器時代的星球，星球推薦人是用戶B。 ',
    desc207:'舉例4：用戶A是一個新用戶，購買了2 顆鐵器時代星球，2 次購買的鐵器時代星球沒有推薦人，此時用戶A可以免費獲得一顆鐵器時代的星球，沒有星球推薦人 。 ',
    desc208:'舉例5：用戶A 是個老用戶，在活動前買了1 顆銅器時代星球，又在活動期間購買1顆銅器時代星球和1顆鐵器時代星球，此時用戶A不會免費 獲得1 顆星球。 因為用戶A不屬於新用戶（活動期間從未購買過星球的用戶）。 ',
    desc209:'新用戶購買2顆任一時代的星球，可免費獲得一顆最低等級星球',
    desc210:'星球土地狂歡：買3送1！ ',
    desc211:'每購買3塊星球土地，即刻獲贈1塊土地。 ',
    desc212:'1、活動描述',
    desc213:'2.4 贈送的星球的星球推薦人取用戶活動期間購買的星球推薦人，如果有多個推薦人則取最後一個推薦人。 ',
    desc214:'購買3塊土地，獲贈1塊土地',
    desc215:'購買2顆星球，贈送1顆星球',
    desc216:'贈送數量',
    desc217:'已購買數量',
    desc218:'{n1}顆',
    desc219:'舉例6：用戶A 是一個新用戶，在活動期間第一次購買的是銅器時代星球，第二次購買的是鐵器時代星球，第三次購買的是銅器時代星球，第四 次到第六次都是買的是鐵器時代星球。 此時用戶A在第2 顆星球購買完成後可免費獲得1顆銅器時代星球。 在第4顆星球購買完成後可免費獲得1顆銅器時代星球。 在第6顆星星購買完成後可免費獲得1顆鐵器時代星球。 ',
    desc220:'2.5 贈送的星球的星球推薦人取用戶活動期間購買的星球推薦人，如購買的星球有多個推薦人則取最後一個推薦人，如果都沒有推薦人則贈送星球的沒有推薦人。 ',
    desc221:'獲得方式',
    desc222:'我購買的',
    desc223:'系統獎勵',
    desc224:'獎勵',
  },
  daot: {
    desc1: 'DAOT 認購',
    desc2: 'DAOT認購',
    desc3: '打開財富的自由之門',
    desc4: '認購結束倒數計時',
    desc5: '天',
    desc6: '時',
    desc7: '分',
    desc8: '秒',
    desc9: '代幣價格',
    desc10: 'DAOT 出售總數',
    desc11: '個人認購數量',
    desc12: '出售進度',
    desc13: '認購方式：先來先得，售完即止',
    desc14: '認購',
    desc15: '無上限',
    desc16: '已認購數量',
    desc17: '認購地址',
    desc18: '可認購 RC 數量',
    desc19: '可認購 DU 數量',
    desc20: '幣種發放',
    desc21: '2年線性釋出',
    desc22: '編輯',
    desc23: '代幣名稱',
    desc24: '發行總量',
    desc25: '代幣類型',
    desc26: '合約地址',
    desc27: '常見問題',
    desc28: '支付帳戶',
    desc29: '獎金帳戶',
    desc30: '投資帳戶',
    desc31: '支付幣種',
    desc32: '支付數量',
    desc33: '請輸入付款數量',
    desc34: 'Solana V2 錢包位址',
    desc35: '請輸入錢包位址',
    desc36: '價格',
    desc37: '預計獲得DAOT數量',
    desc38: '帳戶餘額：',
    desc39: '認購成功',
    desc40: '編輯地址',
    desc41: '認購地址',
    desc42: '請輸入你的認購地址',
    desc43: '修改成功',
    desc44: '或',
    desc45: 'DAOT 是 daoversal平台的平台币，未来将上线主流交易所。',
    desc46: '尊敬的會員:',
    desc47: '我們對您為我們社區所做的寶貴貢獻表示衷心的感謝。 您的持續支援使您獲得使用 DU/RC 訂閱 DAOT 代幣預發行的獨家機會。 ',
    desc48: '選擇“是”，您將獲得在 3 月 1 日至 3 月 31 日期間將獎勵分配給 DAOT 訂閱、土地購買和星球購買的特權。 請注意，此優惠不可逆轉，並將暫時凍結提款，直至預售分配完成。 ',
    desc49: 'DAOT 代幣計劃於 4 月 15 日上午 11 點（GST）推出。 作為搶先體驗用戶，您有權在 3 月 1 日至 3 月 31 日期間使用 DU/RC 取得代幣。 ',
    desc50: '在預發行階段，代幣價格為每個代幣 2.00 美元，總供應量為 100,000,000 個。 推出後，交易開始時，上市價格將升至每個代幣 3.00 美元。 ',
    desc51: '感謝您堅定不移的奉獻。 讓我們一起踏上通往去中心化未來的旅程。 ',
    desc52: '不訂閱 DAOT',
    desc53: '訂閱DAOT',
    desc54: '1、認購時間是多久？',
    desc56: '認購時間是從 2024-03-04 20:00:00-2024-04-03 19:59:59 （GST）。 DAOT提前售完也會認購結束。',
    desc57: '2、認購對象',
    desc58: '全部用戶都可以參與認購。 認購前需在公告彈框中點選「我參與認購DAOT」才能參與認購，且參與認購的使用者的提現功能會被鎖定30 天，點選「不參與認購DAOT」的使用者將無法參與認購且提現功能可以 正常使用。',
    desc59: '3、支援那些幣種和帳戶認購？',
    desc60: '支持投資帳戶帳戶的DU，獎金帳戶的DU和RC參與認購，認購時需選擇參與認購的幣種和帳戶。 交易帳戶的資產需要手動劃轉到獎金帳戶參與認購。',
    desc61: '4、認購成功可以贖回本金嗎？',
    desc62: '一旦認購成功，不支持贖回本金',
    desc63: '5、每個帳號有認購數量限制嗎？',
    desc64: '每個帳號無最大認購數量限制，先來先得，售完為止',
    desc65: '6、認購結束後什麼時候發放DAOT？',
    desc66: '認購結束後會發佈公告通知DAOT領取事項。',
    desc67: '7、Solana V2 錢包位址是做什麼使用的？',
    desc68: '錢包用來領取DAOT使用的。 活動未結束前可以編輯地址。',
    desc69: '*当前帳號已選擇不參與認購',
    desc70: '活動未開始',
    desc71: '活動已結束',
    desc72: '已售罄',
    desc73: 'Daoversal 生態系統很自豪地推出 DAOT，這是一種旨在顛覆 Daoversal 平台格局的星際代幣。 DAOT 透過提供治理機制來保護整個生態系統的主權，從而作為基礎基石。 它還致力於透過促進全球以物易物系統來推動沉浸式世界經濟。 DAOT 的智能合約代表了一種更具動態性、包容性和去中心化的機制，專為元宇宙生態系統的演變而設計。',
  },
  vote: {
    desc1: 'Daoversal 治理投票',
    desc2:'未开始',
    desc3:'进行中',
    desc4:'已结束',
    desc5:'提高 DAOT 代币流动性和 社区参与度',
    desc6:'活动时间：',
    desc7:'DAOT 代币的繁荣对于我们生态系统的可持续增长和成功至关重要。 为了实现这一目标，我们需要积极鼓励社区参与和参与。 在本提案中，我们概述了旨在提高 DAOT 代币流动性和激励社区参与的几项措施，特别是通过 DAOT 预售分配。',
    desc8:'投票數',
    desc9:'距离结束',
    desc10:'天',
    desc11:'时', 
    desc12:'分',
    desc13:'秒',
    desc14:'提案內容',
    desc15:'票数',
    desc16:'投票率',
    desc17:'距离开始',
    desc18:'关键点',
    desc19:'1. 简化支持 DAOT 代币的工作 ',
    desc20:'- 为了简化并集中精力支持 DAOT 代币，我们建议取消所有现有的提款请求。',
    desc21:'- 这些取消的请求中的 DU 将被重定向到 DAOT 预售分配中，并分配给各自的用户，以增强流动性并支持代币的增长。',
    desc22:'2、平台獎勵轉入預售分配：',
    desc23:' - 平台上產生的所有獎勵將自動轉入 DAOT 預售分配並分配給各自的用戶。',
    desc24:'- 目前平台上的所有 RC/DU 餘額將自動轉入 DAOT 預售分配並指派給各自的使用者。',
    desc241:' - 此措施確保所產生的獎勵有助於 DAOT 代幣的流動性和強度。',
    desc25:'3. 额外的DAOT分配：',
    desc26:'- 为了进一步加强预售分配并确保平台能够在 4 月 15 日之前支持生成性奖励，我们建议额外添加 500,000 个 DAOT 代币。',
    desc27:'- 这一额外分配将增强流动性，并提供充足的资源来支持正在进行的平台活动和奖励。',
    desc28:'投票訊息',
    desc29:'投票狀態',
    desc30:'投票開始時間',
    desc31:'投票結束時間',
    desc32:'投票形式',
    desc33:'參與人',
    desc34:'投票形式',
    desc35:'單選',
    desc36:'1块生效中的土地可以投 1 票',
    desc37:'全部用户',
    desc38:'注：1块生效中的土地可以投 1 票，有几块生效中的土地就可以投几票。投票成功后不可以更改。',
    desc39:'投下你的票',
    desc40:'投票记录',
    desc41:'投票',
    desc42:'剩余 <span style="color:#C9FA5B">{n1}</span> 次投票',
    desc43:'已参与投票',
    desc44:'投票成功',
    desc45:'名称',
    desc46:'投票日期',
    desc47:'投票内容',
    desc48:'状态',
    desc49:'Daoversal 治理投票',
    desc50:'已投票',
    desc51:'投票介绍',
    desc52:'介绍'
  },
  transferAccount:{
    desc0:'Hyper用户迁移',
    desc1:'邮箱',
    desc2:'请输入邮箱地址',
    desc3:'账号',
    desc4:'请输入账号',
    desc5:'密码',
    desc6:'请输入密码',
    desc7:'确认密码',
    desc8:'请输入确认密码',
    desc9:'邮箱验证码',
    desc10:'请输入邮箱验证码',
    desc11:'邮箱地址不能为空',
    desc12:'账号不能为空',
    desc13:'密码不能为空',
    desc14:'确认密码和设定密码不相等',
    desc15:'邮箱验证码不能为空',
    desc16:'邮箱格式不正确',
    desc17:'迁移账号',
    desc18:'我知道了',
    desc19:'我們謹通知您，HyperCommunity 上的一鍵登入功能將於 2024 年 4 月 27 日 23:59 GST 逐步取消。',
    desc20:'HyperCommunity 迁移',
    desc21:'只有Hyper用户才能进行账号迁移',
    desc22:'账户迁移成功',
    desc23:'帳戶遷移通知',
    desc24:'為了確保資產不間斷的存取和安全，使用者必須啟動到 DAOVERSAL 的帳戶遷移程序。',
    desc25:'我們非常感謝您及時採取行動，因為未能在規定的時間內遷移您的帳戶可能會導致潛在的資產損失。我們感謝您的合作與理解。'
  },
  lockrelease:{
    desc1: "代币名称",
    desc2: "发行总量",
    desc3: "代币类型",
    desc4: "代币合约",
    desc5: "锁仓详情",
    desc6: "认购总量",
    desc7: "剩余锁仓",
    desc8: "已领取",
    desc9: "待领取",
    desc10: "一键领取",
    desc11: "Daoversal 生態系統正式推出 DAOT。 這是一種無邊際代幣，旨在顛覆 Daoversal 平台的格局。 作為基石，DAOT 旨在提供維護整個生態系統主權的治理機制。 它還致力於透過推廣無邊際交易系統，來推動沉浸式世界經濟。 此外，DAOT 的智能合約代表了一種更具動態性、包容性和去中心化的機制，專為元宇宙生態系統的演變而設計。",
    desc12: "锁仓释放记录",
    desc13: "序号",
    desc14: "释放时间",
    desc15: "释放数量",
    desc16: "状态",
    desc17: "待领取",
    desc18: "已领取",
    desc19: "领取成功",
    desc20: "DAOT",
    desc21: "DAOT認購",
  },
  stake:{
    desc1: "释放倍率",
    desc2: "Paused",
    desc3: "Active",
    desc4: "Finish",
    desc5: "释放时间",
    desc6: "未释放",
    desc7: "已释放",
    desc8: "Stake Overview",
    desc9: "团队奖励",
    desc10: "推荐奖励",
    desc11: "平级奖励",
    desc12: "个人业绩",
    desc13: "团队业绩",
    desc14: "我的质押",
    desc15: "Staking Products",
    desc16: "质押加速中",
    desc17: "周期",
    desc18: "质押数量",
    desc19: "年化利率",
    desc20: "币种",
    desc21: "立即质押",
    desc22: "质押收益",
    desc23: "团队收益",
    desc24: "推荐收益",
    desc25: "平级收益",
    desc26: "总质押",
    desc27: "总收益",
    desc28: "总质押收益",
    desc29: "总团队收益",
    desc30: "总推荐收益",
    desc31: "总平级收益",
    desc32: "My Stake",
    desc33: "Rewards",
    desc34: "Unstake",
    desc35: "全部状态",
    desc36: "未赎回",
    desc37: "已赎回",
    desc38: "待领取",
    desc39: "已领取",
    desc40: "全部类型",
    desc41: "质押奖励",
    desc42: "推荐奖励",
    desc43: "平级奖励",
    desc44: "团队奖励",
    desc45: "全部产品",
    desc46: "生效日期",
    desc47: "质押时间",
    desc48: "产品名称",
    desc49: "到期时间",
    desc50: "质押数量",
    desc51: "质押金额",
    desc52: "状态",
    desc53: "操作",
    desc54: "赎回",
    desc55: "奖励日期",
    desc56: "领取时间",
    desc57: "状态",
    desc58: "数量",
    desc59: "币种",
    desc60: "类型",
    desc61: "操作",
    desc62: "详情",
    desc63: "赎回时间",
    desc64: "赎回数量",
    desc65: "赎回金额",
    desc66: "保险状态",
    desc67: "赔付金额",
    desc68: "赔付日期",
    desc69: "未赔付",
    desc70: "已赔付",
    desc71: "无需赔付",
    desc72: "奖励详情",
    desc73: "收益日期",
    desc74: "总奖励",
    desc75: "级别",
    desc76: "质押数量",
    desc77: "奖励数量",
    desc78: "来源奖励数量",
    desc79: "质押币种",
    desc80: "投资账户余额",
    desc81: "奖金账户余额",
    desc82: "质押数量",
    desc83: "剩余可质押额度",
    desc84: "质押周期",
    desc85: "保險金，須額外支付質押總金額的 {n1}",
    desc86: "确定质押",
    desc87: "恭喜您质押成功",
    desc88: "您可以前往“我的质押”管理质押订单和查看收益情況",
    desc89: "我知道了",
    desc90: "我的质押",
    desc91: "最小质押金额不能小于{n1}",
    desc92: "最大质押金额不能大于{n1}",
    desc93: "收益率",
    desc94: "支付总额",
    desc95:'Stake',
    desc96:'MyStake',
    desc97: "天",
    desc98:'订单已赎回成功',
    desc99: "請輸入质押金額",
    desc100: "奖励詳情",
    desc101: "浮动利率",
    desc102: "每天可使用250 DU/RC交换DAOT。",
    desc103:"来源用户",
  }
};
export default zh_TW;
