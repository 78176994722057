<template>
  <div style="height: 100%;">
    <!-- <ActivityTop v-if="showActivity" @handlerClose="handlerClose" /> -->
    <homeHeader :class="{ topbg: istop }" v-if="hidenTop" :isTips="isTips" />
    <router-view :key="$route.name" v-if="isRouterActive" />
    <glFooter v-if="isshowFooter" :class="{ marginTop: ismargin }" />
    <div class="rg-rout-out">
      <div v-if="$route.path !== '/daot' && $route.path !== '/user' && $route.path!== '/vote'" class="rg-rout" @click="$router.push('/daot')">
        <img src="./public/images/daot/rg-icon.png" alt="" />
        <span>{{ $t("daot.desc2") }}</span>
      </div>
    </div>
    <!-- 邀请码弹窗 -->
    <van-popup class="min-box" v-model:show="$store.state.bindInvited" :close-on-click-overlay="false">
      <div class="invited-con">
        <div class="title">{{ $t('invite.desc104') }}</div>
        <div class="invited-input" >
          <input class="" type="text" v-model="inviteCode" :placeholder="$t('invite.desc105')">
        </div>
        <div class="btnAll-box">
          <div class="notice-btn btn-cancel" @click="inviteSet(false)">{{ $t('invite.desc107') }}</div>
          <div class="notice-btn" :class="loading||inviteCode==''?'not-active':''" @click="bindInvitedCode()">{{ $t('invite.desc106') }}</div>
        </div>
      </div>
    </van-popup>
    <!-- 盲盒活动 -->
    <!-- <div class="activitydialog flex" v-if="showDialog" @click="handlerInvite">
      <img src="./public/images/bilnd/bilndimg1.png" alt="" class="img1" />
      <div class="dialog-info flex">
        <div>{{ $t("blind.desc4") }} </div> <img src="./public/images/bilnd/bilndimg2.png" alt="">
      </div>
    </div> -->
  </div>
</template>

<script>
import homeHeader from "@/componets/header.vue";
import glFooter from "./componets/glfooter.vue";
import store from "@/store";
import bus from "@/utils/bus";
export default {
  name: "App",
  components: {
    homeHeader,
    glFooter,
  },
  data() {
    return {
      isRouterActive: true,
      istop: false,
      ismargin: false,
      isshowFooter: false,
      showActivity: false,
      showDialog: true,
      hidenTop: false,
      isTips:false,
      inviteCode:'',
      loading:false,
    };
  },
  provide() {
    return {
      reload: this.reload,
    }
  },
  mounted() {
    if (localStorage.getItem("token") != null) {
      this.$post(this.URL.member.info, {}).then((res) => {
        if (res.code == 0) {
          this.$store.commit("SET_USERDATA", res.data);
        }
      });

    }
    if (this.$route.query.inviteCode) {
      store.commit("SET_INVITECODE", this.$route.query.inviteCode);
    }

    if (
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      localStorage.setItem("ismobile", 1);
    } else {
      localStorage.setItem("ismobile", 0);
    }
    this.$post(this.URL.home.h5Url, {}).then((res) => {
      if (res.code == 0) {
        this.$store.commit("SET_H5URL", res.data);
      } else {
        this.$message.error(res.message);
      }
    });
    window.addEventListener("scroll", this.change);
    this.getChainList();
    bus.on('handlerTips', (e) => {
        this.isTips = false;
      })
    bus.on("handlerShow", (e) => {
      if (e) {
        this.showActivity = true;
      } else {
        this.showActivity = false;
      }

    });
    bus.on("handlerHiden", (e) => {
      if (e) {
        this.showDialog = true;
      } else {
        this.showDialog = false;
      }

    });

  },
  watch: {
    $route(to, from) {
      if (localStorage.getItem('token') != null) {
        this.$post(this.URL.manage.message, {
          page: 1,
          pageSize: 1
        }).then(res => {
          if (res.code == 0) {
            let info = res.data.records[0];
            if (info != undefined) {
              this.isTips = true;
            } else {
              this.isTips = false;
            }

          }
        })
      }
      this.$store.commit("SET_PATHNAME", window.location.pathname);
      if (to.path == "/" || to.path == "/home" || to.path == '/brand' || to.path == '/brand/component' || to.path == '/brand/resource') {
        this.isshowFooter = false;
      } else {
        this.isshowFooter = true;
      }
      if (to.path == '/brand' || to.path == '/brand/component' || to.path == '/brand/resource') {
        this.hidenTop = false
      } else {
        this.hidenTop = true;
      }
      if (localStorage.getItem("ismobile") == 1) {
        if (to.path == "/mining" || to.path == "/asset" || to.path == "/pool") {
          this.ismargin = true;
        } else {
          this.ismargin = false;
        }
      }
    },
  },

  methods: {
    inviteSet(val) {
      this.$store.commit("SET_BINDINVITED", val);
    },
    bindInvitedCode(){
      if(this.inviteCode==''){
        return;
      }
      if(this.loading){
        return;
      }
      this.loading = true;
      this.$post(this.URL.invite.bindCode, {inviteCode:this.inviteCode}).then(res => {
        if (res.code == 0) {
          this.$store.commit("SET_BINDINVITED", false);
          this.$post(this.URL.user.info, {}).then(result => {
            this.$store.commit('SET_USERDATA', result.data)
          });
          let msg = this.$message.success(res.message)
          setTimeout(() => {
            msg.close();
          }, 2000);
          setTimeout(() => {
            location.reload();
          }, 2000);
        } else {
          this.$message.error(res.message)
        }
        this.loading = false;
      })
    },
    reload() {
      try {
        if (this.reloadTimer) {
          clearTimeout(this.reloadTimer)
        }
        this.reloadTimer = setTimeout(() => {
          this.isRouterActive = false;
          this.$nextTick(() => {
            this.isRouterActive = true
          })
        }, 200)

      } catch (error) {
        console.log('app-error', error)

      }
    },
    handlerInvite() {
      if (localStorage.getItem('token') != null) {
        this.$router.push('/blind')
      } else {
        this.$router.push('/login')
      }
    },
    handlerClose() {
      this.showActivity = false;
      bus.emit('closeActivity')
    },
    change() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop >= 2) {
        this.istop = true;
      } else {
        this.istop = false;
      }
    },
    getChainList() {
      // 获取配置的链列表
      this.$post(this.URL.home.chainList, {}).then((res) => {
        if (res.code == 0) {
          let chainList = res.data;
          chainList.forEach((e) => {
            if (e.chainName) {
              if (e.chainName == "BSC" || e.chainName == "bsc") {
                e.chainId = "56";
              }
              if (e.chainName == "ETH" || e.chainName == "eth") {
                e.chainId = "1";
              }
              if (e.chainName == "HECO" || e.chainName == "heco") {
                e.chainId = "128";
              }
            }
          });
          localStorage.setItem("chainList", JSON.stringify(chainList));
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<style lang="less">
@import "./public/css/font.css";
@import "@/public/css/planet.less";

* {
  font-family: "BaiJamjuree-Medium";
}

#app {
  min-height: 100vh;
  background: #000;
}

.rg-rout-out {
  display: none;
}
.rg-rout {
  position: fixed;
  right: 0;
  bottom: 150px;
  width: 56px;
  z-index: 99;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
  span {
    margin-top: -10px;
    display: block;
    width: 100%;
    padding: 2px 4px;
    color: #C9FA5B;
    font-family: "Bai Jamjuree";
    font-size: 8px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.topheight {
  top: 52px !important;
}

.topbg {
  background: #000;
}

.marginTop {
  padding-bottom: 70px;
}

.mobilesign {
  width: 100%;
  height: 44px;

  .back {
    width: 14px;
    height: 22px;
  }
}

.activitydialog {
  position: fixed;
  bottom: 120px;
  right: 20px;
  padding: 12px 24px 12px 16px;
  z-index: 56;
  border-radius: 90px;
  background: #fff;
  cursor: pointer;

  .img1 {
    flex: 0 0 30px;
    width: 30px;
    height: 30px;
  }

  .dialog-info {
    margin-left: 8px;
    font-size: 16px;
    color: #000000;
    line-height: 30px;
    font-weight: 600;

    img {
      flex: 0 0 6px;
      width: 6px;
      height: 10px;
      margin: 10px 0 0 8px;
    }
  }
}
.min-box{
  border-radius: 20px;
}

.invited-con {
  position: relative;
  width: 500px;
  padding: 40px;
  box-sizing: border-box;
  .invited-input{
    width: 100%;
    background: transparent;
    input {
      width: 100%;
      height: 48px;
      line-height: 48px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      padding: 0px 12px;
      box-sizing: border-box;
      color: #fff;
      font-size: 20px;
    }
  }

  .close {
    position: absolute;
    bottom: -58px;
    left: 50%;
    width: 38px;
    height: 38px;
    transform: translateX(-50%);
    cursor: pointer;
  }

  .title {
    margin-bottom: 32px;
    font-size: 24px;
    color: #c9fa5b;
    line-height: 24px;
  }
  .btnAll-box{
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }
  .notice-btn {
    
    width: 48%;
    height: 52px;
    background: url("./public/images/new/btnbg40.png") center no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    font-size: 18px;
    color: #000000;
    text-align: center;
    line-height: 52px;
  }
  .not-active{
    background: url("./public/images/new/btnbj41.jpg") center no-repeat;
    background-size: 100% 100%;
    cursor: not-allowed
  }
  .btn-cancel{
    color: #c9fa5b;
    background: url("./public/images/new/btnbg39.png") center no-repeat;
    background-size: 100% 100%;
  }
}

@media (max-width:1200px) {
  .rg-rout-out {display: block;}
}
@media (max-width:767px) {
  .activitydialog {
    padding: 16px;
    bottom: 200px;
    right: 16px;
    background: #C9FA5B;

    img {
      flex: 0 0 32px;
      width: 32px;
      height: 32px;
    }

    .dialog-info {
      display: none;
    }
  }
  .min-box{
    width: 92%;
  }
  .invited-con {
    width: 100%;
    padding: 20px 16px;
    .invited-input{
      input {
        height: 42px;
        line-height: 42px;
        font-size: 16px;
      }
    }
    .close {
      bottom: -40px;
      width: 28px;
      height: 28px;
    }

    .title {
      margin-bottom: 12px;
      font-size: 16px;
    }
    .notice-btn {
      height: 40px;
      font-size: 14px;
      line-height: 38px;
    }
  }
  .rg-rout-out {display: block;}

}</style>
