<template>
    <div class="trade">
        <div class="trade-title">{{ $t('home.desc33') }}</div>
        <div class="google-info-tips">{{ $t('home.desc34') }}</div>
        <div class="trade-info">

            <div class="info-input">
                <input type="tel" v-model="googleCode" onkeyup="this.value=this.value.replace(/\D/g,'')"
                    onafterpaste="this.value=this.value.replace(/\D/g,'')" maxlength="6" :placeholder="$t('home.desc35')" />
            </div>
        </div>
        <div class="trade-error">
            <p>{{ errtips2 }}</p>
        </div>
        <div class="google-tips">{{ $t('home.desc36') }}</div>
        <div class="trade-btn pc" @click="handlerSubmit" v-loading="loading">{{ $t('home.desc20') }}</div>
        <div class="trade-btn h5" @click="handlerSubmit1" v-loading="loading">{{ $t('home.desc20') }}</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            googleCode: '',
            errtips2: '',
            loading: false
        }
    },
    methods: {
        handlerSubmit() {
            this.errtips2 = '';
            if (this.googleCode == '') {
                this.errtips2 = this.$t('home.desc35');
                return;
            }
            if (!this.loading) {
                this.loading = true;
                this.$post(this.URL.user.unbindgoogle, {
                    code: this.googleCode
                }).then(res => {
                    if (res.code == 0) {
                        this.loading = false;
                        this.$message.success(this.$t('home.desc41'))
                       
                        this.$post(this.URL.user.info, {}).then(result => {
                            this.$store.commit('SET_USERDATA', result.data)
                        })
                        setTimeout(() => {
                            this.$emit('handlerSwitch', 'headerNav');
                        }, 2000);
                    } else {
                        this.loading = false;
                        this.$message.error(res.message)
                    }
                })
            }
        },
        handlerSubmit1() {
            this.errtips2 = '';
            if (this.googleCode == '') {
                this.errtips2 = this.$t('home.desc35');
                return;
            }
            if (!this.loading) {
                this.loading = true;
                this.$post(this.URL.user.unbindgoogle, {
                    code: this.googleCode
                }).then(res => {
                    if (res.code == 0) {
                        this.loading = false;
                        this.$message.success(this.$t('home.desc41'))
                        
                        this.$post(this.URL.user.info, {}).then(result => {
                            this.$store.commit('SET_USERDATA', result.data)
                        })
                        setTimeout(() => {
                            this.$router.go(-1)
                        }, 2000);
                    } else {
                        this.loading = false;
                        this.$message.error(res.message)
                    }
                })
            }
        }
    }
}
</script>

<style lang="less" scoped>
.trade {
    .trade-title {
        margin-bottom:24px;
        font-size: 24px;
        color: #ffffff;
        font-weight: 600;
    }

    .google-info-tips {
        margin: 0 auto;
        font-size: 16px;
        color: #ffffff;
        font-weight: 600;
        text-align: center;
        line-height: 34px;
    }

    .trade-info {
        margin-top: 24px;

        .info-input {
            position: relative;
            padding: 12px 14px;
            border: 1px solid #393939;
            box-sizing: border-box;

            input {
                width: 100%;
                height: 17px;
                font-size: 16px;
                color: #ffffff;

                &::placeholder {
                    color: #5F5F5F;
                }
            }

            img {
                position: absolute;
                top: 50%;
                right: 15px;
                width: 16px;
                height: 16px;
                transform: translateY(-50%);
                cursor: pointer;
            }
        }
    }

    .trade-error {

        height: 40px;

        p {
            padding: 10px 0 0;
            font-size: 13px;
            color: #F95454;
        }
    }

    .google-tips {
        margin-bottom: 40px;
        font-size: 13px;
        color: #999999;
        line-height: 24px;
    }

    .trade-btn {
        width: 100%;
        height: 57px;
        background: url('../public/images/new/rightbtn.png') center no-repeat;
        background-size: 100% 100%;
        font-size: 20px;
        color: #000000;
        text-align: center;
        line-height: 60px;
        cursor: pointer;
    }
    .h5{
        display: none;
    }
}

@media (max-width:1200px) {
    .trade {
        padding: 0 30px 16px;
        .trade-title {
            padding-bottom: 12px;
            margin-bottom: 12px;
            font-size: 16px;
        }

        .trade-error {
            height: 30px;

            p {
                padding-top: 5px;
            }
        }

        .google-info-tips {
            width: 100%;
            font-size: 16px;
            line-height: 20px;
        }

        .trade-info {
            margin-top: 30px;

            .info-input {
                input {

                    font-size: 14px;


                }
            }
        }

        .google-tips {
            font-size: 12px;
            line-height: 22px;
        }
        .trade-btn{
        }
    }
}
@media (max-width:767px) {
    .trade {
        padding: 20px 16px;
        background: #181818;
        border-radius: 8px;
     
        .trade-title {
            display: none;
        }

        .trade-info {
            padding: 0;
        }

        .trade-tips {
            padding-left: 0;
            color: #999999;
        }
        .trade-error{
            padding:0;
        }
        .trade-btn {
            width: 100%;
            height: 49px;
            font-size: 16px;
            line-height: 49px;
        }

        .pc {
            display: none;
        }

        .h5 {
            display: block;
        }
    }
}
</style>