
import { EthereumProvider } from '@walletconnect/ethereum-provider';
import store from '@/store/index.js';
import i18n from "@/public/lang/i18n";
import { ElMessage } from "element-plus";
import Web3 from 'web3';
const CONNECT = {};
const projectId = process.env.VUE_APP_CURENV == 'production'?'d6df11afbb13a9ff2f16c470dba6285f':'c141a10bb3c0ef4f7e24ac6aabde0f6c'
let web3 = null;
let webModel=true;
let provider_current = null;
let provider_external = null;
let provider_Metamask = null;
let provider_Bitget = window.bitkeep && window.bitkeep.ethereum;
let connectResult = {
    state:false,
    agree:true
};
const ChainCfg = {
    1: {
        chainId: '0x1',
        chainName: 'Ethereum Mainnet',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: ['https://mainnet.infura.io/v3/52d82fe657a2478fa40b336d286554ac'],
        blockExplorerUrls: ['https://etherscan.io'],
    },
    3: {
        chainId: '0x3',
        chainName: 'Ropsten testNet',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: ['https://ropsten.infura.io/v3/52d82fe657a2478fa40b336d286554ac'],
        blockExplorerUrls: ['https://ropsten.etherscan.io'],
    },
    5:{
        chainId: '0x5',
        chainName: 'Goerli-test',
        nativeCurrency: {
            name: 'GoerliETH',
            symbol: 'GoerliETH',
            decimals: 18,
        },
        rpcUrls: ['https://goerli.infura.io/v3/'],
        blockExplorerUrls: ['https://goerli.etherscan.io'],
    },
    42: {
        chainId: '0x2a',
        chainName: 'Kovan testNet',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: ['https://kovan.infura.io/v3/52d82fe657a2478fa40b336d286554ac'],
        blockExplorerUrls: ['https://kovan.etherscan.io'],
    },
    56: {
        chainId: '0x38',
        chainName: 'Binance Smart Chain',
        nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18,
        },
        rpcUrls: ['https://bsc-dataseed.binance.org/'],
        blockExplorerUrls: ['https://bscscan.com/'],
    },
    97: {
        chainId: '0x61',
        chainName: 'Binance Smart Chain - TestNet',
        nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18,
        },
        rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
        blockExplorerUrls: ['https://testnet.bscscan.com/'],
    },
    137: {
        chainId: '0x89',
        chainName: 'Polygon Mainnet',
        nativeCurrency: {
            name: 'MATIC',
            symbol: 'MATIC',
            decimals: 18,
        },
        rpcUrls: ['https://polygon.llamarpc.com'],
        blockExplorerUrls: ['https://polygonscan.com'],
    }
};
(async function(){
    provider_Metamask = window.ethereum?window.ethereum:null;
    let ua = navigator.userAgent.toLowerCase();
    store.commit("changeShowMataMask",true);
    if(window.ethereum) {
        web3 = new Web3(provider_Metamask);
        webModel=true;
    }else if (window.web3) {
        web3 = window.web3;
        webModel=true;
    }else {
        if(ua.indexOf('imtoken')>=0||ua.indexOf('tokenpocket')>=0||ua.indexOf('metamask')>=0||ua.indexOf('bitkeep')>=0) {
            store.commit("changeShowMataMask",true);
        }else{
            store.commit("changeShowMataMask",false);
        }
        web3 = new Web3(new Web3.providers.HttpProvider("https://blissful-blissful-dream.bsc-testnet.discover.quiknode.pro/0b9273ffcde8e4e3b9901e788f2e37daf2ad003b/"));
        webModel=false;
    }
    window.Provider = web3;
    initialization();
})();

async function initialization(){
    // ElMessage.error(store.state.supportData+"");
    provider_external = await EthereumProvider.init({
        projectId: 'f3b1e368503b4aada4c931c128b8c75e', // required
        chains: [56], // required
        // rpcMap:['https://wispy-rough-mountain.bsc-testnet.discover.quiknode.pro/a6fc8166b579317eec2fd3387e11cb78958a796c/'],
        showQrModal: true // requires  @walletconnect/modal
    });
}  
CONNECT.isProvider = webModel;

//判断是否连接
CONNECT.getConnect=async()=>{
    var isConnect=false;
    var address = '';
    var chainId = '';
    if(provider_external){
        address = provider_external.accounts[0];
        chainId = provider_external.chainId;
        if (address!=undefined&&address!='') {
            isConnect=true;
        }
    }
   return {address:address,chainId:chainId,isConnect:isConnect};
}


//判断是否是手机或者App内部浏览器
CONNECT.isMobile = () => {
    var userAgentInfo = navigator.userAgent;
    var mobileAgents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
    var mobile_flag = false;
    //根据userAgent判断是否是手机
    for (var v = 0; v < mobileAgents.length; v++) {
        if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
            mobile_flag = true;
            break;
        }
    }
    return mobile_flag;
}

// walletconnect 钱包链接
CONNECT.walletconnect=async(t)=>{
    if(t==1){
        await provider_Metamask.request({
            method: "eth_requestAccounts",
        }).then(async () => {
            web3.setProvider(provider_Metamask);
            window.Provider = web3; //根据节点的web3实例
            connectResult.chainId = await web3.eth.getChainId();
            const accounts = await web3.eth.getAccounts();
            connectResult.address = accounts[0];
            connectResult.balance = '';
            connectResult.state = true;
        }).catch(async(reason)=>{
            //如果用户拒绝了登录请求
            if (reason.code ===4001) {
                // 用户拒绝登录后执行语句；
                connectResult.agree=false;
            } else {
                // 本不该执行到这里，但是真到这里了，说明发生了意外
                console.log(reason.message+"(There was a problem signing you in)");
                connectResult.agree=false;
            }
        })
    }else if(t==2){
        if (!provider_Bitget) {
            window.open('https://web3.bitget.com/zh-CN/wallet-download?type=2');
            return
        }
        await provider_Bitget.request({
            method: "eth_requestAccounts",
        }).then(async (accounts) => {
            web3.setProvider(provider_Bitget);
            connectResult.chainId = provider_Bitget.networkId;
            connectResult.address = accounts[0];
            connectResult.balance = '';
            connectResult.state = true;
        }).catch(async(reason)=>{
            //如果用户拒绝了登录请求
            if (reason.code ===4001) {
                // 用户拒绝登录后执行语句；
                connectResult.agree=false;
            } else {
                // 本不该执行到这里，但是真到这里了，说明发生了意外
                console.log(reason.message+"(There was a problem signing you in)");
                connectResult.agree=false;
            }
        })

    }else if(t==3){
        await provider_external.enable().catch(function(reason) {
            //如果用户拒绝了登录请求
            if (reason.message === "User rejected provider access") {
                // 用户拒绝登录后执行语句；
                connectResult.agree=false;
            } else {
                // 本不该执行到这里，但是真到这里了，说明发生了意外
                console.log(reason.message+"(There was a problem signing you in)");
                connectResult.agree=false;
            }
            ElMessage.error(reason.message);
            // setTimeout(function(){
            //     location.reload(); //页面刷新
            // },300);
           
        }).then(async () => {
            web3.setProvider(provider_external);
            window.Provider = web3; //根据节点的web3实例
            window.ethereum = provider_external;
            connectResult.chainId = provider_external.chainId;
            connectResult.address = provider_external.accounts[0];
            connectResult.balance = '';
            connectResult.state = true;
        })
    }
    return connectResult;
};
//监听事件
CONNECT.listeningEvents = (callback)=>{
   //订阅账号更改
   provider_current.on("accountsChanged", (accounts) => {callback('accounts',accounts)});
   //订阅链id更改
   provider_current.on("chainChanged", (chainId) => {callback('chainId',chainId)});
} 
// 断开链接
CONNECT.disconnect=async function disconnect(){
    // 是否已连接
    await provider_external.disconnect();
}
//数据签名
CONNECT.sign=async function (data,address){
    return await web3.eth.sign(data, address)
}
//数据签名
CONNECT.personalSign=async function (msg,address){
    ElMessage.success(`${i18n.global.t("sign.desc79")}`,);
    let signResult = {
        agree:true,
    };
    await web3.eth.personal.sign(web3.utils.utf8ToHex(msg),address,'').then(async (response) => {
        signResult.signature = response;
    }).catch(async (e) => {
        signResult.agree=false;
    });
    return signResult
}
//数据签名私钥
CONNECT.privateSign=async function (msg,privateKey){
    return await web3.eth.accounts.sign(msg,privateKey);
}
CONNECT.recover = async function (msg,signature){
    return await web3.eth.personal.ecRecover(web3.utils.utf8ToHex(msg),signature)
}
//发起交易
CONNECT.sendTransaction = async function(_from,_to,_encodeData,_value){
    let result ={};
    let params ={
        from: _from,
        to: _to,
        data: _encodeData+'f9f4d0a7',
    };
    if(_value!=0){
        params.value= _value;
    }
    await web3.eth.sendTransaction(params).then(function(res){
        console.log("handleReqPri ===>", result);
        result.state=true;
        result.log=res;
    }).catch((_error) => {
        console.error("privilege error ? : ", _error);
        result.state=false;
        result.log=_error;
    });
    return result
}

//web3 获取指定账号ETH余额
CONNECT.getBalance = async function(address) {
    return web3.utils.fromWei(await web3.eth.getBalance(address).then(),'ether');
};
//数据编码
CONNECT.encodeParameter = function (typeObject,valueObject){
    return web3.eth.abi.encodeParameter(typeObject,valueObject)
}
//获取链id
CONNECT.getChainId = async function(){
    return await web3.eth.getAccounts().then();
}
// 数据签名
CONNECT.signTypedData = function (from, msgParams,back){
    var params = [from, msgParams]
    var method = 'eth_signTypedData_v4'
    web3.currentProvider.sendAsync({
        method,
        params,
        from,
        }, function (err, result) {
            if (err){
                back(false,err);
            }else if (result.error){
                back(false,result.error);
            }else{
                // let r = result.result.slice(0, 66);
                // let s = '0x' + result.result.slice(66, 130);
                // let v = '0x' + result.result.slice(130, 132);
                back(true,result.result);
            }
    });
}
//切换网络及添加支持网络
CONNECT.setupNetwork = async function (){
    let state =1;
    const provider = window.ethereum;
    const chainId = await CONNECT.getChainId();

    var supportIndex = $.inArray(chainId, store.state.supportData)
    if(supportIndex==-1){ 
        //配置网络信息
        let cfg = ChainCfg[56]; 
        if(provider) {
            //切换已有网络
            await provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{
                    chainId: cfg.chainId,
                }]
            })
            .then((result) => {
                state=0;
            })
            .catch(async (e) => {
                //找不到网络  添加网络
                if(e.code === 4902) {
                    await provider.request({method: 'wallet_addEthereumChain',params: [cfg]})
                    .then(async () => {
                        state=0; 
                    }).catch((error) => {
                        console.log('Failed to setup the network in Metamask:', error)
                    })
                }
            })
        } else {
            console.log("Can't setup network on metamask because window.ethereum is undefined")
        }
    }else{
        state=0;
    }
    return  state

};
CONNECT.WEB3=web3; //将web3导出去

export default CONNECT