<template>
    <div class="trade">
        <div class="trade-title">{{ $t('home.desc22') }}</div>
        <div class="google-info">
            <div class="google-title">{{ $t('sign.desc42') }}</div>
            <div class="google-desc">{{ $t('sign.desc43') }}</div>
        </div>
        <div class="google-info">
            <div class="google-title">{{ $t('sign.desc44') }}</div>
            <div class="google-info-code">
                <div class="code-img">
                    <vue-qr :text="address" :size="150" :margin="0" :callback="qrBack">
                    </vue-qr>
                </div>
                <div class="code-tips">{{ $t('sign.desc45') }}</div>
                <div class="code-copy flexcenter" @click="copy($event, this.googleInfo.secretKey)">{{ $t('home.desc31') }}
                </div>
            </div>
        </div>
        <div class="google-info">
            <div class="google-title">{{ $t('sign.desc46') }}</div>
            <div class="info-input">
                    <input type="tel" v-model="googleCode" onkeyup="this.value=this.value.replace(/\D/g,'')"
                        onafterpaste="this.value=this.value.replace(/\D/g,'')" maxlength="6"
                        :placeholder="$t('home.desc17')" />
                </div>
                
            </div>
            <div class="trade-btn contbn pc" @click="handlerSubmit" v-loading="loading">{{ $t('home.desc20') }}</div>
            <div class="trade-btn contbn h5" @click="handlerSubmit1" v-loading="loading">{{ $t('home.desc20') }}</div>
        <!-- <div class="google-info">
            <div class="google-info-img">
                <img src="../public/images/googleimg.png" alt="" />
            </div>
            <div class="google-info-img-h5">
                <img src="../public/images/googleimg-h5.png" alt="" />
            </div>
            <div class="google-info-tips">{{ $t('home.desc26') }}</div>
            <div class="google-info-app flex">
                <div class="app-info">
                    <img src="../public/images/iosicon.png" alt="" />
                    <div>{{ $t('home.desc27') }}</div>
                    <p>{{ $t('home.desc28') }}</p>
                </div>
                <div class="app-info">
                    <img src="../public/images/androidicon.png" alt="" />
                    <div>{{ $t('home.desc27') }}</div>
                    <p>{{ $t('home.desc29') }}</p>
                </div>
            </div>
            <div class="google-info-app1 flex">
                <div class="app-info flex">
                    <img src="../public/images/iosicon-h5.png" alt="" />
                    <div class="info">
                        <div>{{ $t('home.desc27') }}</div>
                        <p>{{ $t('home.desc28') }}</p>
                    </div>
                </div>
                <div class="app-info flex">
                    <img src="../public/images/androidicon-h5.png" alt="" />
                    <div class="info">
                        <div>{{ $t('home.desc27') }}</div>
                        <p>{{ $t('home.desc29') }}</p>
                    </div>
                </div>
            </div>
            <div class="trade-btn" @click="handlerNext">{{ $t('home.desc20') }}</div>
        </div>
        <div class="google-info">
            <div class="google-info-tips1">{{ $t('home.desc30') }}</div>
            <div class="google-info-code">
                <div class="code-img">
                    <vue-qr :text="address" :size="400" :margin="25" :callback="qrBack">
                    </vue-qr>
                </div>
                <div class="code-copy flexcenter" @click="copy($event,this.googleInfo.secretKey)">{{ $t('home.desc31') }}</div>
            </div>
            <div class="trade-btn" @click="handlerNext">{{ $t('home.desc20') }}</div>
        </div>
        <div class="google-info">
            <div class="google-info-tips1">{{ $t('home.desc32') }}</div>
            <div class="trade-info">

                <div class="info-input">
                    <input type="tel" v-model="googleCode" onkeyup="this.value=this.value.replace(/\D/g,'')"
                        onafterpaste="this.value=this.value.replace(/\D/g,'')" maxlength="6"
                        :placeholder="$t('home.desc17')" />
                </div>
            </div>
            <div class="trade-error">
                <p>{{ errtips2 }}</p>
            </div>
            <div class="trade-btn contbn" @click="handlerSubmit" v-loading="loading">{{ $t('home.desc20') }}</div>
        </div> -->
    </div>
</template>

<script>
import VueQr from 'vue-qr/src/packages/vue-qr.vue';
import clipboard from '@/utils/libs/clipboard';
import bus from "@/utils/bus";
export default {
    components: {
        VueQr,
    },
    data() {
        return {
            actsetp: 0,
            errtips2: '',
            googleCode: '',
            loading: false,
            googleInfo: {},
            address: '',
            qrData: ''

        }
    },
    mounted() {
        this.$post(this.URL.user.google, {}).then(res => {
            if (res.code == 0) {
                this.googleInfo = res.data;
                this.address = res.data.qrCodeStr;
            } else {
                this.$message.error(res.message)
            }

        })
    },
    methods: {
        copy(e, text) {
            clipboard(e, text)
            this.$message.success(this.$t('planet.desc61'))
        },
        qrBack(dataUrl, id) { // 缓存二维码数据
            this.qrData = dataUrl
        },
        handlerNext() {
            this.actsetp += 1;

        },
        handlerSubmit() {
            this.errtips2 = '';
            if (this.googleCode == '') {
                this.errtips2 = this.$t('home.desc17');
                return;
            }

            if (!this.loading) {
                this.loading = true;
                this.$post(this.URL.user.setgoogle, {
                    secretKey: this.googleInfo.secretKey,
                    code: this.googleCode
                }).then(res => {
                    if (res.code == 0) {
                        this.loading = false;
                        this.$message.success(this.$t('home.desc42'))
                       
                        this.$post(this.URL.user.info, {}).then(result => {
                            this.$store.commit('SET_USERDATA', result.data)
                        })
                        bus.emit('updateGoogle')
                        setTimeout(() => {
                            this.$emit('handlerSwitch', 'headerNav');
                        }, 2000);
                    } else {
                        this.loading = false;
                        this.$message.error(res.message)
                    }
                })

            }
        },
        handlerSubmit1() {
            this.errtips2 = '';
            if (this.googleCode == '') {
                this.errtips2 = this.$t('home.desc17');
                return;
            }

            if (!this.loading) {
                this.loading = true;
                this.$post(this.URL.user.setgoogle, {
                    secretKey: this.googleInfo.secretKey,
                    code: this.googleCode
                }).then(res => {
                    if (res.code == 0) {
                        this.loading = false;
                        this.$message.success(this.$t('home.desc42'))
                       
                        this.$post(this.URL.user.info, {}).then(result => {
                            this.$store.commit('SET_USERDATA', result.data)
                        })
                        setTimeout(() => {
                            this.$router.go(-1)
                        }, 2000);
                    } else {
                        this.loading = false;
                        this.$message.error(res.message)
                    }
                })

            }
        }
    }
}
</script>

<style lang="less" scoped>
.trade {
    .trade-title {
        margin-bottom: 24px;
        font-size: 24px;
        font-family: 'Nebula';
        color: #ffffff;
        font-weight: 600;
    }


    .google-info {
        padding: 14px 16px;
        margin-bottom: 20px;
        border: 1px solid #393939;
        border-radius: 8px;
        color: #FFFFFF;

        .google-title {
            font-size: 13px;

        }

        .google-desc {
            font-size: 15px;
            margin-top: 8px;
        }
        .google-info-code{
            margin-top: 12px;
            .code-img{
                margin: 0 auto;
                width: 180px;
                padding: 15px 0;
                background: #fff;
                text-align: center;
            }
            .code-tips{
                margin: 12px 0 20px;
                font-size: 14px;
                text-align: center;
            }
            .code-copy{
                width: 180px;
                height: 42px;
                margin: 0 auto;
                background: #232323;
                font-size: 14px;
                color: #C9FA5B;
                cursor: pointer;
            }
        }
        .info-input{
            margin-top: 12px;
            padding: 13px 16px;
            background: #232323;
            input{
                width: 100%;
                height: 20px;
                font-size: 14px;
                color: #fff;
                &::placeholder{
                    color: #5F5F5F;
                }
            }
        }
    }
    .trade-btn {
        margin-top: 37px;
        width: 100%;
        height: 57px;
        background: url('../public/images/new/rightbtn.png') center no-repeat;
        background-size: 100% 100%;
        font-size: 20px;
        color: #111111;
        font-family: 'Nebula';
        text-align: center;
        line-height: 60px;
        cursor: pointer;
    }
    .h5{
        display: none;
    }
}

@media (max-width:1200px) {
    .trade {
        padding: 0 30px 16px;
        .trade-title {
            width: 284px;
            padding: 0 0 24px;
            margin: 0 auto 16px;
            font-family: 'Nebula';
            font-size: 16px;
            line-height: 18px;
        }



    }
}
@media (max-width:767px) {
    .trade {
        padding: 20px 16px;
        background: #181818;
        border-radius: 8px;
     
        .trade-title {
            display: none;
        }

        .trade-info {
            padding: 0;
        }

        .trade-tips {
            padding-left: 0;
            color: #999999;
        }
        .trade-error{
            padding:0;
        }
        .trade-btn {
            width: 100%;
            height: 49px;
            font-size: 16px;
            line-height: 49px;
        }

        .pc {
            display: none !important;
        }

        .h5 {
            display: block;
        }
    }
}

</style>