import Clipboard from 'clipboard'

export default function clipboard(e, text = '') {
    return new Promise((resolve, reject) => {
        const clipboard = new Clipboard(e.target, { text: () => text })

        clipboard.on('success', e => {
            resolve()
            // 释放内存
            clipboard.off('error')
            clipboard.off('success')
            clipboard.destroy()
        })
        clipboard.on('error', e => {
            reject()
            // 释放内存
            clipboard.off('error')
            clipboard.off('success')
            clipboard.destroy()
        })
        clipboard.onClick(e)
    })
}