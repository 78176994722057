<template>
    <div class="trade">
        <div class="trade-title">{{ $t('transferAccount.desc0') }}</div>
        <div class="trade-info">
            <div class="info-name">{{ $t('transferAccount.desc1') }}</div>
            <div class="info-input flex">
                <input type="text" 
                v-model="transferAccountModel.email"  
                @focus="errorModel.email=''" 
                @blur="checkEmail" 
                :placeholder="$t('transferAccount.desc2')" />
            </div>
        </div>
        <div class="trade-error">
            <p>{{ errorModel.email }}</p>
        </div>
        <div class="trade-info">
            <div class="info-name">{{ $t('transferAccount.desc3') }}</div>
            <div class="info-input flex">
                <input type="text" 
                v-model="transferAccountModel.account" 
                @focus="errorModel.account=''" 
                onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')" 
                :placeholder="$t('transferAccount.desc4')" />
            </div>
        </div>
        <div class="trade-error">
            <p>{{ errorModel.account }}</p>
        </div>
        <div class="trade-info">
            <div class="info-name">{{ $t('transferAccount.desc5') }}</div>
            <div class="info-input flex">
                <input :type="psdtype1" 
                v-model="password" 
                @focus="errorModel.loginPassword=''"  
                onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')" 
                :placeholder="$t('transferAccount.desc6')" />
                <img :src="psdtype1 == 'password' ? require('../public/images/ceyes.png') : require('../public/images/eyesopen.png')" alt="" @click="handlerEye(1)" />
            </div>
        </div>
        <div class="trade-error">
            <p>{{ errorModel.loginPassword }}</p>
        </div>
        <div class="trade-info">
            <div class="info-name">{{ $t('transferAccount.desc7') }}</div>
            <div class="info-input flex">
                <input :type="psdtype1" 
                v-model="requirePwd" 
                @focus="errorModel.requirePwd=''" 
                onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')" 
                :placeholder="$t('transferAccount.desc8')" />
                <img :src="psdtype1 == 'password' ? require('../public/images/ceyes.png') : require('../public/images/eyesopen.png')" alt="" @click="handlerEye(1)" />
            </div>
        </div>
        <div class="trade-error">
            <p>{{ errorModel.requirePwd }}</p>
        </div>

        <div class="trade-info">
            <div class="info-name">{{ $t('transferAccount.desc9') }}</div>
            <div class="info-input flex">
                <input type="tel" 
                v-model="transferAccountModel.code" 
                @focus="errorModel.code=''" 
                onkeyup="this.value=this.value.replace(/\D/g,'')"
                maxlength="6" 
                :placeholder="$t('transferAccount.desc10')" />
                <p @click="handlerSend">{{ sendText }}</p>
            </div>
        </div>
        <div class="trade-error">
            <p>{{ errorModel.code }}</p>
        </div>
        <div class="trade-btn pc" @click="handlerSubmit(1)" v-loading="loading">{{ $t('home.desc20') }}</div>
        <div class="trade-btn h5" @click="handlerSubmit(2)" v-loading="loading">{{ $t('home.desc20') }}</div>
    </div>
</template>

<script>


export default {
    data() {
        return {
    
            psdtype: 'password',
            psdtype1: 'password',
           
            loading: false,
            issend: true,
            transferAccountModel:{
                email:'',
                account:'',
                code:'',
                loginPassword:'',
            },
            errorModel:{
                email:'',
                account:'',
                code:'',
                loginPassword:'',
                requirePwd:'',
            },
            password:'',
            requirePwd:'',
            sendText: this.$t('sign.desc26'),
        }
    },
    mounted() {
        if(this.$store.state.userData.account){
            this.transferAccountModel.email = this.$store.state.userData.email||this.$store.state.userData.sourceEmail;
            this.transferAccountModel.account = this.$store.state.userData.account;
        }
    },
    methods: {
        //验证邮箱格式
        checkEmail(){
            if(this.transferAccountModel.email.indexOf('@')<=-1){
                this.errorModel.email = this.$t('transferAccount.desc16');
            }
        },
        //发送验证码
        handlerSend() {
            let _this = this;
            if (this.transferAccountModel.email == '') {
                this.errorModel.email = this.$t('transferAccount.desc11');
                return;
            }
            if (this.issend) {
                this.issend = false;
                this.$post(this.URL.login.sendverify, {
                    accountType: 2,
                    account: this.transferAccountModel.email,
                    type: 1
                }).then(res => {
                    if (res.code == 0) {
                        this.$message.success(this.$t('sign.desc37'));
                        let num = 60
                        this.timeNull = setInterval(() => {
                            num--;
                            if (num < 1) {
                                clearInterval(_this.timeNull);
                                _this.issend = true;
                                _this.sendText = _this.$t('sign.desc26')
                            } else {
                                this.sendText = `${num} S`
                            }
                        }, 1000)
                    } else {
                        this.issend = true;
                    }
                })

            }
        },
        handlerEye(i) {
            if (i == 0) {
                this.psdtype == 'password' ? this.psdtype = 'text' : this.psdtype = 'password'
            } else {
                this.psdtype1 == 'password' ? this.psdtype1 = 'text' : this.psdtype1 = 'password'
            }
        },
        handlerSubmit(k) {
            if (this.transferAccountModel.email == '') {
                this.errorModel.email = this.$t('transferAccount.desc11');
                return;
            }
            if (this.transferAccountModel.account == '') {
                this.errorModel.account = this.$t('transferAccount.desc12');
                return;
            }
            if (this.password == '') {
                this.errorModel.loginPassword = this.$t('transferAccount.desc13');
                return;
            }
            let reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d).*$/;
            if (!reg.test(this.password)) {
                this.errorModel.loginPassword=this.$t('sign.desc53');
                return;
            }
            
            if (this.requirePwd != this.password) {
                this.errorModel.requirePwd = this.$t('transferAccount.desc14');
                return;
            }
            if (this.transferAccountModel.code == '') {
                this.errorModel.code = this.$t('transferAccount.desc15');
                return;
            }
            this.transferAccountModel.loginPassword=this.common.mdpassword(this.password)
            if (!this.loading) {
                this.loading = true;
                this.$post(this.URL.login.bindhcuser, this.transferAccountModel).then(res => {
                    if (res.code == 0) {
                        this.loading = false;
                        this.transferAccountModel = {
                            email:'',
                            account:'',
                            code:'',
                            loginPassword:'',
                        };
                        this.requirePwd = '';
                        this.password = '';
                        this.$message.success(this.$t('transferAccount.desc22'));
                        this.$store.commit('SET_TRANSFERSHOW',false);
                        this.$store.commit('SET_HVUSERSHOW',false);
                        if(k==1){
                            this.$emit('handlerSwitch', 'headerNav');
                        }else{
                            setTimeout(() => {
                                this.$router.go(-1)
                            }, 2000);
                        }
                    } else {
                        this.loading = false;
                        this.$message.error(res.message)
                    }
                })
            }
        },
    }
}
</script>

<style lang="less" scoped>
.trade {
    .trade-title {
        margin-bottom: 16px;
        font-size: 24px;
        color: #FFFFFF;
        font-weight: 600;
    }

    .trade-info {
        .info-name {
            margin-bottom: 10px;
            font-size: 13px;
            color: #FFFFFF;
            font-weight: 600;
        }

        .info-input {
            position: relative;
            padding: 12px 14px;
            border: 1px solid #393939;
            box-sizing: border-box;

            input {

                flex: 1;
                height: 17px;
                font-size: 16px;
                color: #ffffff;

                &::placeholder {
                    color: #5F5F5F;
                }
            }

            p {
                font-size: 14px;
                color: #C9FA5B;
                line-height: 17px;
                cursor: pointer;
            }

            img {
                position: absolute;
                top: 50%;
                right: 15px;
                width: 16px;
                height: 16px;
                transform: translateY(-50%);
                cursor: pointer;
            }
        }
    }

    .trade-error {
        height: 30px;

        p {
            padding: 5px 0 0;
            font-size: 13px;
            color: #F95454;
        }
    }

    .trade-tips {
        text-align: right;
        font-size: 13px;
        color: #C9FA5B;
        cursor: pointer;
    }

    .trade-btn {
        margin-top: 37px;
        width: 100%;
        height: 57px;
        background: url('../public/images/new/rightbtn.png') center no-repeat;
        background-size: 100% 100%;
        font-size: 20px;
        color: #111111;
        font-family: 'Nebula';
        text-align: center;
        line-height: 60px;
        cursor: pointer;
    }

    .h5 {
        display: none;
    }
}
.modal-con1 {
    position: relative;
    padding: 32px;
    width: 480px;

    .close {
        position: absolute;
        top: 32px;
        right: 32px;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    .modal-title {
        margin-bottom: 32px;
        font-size: 20px;
        color: #ffffff;
    }

    .modal-desc {
        font-size: 16px;
        color: rgba(255, 255, 255, 0.8);
    }

    .divestment-list {
        padding: 20px;
        border-radius: 8px;
        background: #242424;
        flex-wrap: wrap;

        .divestment-info {
            margin-bottom: 16px;
            font-size: 14px;
            color: rgba(255, 255, 255, 0.6);
            line-height: 14px;

            &:last-child {
                margin-bottom: 0;
            }

            div {
                flex: 1;
            }

            p {
                flex: 0 0 40%;
                color: #fff;
                text-align: right;
            }
        }
    }

    .modal-tipsname {
        margin: 21px 0 11px;
        font-size: 14px;
        color: #fff;
        line-height: 16px;

        img {
            width: 16px;
            height: 16px;
            margin-right: 6px;
        }
    }

    .modal-tips1 {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.8);
        line-height: 14px;

        div {
            margin-bottom: 4px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .modal-input {
        margin: 20px 0 16px;
    }

    .modal-tips {
        font-size: 14px;
        color: #eb4343;
    }

    .modal-btncon {
        margin-top: 24px;
        height: 50px;
        background: url("../public/images/new/btnbg16.png") center no-repeat;
        background-size: 100% 100%;
        font-size: 16px;
        color: #000000;
        cursor: pointer;
    }

    .modal-btn {
        margin-top: 32px;
        justify-content: space-between;

        div {
            flex: 0 0 197px;
            height: 50px;
            background: url("../public/images/new/btnbg14.png") center no-repeat;
            background-size: 100% 100%;
            font-size: 18px;
            color: #ffffff;
            cursor: pointer;

            &:last-child {
                background: url("../public/images/new/btnbg15.png") center no-repeat;
                background-size: 100% 100%;
                color: #000000;
            }
        }
    }
}

@media (max-width:1200px) {
    .trade {
        padding-bottom: 14px;

        .trade-title {
            width: 284px;
            padding: 0 0 24px;
            margin: 0 auto 24px;
            font-family: 'Nebula';
            font-size: 16px;
            line-height: 18px;
        }

        .trade-info {
            padding: 0 32px;

            .info-input {

                input {
                    font-size: 14px;
                }
            }
        }

        .trade-error {
            padding: 0 32px;
            white-space: nowrap;
            height: 20px;

            p {
                padding-top: 5px;
            }
        }

        // .pcerror {
        //     display: none;
        // }

        .trade-tips {

            color: #C9FA5B;
            padding: 0 0 0 32px;
            font-size: 12px;
        }

        .trade-btn {
            width: 284px;
            font-family: 'Nebula';
            margin: 14px auto 0;
        }
    }
}

@media (max-width:767px) {
    .trade {
        padding: 20px 16px;
        background: #181818;
        border-radius: 8px;

        .trade-title {
            display: none;
        }

        .trade-info {
            padding: 0;
        }

        .trade-tips {
            padding-left: 0;
            color: #C9FA5B;
        }

        .trade-error {
            padding: 0;
        }

        .trade-btn {
            width: 100%;
            height: 49px;
            font-size: 16px;
            line-height: 49px;
        }

        .pc {
            display: none;
        }

        .h5 {
            display: block;
        }
    }
    .modal-con1 {
        width: 100%;
        padding: 16px;

        .close {
            position: absolute;
            top:20px;
            right: 16px;
            width: 24px;
            height: 24px;
            cursor: pointer;
        }

        .modal-title {
            margin-bottom: 24px;
            font-size: 20px;
            color: #ffffff;
        }

        .modal-desc {
            font-size: 16px;
            color: rgba(255, 255, 255, 0.8);
        }


        .modal-btn {
            margin-top: 32px;
            justify-content: space-between;

            div {
                flex: 0 0 48%;
                height: 44px;
                background: url("../public/images/new/btnbg14.png") center no-repeat;
                background-size: 100% 100%;
                font-size: 18px;
                color: #ffffff;
                cursor: pointer;

                &:last-child {
                    background: url("../public/images/new/btnbg15.png") center no-repeat;
                    background-size: 100% 100%;
                    color: #000000;
                }
            }
        }
    }
}
</style>