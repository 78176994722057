<template>
  <div class="trade">
    <div class="trade-title">{{ $t("sign.desc41") }}</div>
    <div class="trade-list">
      <div class="list-info flex">
        <div>UID</div>
        <p>{{ $store.state.userinfo.userId }}</p>
      </div>
      <div class="list-info flex">
        <div>{{ $t("sign.desc17") }}</div>
        <p v-if="$store.state.userinfo.account">
          {{ $store.state.userinfo.account }}
        </p>

        <div
          v-else
          class="flex not-set-box"
          @click="
            editType = 1;
            showEdit = true;
          "
        >
          {{ $t("asset.desc162") }}
          <img src="../public/images/new/go-to-ico.png" alt="" />
        </div>
      </div>
      <div class="list-info flex">
        <div>{{ $t("sign.desc47") }}</div>
        <p v-if="$store.state.userinfo.email">
          {{ $store.state.userinfo.email }}
        </p>
        <div
          v-else
          class="flex not-set-box"
          @click="
            editType = 2;
            showEdit = true;
          "
        >
        {{ $t("asset.desc162") }}
          <img src="../public/images/new/go-to-ico.png" alt="" />
        </div>
      </div>
      <div class="list-info flex">
        <div>{{ $t("sign.desc48") }}</div>
        <p v-if="nickname != null&&nickname != ''">{{ nickname }}</p>
        <p v-else-if="userId != null&&userId != ''">{{  userId }}</p>
       
        <p v-else>—— ——</p>
      </div>
      <div class="list-info flex">
        <div>{{ $t("sign.desc49") }}</div>
        <p
          v-if="
            $store.state.userinfo.sourceType == 0 ||
            $store.state.userinfo.sourceType == null
          "
        >
          {{ $t("sign.desc67") }}
        </p>
        <p v-else>{{ $store.state.userinfo.sourceTypeName }}</p>
      </div>
      <div class="list-info flex">
        <div>{{ $t("sign.desc50") }}</div>
        <p>{{ $store.state.userinfo.registerTime }}</p>
      </div>
      <div class="list-info flex">
        <div>   {{ $t("sign.desc9") }}</div>
        <p v-if="$store.state.userinfo.hasSetLoginPassword">{{ $t("asset.desc163") }}</p>
        <div v-else class="flex not-set-box" @click="goedit()">
          {{ $t("asset.desc162") }}
          <img src="../public/images/new/go-to-ico.png" alt="" />
        </div>
      </div>
    </div>
    <editInfo
      v-if="showEdit"
      :show="true"
      :type="editType"
      :backFun="editInfoBack"
    />
  </div>
</template>
<script>
import editInfo from "./editInfo.vue";
// const integralAmount = computed(() => store.state.integralAmount);
export default {
  components: {
    editInfo,
  },
  data() {
    return {
      userinfo: {},
      nickname: "",
      userId:"",
      showEdit: false,
      editType: 1,
    };
  },
  mounted() {
    this.$post(this.URL.member.info, {}).then((res) => {
      if (res.code == 0) {
        this.$store.commit("SET_USERINFO", res.data);
        // this.userinfo = res.data;
      }
    });
    this.$post(this.URL.invite.infoParent, {}).then((res) => {
      console.log(res);
      if (res.code == 0) {
        this.nickname = res.data.nickname;
        this.userId= res.data.userId;
      }
    });
  },
  methods: {
    dealAddress(str) {
      if (str && str.length > 12) {
        return (
          str.substring(0, 4) +
          "...." +
          str.substring(str.length - 4, str.length)
        );
      } else {
        return str;
      }
    },
    editInfoBack(t) {
      this.showEdit = false;
    },
    goedit() {
      if (this.$store.state.userinfo.email) {
        this.editType = 3;
        this.showEdit = true;
      } else {
        this.$message.warning($t("asset.desc164"));
        this.editType = 2;
        this.showEdit = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.edit-btn {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.not-set-box {
  color: rgba(255, 255, 255, 0.4);
  text-align: right;
  font-family: Bai Jamjuree;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  align-items: center;
  img {
    width: 5px;
    height: 8.113px;
    margin-left: 10px;
  }
}
.trade {
  .trade-title {
    margin-bottom: 24px;
    font-size: 24px;
    color: #ffffff;
    font-weight: 600;
  }

  .list-info {
    margin-bottom: 24px;
    justify-content: space-between;
    font-size: 16px;
    color: #999999;

    p {
      color: #fff;
    }
  }
}
@media (max-width: 1200px) {
  .trade {
    padding: 0 16px;
  }
}
@media (max-width: 767px) {
  .trade {
    padding: 0 16px;
    background: #181818;
    border-radius: 8px;

    .trade-title {
      display: none;
    }

    .list-info {
      margin-bottom: 0;
      padding: 16px 0;
      font-size: 13px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.06);

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
</style>
