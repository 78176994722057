import { createStore } from "vuex";
import { post, get } from "../utils/http";
import URL from "../utils/url";
import bus from "../utils/bus";
import createPersistedState from "vuex-persistedstate";
let language = "en_US";
if (localStorage.language) {
  language = localStorage.language;
}
let integralToastLastTime = 0;
if (localStorage.integralToastLastTime) {
  integralToastLastTime = localStorage.integralToastLastTime;
}
let modifyData = {};
if (localStorage.modifyData) {
  modifyData = JSON.parse(localStorage.modifyData);
}
export default createStore({
  plugins: [createPersistedState({})],

  state: {
    contractAddress: "0x55d398326f99059ff775485246999027b3197955", // 合约地址
    // toUserAddress: '0xf9EAFAEed9330FF036FBD094536E02135b8dC166', // alan的测试账号 转账对象的钱包地址
    // 支持链：BSC ETH
    // 1.需要操作的是链接钱包，进行注册用户
    // 2.调用USDT的合约，进行支付USDT的费用，然后处理好回调
    // USDT合约地址：0x55d398326f99059ff775485246999027b3197955
    // 调用合约接口：transfer
    // 参数：我们指定的钱包地址，金额
    // 我们指定的钱包地址：0xf191cEc64416EE6F73C4E215E5E295a641Cb9AF7
    // 金额：根据产品价格
    web3: null,
    resetUserData: false,
    contractList: null,
    tokenList: null,
    address: "",
    networkName: "",
    inviteCode: "",

    token: "",
    isLogin: false,
    isApp: false,
    appToken: "",
    userData: {},
    userinfo: {},
    h5Url: [], // url链接列表接口
    buyAddress: "", // 转账对象的钱包地址
    upBuyAddress: "", // 升级转账对象的钱包地址
    pathName: "/home",
    pageClientWidth: 0,
    assetType: 1,
    language,
    integralUseToastShow: false, //积分奖励领取提示弹窗是否显示
    integralToastLastTime, //积分奖励领取提示弹窗上次提示时间
    integralAmount: 0, //积分数
    modifyData,
    hvUserShow:false,
    isHvUser:false,
    transferShow:false,

    supportData: [1,56,2032],
    showMataMask: true,
    bindInvited:false,
  },
  mutations: {

    //绑定邀请关系
    SET_BINDINVITED(state, data) {
      state.bindInvited = data;
    },
    changeShowMataMask(state, data) {
      state.showMataMask = data;
    },
    SET_HVUSERSHOW(state, data){
      state.hvUserShow = data;
    },
    SET_ISHVUSER(state, data){
      state.isHvUser = data;
    },
    SET_TRANSFERSHOW(state, data){
      state.transferShow = data;
    },
    SET_MODIFY_DATA(state, data) {
      state.modifyData = JSON.parse(JSON.stringify(data));
      localStorage.modifyData = JSON.stringify(state.modifyData);
    },
    SET_INTEGRAL_USE_TOAST_SHOW(state, bool) {
      state.integralUseToastShow = bool;
      if (bool) {
        const nowTime = new Date().getTime() / 1000;
        state.integralToastLastTime = nowTime;
      }
    },
    SET_INTEGRAL_TOAST_LAST_TIME(state, time) {
      state.integralToastLastTime = time;
      localStorage.integralToastLastTime = time;
    },
    SET_INTEGRAL_AMOUNT(state, num) {
      state.integralAmount = num;
    },
    SET_ASSETTYPE(state, val) {
      state.assetType = val;
    },
    SET_PAGECLIENTWIDTH(store, val) {
      store.pageClientWidth = val;
    },
    SET_PATHNAME(store, val) {
      store.pathName = val;
    },
    SET_WEB3PROVIDER(store, val) {
      // 用户钱包地址
      store.web3Provider = val;
    },
    // SET_PROVIDER(store, val){ // 用户钱包地址
    //   store.provider = val
    // },
    // SET_WEB3(store, val){ // 用户钱包地址
    //   store.web3 = val
    // },
    SET_ADDRESS(store, val) {
      // 用户钱包地址
      store.address = val;
    },
    SET_NETWORKNAME(store, val) {
      store.networkName = val;
    },
    SET_CONTRACTLIST(store, val) {
      store.contractList = val;
    },
    SET_TOKENLIST(store, val) {
      store.tokenList = val;
    },

    SET_RESET_USER_DATA(store, val) {
      store.resetUserData = val;
    },

    SET_USERDATA(state, data) {
      state.userData = data;
    },
    SET_USERINFO(state, data) {
      state.userinfo = data;
    },
    SET_H5URL(state, data) {
      state.h5Url = data;
    },
    SET_INVITECODE(state, data) {
      state.inviteCode = data;
    },
    SET_BUYADDRESS(state, data) {
      state.buyAddress = data;
    },
    SET_UPBUYADDRESS(state, data) {
      state.upBuyAddress = data;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_ISAPPINFO(state, payload) {
      state.isApp = true;
      state.appToken = payload;
    },
    SET_LOGIN(state, isLogin) {
      state.isLogin = isLogin;
    },
  },
  actions: {
    //判断上一次打开积分领取提示弹窗是否超过23小时
    openIntegralUseToast({ commit, state }) {
      const nowTime = new Date().getTime() / 1000;
      if (nowTime - state.integralToastLastTime < 23 * 60 * 60) {
        return;
      }
      post(URL.assetbag.statistics, {}).then((res) => {
        if (res.code !== 0) return;
        const amount = Number(res.data.balance);
        commit("SET_INTEGRAL_TOAST_LAST_TIME", nowTime);
        if (amount > 0) {
          commit("SET_INTEGRAL_AMOUNT", amount);
          commit("SET_INTEGRAL_USE_TOAST_SHOW", true);
        }
      });
    },
    //一键领取之后
    togetherReceiveAfter({ commit }) {
      post(URL.assetbag.statistics, {}).then((res) => {
        if (res.code !== 0) return;
        const amount = Number(res.data.balance);

        if (amount > 0) {
          const nowTime = new Date().getTime() / 1000;
          commit("SET_INTEGRAL_TOAST_LAST_TIME", nowTime);
          commit("SET_INTEGRAL_AMOUNT", amount);
          commit("SET_INTEGRAL_USE_TOAST_SHOW", true);
        } else {
          bus.emit("hadlerSuccess", 1);
        }
      });
    },
    setResetUserData({ commit }, val) {
      commit("SET_RESET_USER_DATA", val);
    },
    upMistime({ commit }, timestamp) {
      commit("SET_H5URL", timestamp);
    },
    resetVuex({ commit }) {
      commit("SET_H5URL", {});
      commit("SET_USERDATA", {});
      commit("SET_TOKEN", "");
      commit("SET_LOGIN", false);
    },
  },
  getters: {},
  modules: {},
});
