<template>
    <div class="home-footer"
        v-if="pathName == '/login' || pathName == '/register' || pathName == '/error' || pathName == '/forgot' || pathName == '/forgotname' || pathName == '/bindname' || pathName == '/bindhv'">
        <div class="footer-ip flex">
            <div>© 2022 Daoversal. All rights reserved <span
                    @click="handlerPath('https://daoversal.com/privacy-policy')">{{ $t('footer.desc19') }}</span></div>
            <img src="../public/images/logo.png" alt="">
        </div>
    </div>
    <div class="home-footer" v-else>
        <div class="footer-nav flex">
            <div class="footer-nav-list flex" :class="{ textfont: lang == 'en_US' }">
                <div class="nav-list-info">
                    <div>{{ $t('footer.desc1') }}</div>
                    <p @click="handlerPath('https://daoversal.com/decentralized-identifiers')">{{ $t('footer.desc2') }}</p>
                    <p @click="handlerPath('https://daoversal.com/3d-metaverse-avatar')">{{ $t('footer.desc3') }}</p>
                    <p @click="handlerPath('https://daoversal.com/myspace-metaverse')">{{ $t('footer.desc4') }}</p>
                    <p @click="handlerPath('https://daoversal.com/mystar-metaverse')">{{ $t('footer.desc5') }}</p>
                    <p @click="handlerPath('https://daoversal.com/nft-marketplace')">{{ $t('footer.desc6') }}</p>
                </div>
                <div class="nav-list-info">
                    <div>{{ $t('footer.desc7') }}</div>
                    <p>{{ $t('footer.desc8') }}</p>
                    <p>{{ $t('footer.desc9') }}</p>
                    <p>{{ $t('footer.desc10') }}</p>
                    <p>{{ $t('footer.desc7') }}</p>
                </div>
                <div class="nav-list-info">
                    <div>{{ $t('footer.desc11') }}</div>
                    <p>{{ $t('footer.desc12') }}</p>
                    <p>{{ $t('footer.desc13') }}</p>
                    <p>{{ $t('footer.desc14') }}</p>
                    <p>{{ $t('footer.desc5') }}</p>
                    <p>{{ $t('footer.desc15') }}</p>
                </div>
                <div class="nav-list-info">
                    <div>{{ $t('footer.desc16') }}</div>
                    <p>{{ $t('footer.desc17') }}</p>
                    <p>{{ $t('footer.desc18') }}</p>
                    <p @click="handlerPath('https://daoversal.com/privacy-policy')">{{ $t('footer.desc19') }}</p>
                    <p @click="handlerPath('https://daoversal.com/terms-of-us')">{{ $t('footer.desc20') }}</p>
                </div>
                <div class="nav-list-info">
                    <div>{{ $t('footer.desc21') }}</div>
                    <p class="flex">

                        <img src="../public/images/glfootericon1.png" alt=""
                            @click="handlerPath('https://twitter.com/daoversal')" />

                        <img src="../public/images/glfootericon3.png" alt=""
                            @click="handlerPath('https://t.me/wearedaoversal')" />
                        <img src="../public/images/glfootericon4.png" alt=""
                            @click="handlerPath('https://www.instagram.com/daoversal')" />
                        <img src="../public/images/glfootericon5.png" alt=""
                            @click="handlerPath('https://medium.com/@daoversal')" />
                    </p>
                </div>
            </div>
        </div>
        <div class="footer-ip flex">
            <div>© 2023 Daoversal. All rights reserved</div>
            <img src="../public/images/logo.png" alt="">
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState(['pathName']),
    },
    data() {
        return {
            lang: ''
        }
    },
    mounted() {
        this.lang=localStorage.getItem('language')
    },
    methods: {
        handlerPath(url) {
            window.open(url)
        }
    }
}
</script>

<style lang="less" scoped>
.home-footer {
    width: 100%;
    background: #000;
    border-top: 1px solid rgba(255, 255, 255, 0.1);

    .footer-nav {
        padding: 48px 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        .footer-nav-list {
            width: 1240px;
            margin: 0 auto;
            justify-content: space-between;

            .nav-list-info {
                flex: 0 0 230px;
                font-size: 16px;
                color: #A8A8A8;

                p {
                    margin-bottom: 20px;
                    flex-wrap: wrap;
                    justify-content: left;
                    cursor: pointer;

                    &:hover {
                        color: #C9FA5B;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                div {
                    margin-bottom: 32px;
                    font-size: 20px;
                    color: #FFFFFF;
                }

                img {
                    flex: 0 0 28px;
                    width: 28px;
                    height: 28px;
                    margin-right: 21px;
                    margin-bottom: 23px;

                    &:nth-child(5n) {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .footer-ip {
        width: 1240px;
        margin: 0 auto;
        padding: 24px 0;
        justify-content: space-between;
        font-size: 16px;
        color: #444444;
        line-height: 31px;

        img {
            width: 200px;
            height: 31px;
        }

        span {
            color: #fff;
            cursor: pointer;
            margin-left: 10px;
        }
    }
}

@media (max-width:1200px) {
    .home-footer {
        .footer-nav {
            padding: 20px 0;

            .footer-nav-list {
                width: calc(100vw - 8px);
                flex-wrap: wrap;

                .nav-list-info {
                    flex: 0 0 20%;
                    margin-bottom: 16px;
                    font-size: 12px;
                    line-height: 25px;

                    div {
                        margin-bottom: 13px;
                        font-size: 16px;
                    }

                    p {
                        margin-bottom: 10px;
                    }

                    &:nth-last-child(-n+2) {
                        flex: 0 0 50%;
                        margin-bottom: 0;

                        img {
                            flex: 0 0 15px;
                            width: 15px;
                            height: 15px;
                        }
                    }
                }
            }
        }

        .footer-ip {
            width: auto;
            padding: 15px;

            div {
                flex: 1;
                font-size: 12px;
                text-align: center;
            }

            img {
                display: none;
            }
        }
    }
}

@media (max-width:767px) {
    .home-footer {
        display: none;
    }
}</style>