import {
  SwipeItem,
  Swipe,
  Calendar,
  PasswordInput,
  NumberKeyboard,
  Picker,
  DatetimePicker,
  Uploader,
  Switch,
  CellGroup,
  Cell,
  CountDown,
  Tab,
  Tabs,
  Toast,
  Icon,
  Dialog,
  Checkbox,
  CheckboxGroup,
  Lazyload,
  Loading,
  Popup,
  List,
  PullRefresh,
  RadioGroup,
  Radio,
  Image as VanImage,
  Locale
} from 'vant';

export function vant(app) {


  app.use(Icon)
      .use(Swipe)
      .use(SwipeItem)
      .use(Calendar)
      .use(NumberKeyboard)
      .use(PasswordInput)
      .use(Picker)
      .use(CellGroup)
      .use(DatetimePicker)
      .use(Uploader)
      .use(Switch)
      .use(Cell)
      .use(CountDown)
      .use(Toast)
      .use(Checkbox)
      .use(CheckboxGroup)
      .use(Dialog)
      .use(Loading)
      .use(List)
      .use(PullRefresh)
      .use(Popup)
      .use(Radio)
      .use(RadioGroup)
      .use(VanImage)
      .use(Tab)
      .use(Tabs)
      .use(Lazyload, {
        preLoad: 1.3,
        error: "",
        loading: "",
        attempt: 1
      });

}