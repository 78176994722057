import store from '@/store'
(function (doc, win) {
    var docEl = doc.documentElement,
        resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
        recalc = function () {
            store.commit('SET_PAGECLIENTWIDTH',docEl.clientWidth)
            var clientWidth = docEl.clientWidth;
            if (!clientWidth) return;
            // 这里的750 取决于设计稿的宽度
            if (clientWidth >= 780) {
                docEl.style.fontSize = '100px';
            } else {
                docEl.style.fontSize = 100 * (clientWidth / 780) + 'px';
            }
        };
    if (!doc.addEventListener) return;
    win.addEventListener(resizeEvt, recalc, false);
    doc.addEventListener('DOMContentLoaded', recalc, false);
  })(document, window);