<template>
  <el-tooltip
    class="box-item"
    effect="dark"
    :content="content"
    placement="bottom"
    raw-content
  >
    <span class="text">
      <!-- <slot /> -->
      <span class="tool-tip-con name-tip" v-if="type === 'name'" />
      <span class="tool-tip-con title-tip" v-else-if="type === 'title'" />
    </span>
  </el-tooltip>
</template>
<script>
export default {
  props: {
    content: String,
    type: {
      type: String,
      default: "name", //title
    },
  },
};
</script>
<style lang="less" scoped>
:deep {
  .el-popper__arrow {
    display: block;
  }
}
.tool-tip-con {
  width: 1em;
  height: 1em;
  position: relative;
  cursor: pointer;
  z-index: 1;
  display: inline-block;
  position: relative;
  &::before {
    content: "";
    width: 1em;
    height: 1em;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }
  &.name-tip {
    font-size: 14px;
    margin-left: 2px;
    &::before {
      top: 2px;
      left: 0;
      background-image: url("../public/images/asset/my-income/icon.png");
    }
  }
  &.title-tip {
    font-size: 18px;
    margin-left: 8px;
    &::before {
      top: 2px;
      left: 0;
      background-image: url("../public/images/icon_tip_title.png");
    }
  }
}
@media (max-width: 767px) {
  .tool-tip-con {
    &.name-tip {
      font-size: 12px;
    }
    &.title-tip {
      font-size: 14px;
      margin-left: 6px;
    }
  }
}
</style>
